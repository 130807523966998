import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { url } from '../../../api';
//styles
import {
  Overlay,
  MainContainer,
  CloseContainer,
  ButtonCargar,
  ButtonSelect,
  CargaArchivosContainer,
  DescriptionText,
  InputModal,
  MainTitle,
  SelectContainer,
  Subtitle,
  TextCargar,
  TextCargarWarning,
  UploadImg,
  UploadImgeHeader,
  LabelSelect,
  DimensionsContainer,
  ButtonContainer,
  Xtext,
} from './ModalIngresarDatosStyles';
import { useMutation } from '@apollo/client';
import { UPLOAD_FILE } from '../../../../graphql/Mutation';
import upload from '../../../assets/upload.svg';
import { Flex } from '../../../styles/common.styles';

const close = `${url}CloseButton.png`;
export default function ModalIngresarDatos({
  showModal,
  setShowModal,
  handleEdit,
}) {
  const [uploadFile] = useMutation(UPLOAD_FILE);
  const [file, setFile] = useState(null);
  const [valid, setValid] = useState(true);

  const onDrop = async (acceptedFiles) => {
    setFile(acceptedFiles[0]);
  };

  const saveFile = async () => {
    if (
      formEditData.height !== '' &&
      formEditData.length !== '' &&
      formEditData.width !== '' &&
      formEditData.weight !== ''
    ) {
      try {
        const { data } = await uploadFile({
          variables: {
            file,
            name: `${showModal?.repair || 'repair'}-${
              showModal?.id_autopart || 'id_autopart'
            }-${showModal?.id_part || 'id_part'}`,
          },
        });

        handleEdit({
          ...formEditData,
          image: data.singleUpload,
        });
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    } else {
      console.log('probanding');
      setValid(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': [],
    },
    multiple: false, // Disable multiple file selection   // Allow only one file
  });

  const [formEditData, setFormEditData] = useState({
    name: showModal?.repair || '',
    part: showModal?.id_autopart || '',
    partId: showModal?.id_part || '',
    description: showModal?.description || '',
    averageCost: showModal?.average_price || '',
    marketPlacePrice: showModal?.final_price || '',
    height: showModal?.height || '',
    width: showModal?.width || '',
    length: showModal['length'] || '',
    weight: showModal?.weight || '',
    image: showModal?.image || '',
    postInMarketPlace: true,
  });

  useEffect(() => {
    console.log(formEditData);
    return () => {
      // limpieza del efecto
    };
  }, [formEditData]);

  return (
    <Overlay>
      <MainContainer>
        <CloseContainer>
          <img onClick={() => setShowModal(false)} src={close} alt='x' />
        </CloseContainer>
        <Flex
          direction='row'
          gap='8px'
          align='center'
          width='100%'
          mobile='flex-direction: column;'
        >
          <img alt='' src={upload} style={{ width: '48px' }} />
          <MainTitle style={{ width: '100%', textAlign: 'center' }}>
            Información complementaria
          </MainTitle>
        </Flex>
        <DescriptionText>
          Necesitamos que nos apoyes a completar la siguiente información para
          poder asociar tu producto en el sistema.
        </DescriptionText>
        <Flex width='100%' direction='column' gap='16px'>
          <Subtitle>Información general</Subtitle>

          <SelectContainer>
            <LabelSelect>Nombre del producto</LabelSelect>
            <InputModal
              onChange={(e) =>
                setFormEditData({ ...formEditData, name: e.target.value })
              }
              value={formEditData.name}
              placeholder='Colocar nombre del producto'
            />
          </SelectContainer>

          <SelectContainer>
            <LabelSelect>Descripcións del producto</LabelSelect>
            <InputModal
              onChange={(e) =>
                setFormEditData({
                  ...formEditData,
                  description: e.target.value,
                })
              }
              value={formEditData.description}
              placeholder='Colocar descripción del producto'
            />
          </SelectContainer>
        </Flex>
        <Flex width='100%' direction='column' gap='16px'>
          <Subtitle>Volumetría</Subtitle>

          <DimensionsContainer>
            <SelectContainer>
              <LabelSelect>Alto</LabelSelect>
              <InputModal
                validIn={formEditData.height == '' && valid == false}
                onChange={(e) =>
                  setFormEditData({ ...formEditData, height: e.target.value })
                }
                value={formEditData.height}
                placeholder='mm'
              />
            </SelectContainer>
            <Xtext>X</Xtext>
            <SelectContainer>
              <LabelSelect>Ancho</LabelSelect>
              <InputModal
                validIn={formEditData.width == '' && valid == false}
                onChange={(e) =>
                  setFormEditData({ ...formEditData, width: e.target.value })
                }
                value={formEditData.width}
                placeholder='mm'
              />
            </SelectContainer>
            <Xtext>X</Xtext>
            <SelectContainer>
              <LabelSelect>Largo</LabelSelect>
              <InputModal
                validIn={formEditData.length == '' && valid == false}
                onChange={(e) =>
                  setFormEditData({ ...formEditData, length: e.target.value })
                }
                value={formEditData.length}
                placeholder='mm'
              />
            </SelectContainer>
          </DimensionsContainer>

          <SelectContainer>
            <LabelSelect>Peso</LabelSelect>
            <InputModal
              validIn={formEditData.weight == '' && valid == false}
              onChange={(e) =>
                setFormEditData({ ...formEditData, weight: e.target.value })
              }
              value={formEditData.weight}
              placeholder='kg'
            />
          </SelectContainer>
        </Flex>
        <Flex width='100%' direction='column' gap='16px'>
          <Subtitle>Subir imagen del producto</Subtitle>

          <CargaArchivosContainer>
            <UploadImg src={upload} />
            <div>
              <TextCargar>Selecciona el archivo</TextCargar>
              <TextCargarWarning>
                Formato .jpg, .png <br />
                Peso de 10MB
              </TextCargarWarning>
            </div>

            <div>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <ButtonSelect as='label' htmlFor='fileInput'>
                  Seleccionar
                </ButtonSelect>
              </div>
            </div>
          </CargaArchivosContainer>
        </Flex>

        <ButtonContainer>
          <ButtonSelect onClick={() => setShowModal(false)}>
            Cancelar
          </ButtonSelect>
          <ButtonCargar onClick={() => saveFile()}>Cargar</ButtonCargar>
        </ButtonContainer>
      </MainContainer>
    </Overlay>
  );
}
