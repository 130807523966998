import styled from 'styled-components';

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(
    0,
    0,
    0,
    0.5
  ); /* Semi-transparent black background for the backdrop */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
  backdrop-filter: blur(5px); /* Apply a blur effect to the background */
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 24px;
  border-radius: 16px;
  background: var(--neutral-white, #fff);
  box-shadow: 0px 8px 24px 0px rgba(0, 32, 74, 0.4);
  z-index: 1001; /* Ensure it's above the backdrop */
  width: 100%;
  max-width: 684px;
  height: fit-content;
  max-height: 90%;
  overflow: auto;
  gap: 40px;
  position: relative;
  @media (max-width: 1023px) {
    gap: 24px;
  }
`;

export const ImagenTItle = styled.img`
  width: 64px;
`;
export const Title = styled.h2`
  color: var(--primarydeep, #0d3564);
  text-align: center;
  /* DESKTOP/H3 */
  font-family: Mulish;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 48px */
  @media (max-width: 1023px) {
    font-size: 28px;
  }
`;

export const TextModal = styled.p`
  color: var(--neutral-abism, #14181a);
  text-align: center;
  /* COMMON/Body */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  margin: 0;
`;

export const SelectContainer = styled.div`
  width: 100%;
`;

export const LabelSelect = styled.p`
  color: var(--primary-darkblue, #061222);
  margin: 0;
  /* COMMON/Caption */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
`;

export const SelectCargar = styled.select`
  width: 100%;
  display: flex;
  height: 48px;
  padding: 16px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--neutral-cloud, #b9cad0);
  background: var(--neutral-white, #fff);
  color: var(--neutral-storm, #8299a3);

  /* COMMON/Body */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
`;

export const CargarContainer = styled.div`
  display: flex;
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  margin: 16px 0;
  background: var(--background-light-gray, #f8f8f8);
  @media (max-width: 1023px) {
    flex-direction: column;
    width: fit-content;
    margin: 0 auto;
  }
`;

export const CargarContainerInner = styled.div`
  display: flex;
  width: 100%;
  gap: 32px;
  justify-content: start;
  align-items: center;
  @media (max-width: 1023px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const ImagenCargar = styled.img`
  width: 32px;
`;

export const MainTextCargar = styled.p`
  color: var(--primarydeep, #0d3564);
  margin: 0;
  /* COMMON/Body_Bold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
`;

export const TextCargar = styled.p`
  color: var(--neutrals-taupe-gray, #939396);
  margin: 0;
  /* COMMON/Body */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
`;

export const ButtonSeleccionar = styled.button`
  display: flex;
  width: 212px;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border-color: #0d3564;
  border-width: 1px;
  border-style: solid;
  background: var(--primarydeep, #0d3564);
  color: #fff;
  cursor: pointer;
`;

export const ButtonCancelar = styled.button`
  display: flex;
  width: 212px;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border-color: #0d3564;
  border-width: 1px;
  border-style: solid;
  border: 1px solid var(--primarydeep, #0d3564);
  background: var(--neutral-white, #fff);
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  justify-content: center;
  align-items: center;
`;
