/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { Button, StyledInput } from '../../styles/common.styles';
import DashboardTable from './DashboardTable.jsx';
import ModalUpload from './ModalUpload/ModalUpload';
import { useState } from 'react';
import { url } from '../../api';
// import ModalCargaInformacion from '../../components/Dashboard/ModalCargaInformacion';
const DashboardLogo = `${url}Dashboard.svg`;
const downArrow = `${url}downArrow.svg`;

const Dashboard = () => {
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [openModalUploadTest, setOpenModalUploadTest] = useState(false);
  return (
    <>
      {/* {openModalUpload ? 
    <ModalCargaInformacion
    openModalUpload={openModalUpload}
    setOpenModalUpload={setOpenModalUpload}
    /> : null}  */}
      <ModalUpload
        open={openModalUploadTest}
        setOpen={setOpenModalUploadTest}
      />

      <MainContaier>
        <TopContainer>
          <TitleContain>
            <img style={{ width: '48px' }} src={DashboardLogo} />
            Dashboard
          </TitleContain>
          {/* <Button 
          mobile='width:100%;'
          onClick={()=>setOpenModalUpload(true)}
          >Cargar información</Button> */}
          <Button
            mobile='width:100%;'
            onClick={() => setOpenModalUploadTest(true)}
          >
            Cargar información
          </Button>
        </TopContainer>
        <FlexRow>
          <SelectContainer>
            <LabelSelect>Fecha</LabelSelect>
            <InputDate type='date' />
          </SelectContainer>
          <SelectContainer>
            <LabelSelect>Grupo distribuidor</LabelSelect>
            <SelectField>
              <option>Elige una opción</option>
            </SelectField>
          </SelectContainer>
          <SelectContainer>
            <LabelSelect>Marca</LabelSelect>
            <SelectField>
              <option>Elige una opción</option>
            </SelectField>
          </SelectContainer>
          <IngresaRow>
            <StyledInput
              width='205px'
              placeholder='Ingresa ID de Pieza'
              mobile='width:20%;'
            />
            <Button width='194px' mobile='width:70%;'>
              Buscar
            </Button>
          </IngresaRow>
          <Button width='194px' minwidth='194px' secondary mobile='width:100%;'>
            Limpiar filtro
          </Button>
        </FlexRow>
        <TableContainer>
          <DashboardTable />
        </TableContainer>
        <FlexRow style={{ justifyContent: 'flex-end' }}>
          <Button width='194px' secondary mobile='width:100%;'>
            Exportar tabla
          </Button>
        </FlexRow>
      </MainContaier>
    </>
  );
};

export default Dashboard;

const MainContaier = styled.div`
  width: 100%;
  display: flex;
  padding: 40px 24px 80px 24px;
  flex-direction: column;
  gap: 40px;
  @media (max-width: 1023px) {
  }
`;

const TitleContain = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-direction: row;
  color: #0d3564;
  font-family: Mulish;
  font-size: 40px;
  font-weight: 800;
  @media (max-width: 1023px) {
  }
`;

const IngresaRow = styled.div`
  display: flex;
  gap: 24px;
  align-items: flex-end;
`;

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

const RowsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  & > div:nth-child(even) {
    background: red;
    color: white;
  }
  & > div:nth-child(odd) {
    background: blue;
    color: white;
  }
`;

const TableContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 20px 54px 0px rgba(13, 53, 100, 0.15);
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
  gap: 24px;
  @media (max-width: 1023px) {
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  @media (max-width: 1023px) {
    width: 100%;
  }
`;
const LabelSelect = styled.p`
  color: var(--primary-darkblue, #061222);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  margin: 0;
`;
const InputDate = styled.input`
  display: flex;
  height: 48px;
  padding: 0 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--neutral-cloud, #b9cad0);
  background: var(--neutral-white, #fff);
  border-radius: 8px;
  border: 1px solid var(--neutral-cloud, #b9cad0);
  background: var(--neutral-white, #fff);
`;

const SelectField = styled.select`
  display: flex;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  height: 48px;
  padding: 0 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--neutral-cloud, #b9cad0);
  background: url(${downArrow}) no-repeat right center;
  background-position: calc(100% - 8px) center;
  background-color: #ffffff;
  color: var(--neutral-storm, #8299a3);

  /* COMMON/Body */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
`;
