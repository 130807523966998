/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { url } from '../../api';
const edit = `${url}Editar.svg`;
const DeleteIcon = `${url}delete.svg`;
const image = `${url}Imagen_check.png`;
const Noimage = `${url}Imagen_uncheck.png`;
import styled from 'styled-components';
import { Checkbox, Pagination, Switch } from '@mui/material';
import ModalIngresarDatos from './ModalIngresarDatos/ModalIngresarDatos';
import ModalEditAdminMkt from './ModalEditar/ModalEditAdminMkt';
import ModalEliminarAdminMkt from './ModalEliminar/ModalEliminarAdminMkt';
import { useMutation } from '@apollo/client';
import {
  CHANGE_TASA_ITEMS,
  DELETE_ITEMS,
  DELE_USER,
  EDIT_ITEMS,
} from '../../../graphql/Mutation';
import ModalLosentimos from './ModalLoSentimos/ModalLosentimos';
import { styled as styled2 } from '@mui/material';
import { Flex } from '../../styles/common.styles';
import Correct from '../../assets/correct.svg';
import Incorrect from '../../assets/incorrect.svg';
import Bluecorrect from '../../assets/bluecorrect.svg';
import ModalPublish from './NewModals/ModalPublish';
import TurnOffModal from './NewModals/TurnOffModal';
import UncompleteModal from './NewModals/UncompleteModal';

const CustomCheckbox = styled2(Checkbox)(({ theme }) => ({
  // Estilos para el borde en unchecked
  '&.MuiButtonBase-root': {
    color: '#fff',
    // border: '#0E79B4',
    width: '18px',
    height: '18px',
    border: '2px solid #0E79B4',
    borderRadius: '4px',
  },
  // Estilos para el fondo en checked
  '&.Mui-checked': {
    '&.MuiCheckbox-root': {
      backgroundColor: '#41B770',
      border: '2px solid #0E79B4',
      borderRadius: '4px',
      width: '18px',
      height: '18px',
    },
    // Estilos para el color de la palomita en checked
    '& .MuiSvgIcon-root': {
      color: '#fff',
    },
  },
}));

const AntSwitch = styled2(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#027CBB',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : '#A5cee3',
    boxSizing: 'border-box',
  },
}));

const MockData = [
  {
    id: '123456',
    description: 'Difusión',
    movement: 'Lento',
    distribuitor: 'Vat. Distribuidor',
    cost: '$7,822.04',
    marketplace: true,
    mktPrice: '$7,782.04',
    idDistributor: '1',
  },
  {
    id: '123457',
    description: 'Difusión',
    movement: 'Potencial obsoleto',
    distribuitor: 'Vat. Distribuidor',
    cost: '$7,822.04',
    marketplace: false,
    mktPrice: '$5,822.04',
    idDistributor: '2',
  },
  {
    id: '123458',
    description: 'Difusión',
    movement: 'Lento',
    distribuitor: 'Vat. Distribuidor',
    cost: '$7,822.04',
    marketplace: true,
    mktPrice: '$822.04',
    idDistributor: '3',
  },
  {
    id: '123459',
    description: 'Difusión',
    movement: 'Potencial obsoleto',
    distribuitor: 'Vat. Distribuidor',
    cost: '$7,822.04',
    marketplace: false,
    mktPrice: '$7,892.04',
    idDistributor: '4',
  },
  {
    id: '1234510',
    description: 'Difusión',
    movement: 'Lento',
    distribuitor: 'Vat. Distribuidor',
    cost: '$22.04',
    marketplace: true,
    mktPrice: '$47,822.04',
    idDistributor: '5',
  },
];

const AdminMktTable = ({
  publish,
  items,
  totalItemsPublished,
  totalItemsNoPublished,
  handleChangePage,
  currentPage,
  reloadCurrentList,
  setShowModalEmpySearch,
  showModalEmpySearch,
  valueFilters,
  isAdminDashboard,
  showModalDelete,
  setShowModalDelete,
}) => {
  const itemsPerPage = 6;
  const [showModal, setShowModal] = useState();
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]); // Estado para los checkboxes en las filas
  const [tasa, setTasa] = useState('0'); // Estado para el input de la tasa de ganancia
  const totalPages = Math.ceil(
    publish
      ? totalItemsPublished / itemsPerPage
      : totalItemsNoPublished / itemsPerPage
  );

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData = items.slice(startIndex, endIndex);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    // Actualiza el estado de todos los checkboxes en las filas
    setSelectedCheckboxes(displayedData.map(() => !selectAll));
  };

  const toggleRowCheckbox = (index) => {
    // Actualiza el estado de un checkbox en una fila específica
    const updatedCheckboxes = [...selectedCheckboxes];
    updatedCheckboxes[index] = !updatedCheckboxes[index];
    setSelectedCheckboxes(updatedCheckboxes);
  };

  const [deleteItems] = useMutation(DELETE_ITEMS, {
    fetchPolicy: 'no-cache',
    onCompleted({ deleteItems }) {
      reloadCurrentList();
    },
  });

  const [editItems] = useMutation(EDIT_ITEMS, {
    fetchPolicy: 'no-cache',
    onCompleted({ editItems }) {
      reloadCurrentList();
    },
  });

  const [changeTasaItemsSelected] = useMutation(CHANGE_TASA_ITEMS, {
    fetchPolicy: 'no-cache',
    onCompleted({ changeTasaItemsSelected }) {
      reloadCurrentList();
    },
  });

  const handleDelete = () => {
    setSelectAll(false);
    setSelectedCheckboxes(displayedData.map(() => false));
    setShowModalDelete(false);
    const arrDelete = [];
    items.map((item, index) => {
      if (selectedCheckboxes[index]) {
        arrDelete.push(item.id_autopart);
        //deleteProduct(item.id);
      }
    });
    deleteItems({
      variables: {
        arrItems: JSON.stringify(arrDelete),
      },
    });
  };

  const handleEdit = (item) => {
    setShowModalEdit(false);
    setShowModal(false);
    editItems({
      variables: {
        info: JSON.stringify(item),
      },
    });
  };

  const aplicarTasa = () => {
    if (selectAll) {
      changeTasaItemsSelected({
        variables: {
          inMarketplace: publish,
          date: valueFilters.date,
          idUac: valueFilters.idUac,
          idManufacture: valueFilters.idManufacture,
          isByFilters: true,
          tasa: tasa,
        },
      });
    } else if (selectedCheckboxes?.includes(true)) {
      const arrItemsTasa = [];
      items.map((item, index) => {
        if (selectedCheckboxes[index]) {
          arrItemsTasa.push(item.id_autopart);
        }
      });
      changeTasaItemsSelected({
        variables: {
          inMarketplace: publish,
          items: JSON.stringify(arrItemsTasa),
          isByFilters: false,
          tasa: tasa,
        },
      });
    }
  };

  const handleChange = (event) => {
    // Asegurarse de que el valor esté dentro del rango permitido
    const nuevoValor = Math.min(Math.max(parseInt(event.target.value), 0), 20);
    console.log('prueba', nuevoValor, typeof nuevoValor);

    setTasa(nuevoValor.toString());
  };

  useEffect(() => {
    if (tasa == 'NaN') {
      setTasa('0');
    }
  }, [tasa]);

  useEffect(() => {
    setSelectAll(false);
    // Actualiza el estado de todos los checkboxes en las filas
    setSelectedCheckboxes(displayedData.map(() => false));
  }, [publish]);

  useEffect(() => {
    setSelectedCheckboxes([false, false, false, false, false, false]);
    return () => {
      // limpieza del efecto
    };
  }, []);

  const handleSelect = (event) => {
    // Mover el cursor al final del texto (antes del %)
    event.target.setSelectionRange(
      event.target.value.length - 1,
      event.target.value.length - 1
    );
  };

  const [openModalPublish, setOpenModalPublish] = useState(false);
  const [openModalTurnOff, setOpenModalTurnOff] = useState(false);
  const [openModalUncomplete, setOpenModalUncomplete] = useState(false);

  return (
    <>
      {/* NUEVOS MODALES A INTEGRAR */}
      <ModalPublish open={openModalPublish} setOpen={setOpenModalPublish} />
      <TurnOffModal open={openModalTurnOff} setOpen={setOpenModalTurnOff} />
      <UncompleteModal
        open={openModalUncomplete}
        setOpen={setOpenModalUncomplete}
      />
      <>
        {showModalEmpySearch ? (
          <ModalLosentimos
            showModalDelete={showModalEmpySearch}
            setShowModalDelete={setShowModalEmpySearch}
          />
        ) : null}
        {showModal ? (
          <ModalIngresarDatos
            showModal={showModal}
            setShowModal={setShowModal}
            handleEdit={handleEdit}
          />
        ) : null}
        {showModalEdit ? (
          <ModalEditAdminMkt
            showModalEdit={showModalEdit}
            setShowModalEdit={setShowModalEdit}
            handleEdit={handleEdit}
          />
        ) : null}
        {showModalDelete ? (
          <ModalEliminarAdminMkt
            showModalDelete={showModalDelete}
            setShowModalDelete={setShowModalDelete}
            handleDelete={handleDelete}
          />
        ) : null}
        <ContainerTableHeader isAdminDashboard={isAdminDashboard}>
          <ContaierTableHeaderInner>
            <Flex>
              <ContainerTableText>
                Tasa de ganancia por pieza
              </ContainerTableText>
            </Flex>
            <Flex gap='16px' align='center' width='100%'>
              <ContainerTableInput
                type='text'
                value={`${tasa}%`}
                onChange={handleChange}
                placeholder='0%'
                onSelect={handleSelect}
              />

              <ContainerTableButtonAplicar
                disabled={!selectedCheckboxes?.includes(true)}
                onClick={aplicarTasa}
                style={{ width: '100%' }}
              >
                Aplicar
              </ContainerTableButtonAplicar>
            </Flex>
          </ContaierTableHeaderInner>
        </ContainerTableHeader>
        <TableContainer>
          <FlexTable>
            <FlexTableHeaderRow>
              {!isAdminDashboard && (
                <Cell>
                  <CustomCheckbox
                    checked={selectAll}
                    onChange={toggleSelectAll}
                  />
                </Cell>
              )}
              <Cell>ID Parte</Cell>
              <Cell>
                {/* Nombre */}
                Parte
              </Cell>
              {!isAdminDashboard && <Cell>Marca</Cell>}
              <Cell>Descripción</Cell>
              <Cell>Costo promedio</Cell>
              <Cell>ID Distribuidor</Cell>
              {!isAdminDashboard && publish && <Cell>Imagen</Cell>}
              {!isAdminDashboard && <Cell>Publicar</Cell>}
              {!isAdminDashboard && <Cell>TecAlliance</Cell>}
              <Cell>Precio Market Place</Cell>
              {!isAdminDashboard && <Cell>Acciones </Cell>}
            </FlexTableHeaderRow>
            {items.map((product, index) => (
              <FlexTableRow key={`product${index}`}>
                {!isAdminDashboard && (
                  <Cell>
                    <CustomCheckbox
                      checked={selectedCheckboxes[index]}
                      onChange={() => toggleRowCheckbox(index)}
                    />
                  </Cell>
                )}
                <Cell>{product.id_part}</Cell>
                <Cell>{product.repair}</Cell>
                {!isAdminDashboard && (
                  <Cell>{product.catalog_brands[0].name_brand}</Cell>
                )}
                <Cell>{product.description}</Cell>
                <Cell>${product.average_price}</Cell>
                <Cell>{product.user_access_controls[0].name}</Cell>
                {!isAdminDashboard && publish && (
                  <Cell>
                    {product.image && product.image !== '' ? (
                      <img src={image} alt='' />
                    ) : (
                      <img src={Noimage} alt='' />
                    )}
                  </Cell>
                )}
                {!isAdminDashboard && (
                  <Cell>
                    <AntSwitch
                      checked={product.status}
                      onClick={() => console.log('prueba')}
                    />
                  </Cell>
                )}
                {!isAdminDashboard && (
                  <Cell>
                    {product.extract_tech_alliance == null && (
                      <img alt='' src={Incorrect} />
                    )}
                    {product.extract_tech_alliance == 0 && (
                      <img alt='' src={Bluecorrect} />
                    )}
                    {product.extract_tech_alliance == 1 && (
                      <img alt='' src={Correct} />
                    )}
                  </Cell>
                )}
                <Cell>
                  {!isAdminDashboard ? (
                    <input
                      type='text'
                      disabled
                      value={`$${product.final_price}`}
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '1px solid #B9CAD0',
                        borderRadius: '8px',
                        backgroundColor: '#ffffff',
                        height: '35px',
                        padding: '0 8px',
                      }}
                    />
                  ) : (
                    <>${product.final_price}</>
                  )}
                </Cell>
                {!isAdminDashboard && (
                  <Cell>
                    {publish ? (
                      <img
                        style={{ cursor: 'pointer' }}
                        src={edit}
                        alt=''
                        onClick={() => setShowModalEdit(product)}
                      />
                    ) : (
                      <ButtonTableIngresar
                        onClick={() => setShowModal(product)}
                      >
                        Ingresar datos
                      </ButtonTableIngresar>
                    )}
                  </Cell>
                )}
              </FlexTableRow>
            ))}
          </FlexTable>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '16px',
              width: '100%',
            }}
          >
            <StyledPagination
              count={totalPages}
              page={currentPage}
              onChange={handleChangePage}
              boundaryCount={0}
              siblingCount={0}
            />
          </div>
        </TableContainer>
      </>
    </>
  );
};

export default AdminMktTable;

const StyledPagination = styled(Pagination)`
  .MuiPaginationItem-root {
    /* Establece el ancho deseado para los botones de paginación */
    height: 30px; /* Establece la altura deseada para los botones de paginación */
    border-radius: 8px; /* Elimina cualquier redondeo en los botones */
    color: #0e79b4;
    width: 30px;
  }

  .MuiPaginationItem-root.Mui-selected {
    background-color: #cfe4f0;
  }
  .MuiPaginationItem-root.Mui-selected:hover {
    background-color: #cfe4f0;
  }
`;

const FlexTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto 20px;
  border-radius: 8px;
  color: #14181a;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 20px;
  overflow-x: auto;
  ::-webkit-scrollbar {
    height: 16px; /* Anchura de la barra de desplazamiento en navegadores Webkit */
  }

  ::-webkit-scrollbar-thumb:horizontal {
    background-color: #b9cad0; /* Barra translúcida */
    border-radius: 16px; /* Bordes redondeados */
    width: 4px;
    margin: 4px;
    background-clip: content-box;
    border: 5px solid transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: #eff3f8;
    border-radius: 16px;
  }
`;

const ContainerTableHeader = styled.div`
  display: ${(props) => (props.isAdminDashboard ? 'none' : 'flex')};
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
`;

const ContaierTableHeaderInner = styled.div`
  display: flex;
  align-items: start;
  gap: 24px;
  flex-direction: column;
  width: 100%;
`;

const ContainerTableText = styled.p`
  color: var(--neutral-abism, #14181a);
  text-align: center;

  /* COMMON/Body_Bold_Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 16.8px */
  margin: 0;
`;

const ContainerTableInput = styled.input`
  display: flex;
  height: 48px;
  padding: 16px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  align-self: stretch;
  display: flex;
  height: 48px;
  padding: 16px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  align-self: stretch;
  color: var(--neutral-storm, #8299a3);
  text-align: center;
  border-radius: 8px;
  border: 1px solid var(--neutral-cloud, #b9cad0);
  background: var(--neutral-white, #fff);
  width: fit-content;
  max-width: 100px;

  /* COMMON/Body */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;

const ContainerTableButtonAplicar = styled.button`
  display: flex;
  width: 194px;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border-color: #0d3564;
  border-style: solid;
  cursor: pointer;
  background: var(--primarydeep, #0d3564);
  color: var(--neutral-white, #fff);
  /* COMMON/Button */
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  &:disabled {
    background: var(--neutral-cloud, #b9cad0);
    color: var(--neutral-white, #fff);
    cursor: not-allowed;
    border: 1px solid var(--neutral-cloud, #b9cad0);
  }
`;

const ContainerTableButtonBorrar = styled.button`
  display: flex;
  width: 194px;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--primarydeep, #0d3564);
  background: var(--neutral-white, #fff);
  color: var(--primarydeep, #0d3564);
  cursor: pointer;
  /* COMMON/Button */
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const FlexTableRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  grid-template-columns: auto auto auto auto auto auto auto auto;
  min-width: fit-content;
  width: 100%;

  &:nth-child(even) {
    background-color: #f7f7f7;
  }
`;

const FlexTableHeaderRow = styled(FlexTableRow)`
  background-color: #ffffff;
  font-weight: bold;
  border-bottom: 1px solid #dcdede;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: #55646a;
  @media (max-width: 1023px) {
    margin: 15px 0; /* Agrega margen lateral en modo móvil */
  }
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-height: 50px;
  text-align: center;
  min-width: 100px;
  width: 100%;
  padding: 0 8px;
  nav @media (max-width: 1023px) {
    margin: 15px; /* Agrega margen lateral en modo móvil */
  }
`;

const PrecioMkt = styled.div`
  display: flex;
  padding: 8px 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--neutral-cloud, #b9cad0);
  background: var(--neutral-white, #fff);
`;

const ButtonTableIngresar = styled.button`
  display: flex;
  height: auto;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--primarydeep, #0d3564);
  background: var(--neutral-white, #fff);
  color: var(--primarydeep, #0d3564);
  cursor: pointer;
  /* COMMON/Button */
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
`;

const EditContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  gap: 16px;
`;

const StyledSvg = styled.img`
  width: 24px;
  cursor: pointer;
`;
const TableContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 20px 54px 0px rgba(13, 53, 100, 0.15);
`;
