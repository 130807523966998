import { useState } from 'react';
import styled from 'styled-components';
import { url } from '../api';
import { Modal } from '@mui/material';
const eyePass = `${url}eyepassword.svg`;
const eyeInput = `${url}EyeInput.svg`;
const lock = `${url}Lock.svg`;
const DownArrow = `${url}downArrow.svg`;

export const GeneralContainer = styled.div`
  //max-width: 1440px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  margin: 0 50%;
  transform: translate(-50%, 0);
  @media (max-width: 1023px) {
    overflow-y: auto;
    max-height: 80vh;
  }
`;

export const Background = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction};
  gap: ${(props) => props.gap};
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
  width: ${(props) => props.width || 'fit-content'};
  background: ${(props) => props.bg};
  height: ${(props) => props.height};
  min-height: ${(props) => props.minHeight};
  max-height: ${(props) => props.maxHeight};
  min-width: ${(props) => props.minWidth};
  max-width: ${(props) => props.maxWidth};
  position: ${(props) => props.position};
  padding: ${(props) => props.padding};
  flex-wrap: ${(props) => (props.wrap ? 'wrap' : 'nowrap')};
  align-self: ${(props) => props.alignSelf};
  margin: ${(props) => props.margin};
  position: relative;
  ${(props) => props.extras}
  @media (max-width: 1023px) {
    ${(props) => props.mobile}
  }

  ::-webkit-scrollbar {
    height: 16px; /* Anchura de la barra de desplazamiento en navegadores Webkit */
  }

  ::-webkit-scrollbar-thumb:horizontal {
    background-color: #b9cad0; /* Barra translúcida */
    border-radius: 16px; /* Bordes redondeados */
    width: 4px;
    margin: 4px;
    background-clip: content-box;
    border: 5px solid transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: #ffffff;
    border-radius: 16px;
  }
`;

export const FlexSticky = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction};
  gap: ${(props) => props.gap};
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
  width: ${(props) => props.width || 'fit-content'};
  background: ${(props) => props.bg};
  height: ${(props) => props.height};
  min-height: ${(props) => props.minHeight};
  max-height: ${(props) => props.maxHeight};
  min-width: ${(props) => props.minWidth};
  max-width: ${(props) => props.maxWidth};
  position: ${(props) => props.position};
  padding: ${(props) => props.padding};
  flex-wrap: ${(props) => (props.wrap ? 'wrap' : 'nowrap')};
  align-self: ${(props) => props.alignSelf};
  margin: ${(props) => props.margin};
  position: sticky;
  top: 10px;
  left: 0px;
  ${(props) => props.extras}
  @media (max-width: 1023px) {
    ${(props) => props.mobile}
  }
`;

export const Text = styled.div`
  font-family: ${(props) => props.family || 'Mulish'};
  font-size: ${(props) => props.size || '16px'};
  font-style: normal;
  font-weight: ${(props) => props.weight || '400'};
  max-width: ${(props) => props.maxWidth};
  min-width: ${(props) => props.minWidth};
  width: ${(props) => props.width};
  text-align: ${(props) => props.textAlign};
  color: ${(props) => props.color};
  line-height: ${(props) => props.lineHeight};
  text-decoration: ${(props) => props.decoration};
  ${(props) => props.extras}
  @media (max-width: 1023px) {
    ${(props) => props.mobile}
  }
  position: relative;
`;

export const UList = styled.ul`
  font-family: ${(props) => props.family || 'Mulish'};
  font-size: ${(props) => props.size || '16px'};
  font-style: normal;
  font-weight: ${(props) => props.weight || '400'};
  max-width: ${(props) => props.maxWidth};
  min-width: ${(props) => props.minWidth};
  width: ${(props) => props.width};
  text-align: ${(props) => props.textAlign};
  color: ${(props) => props.color};
  line-height: ${(props) => props.lineHeight};
  text-decoration: ${(props) => props.decoration};
  ${(props) => props.extras}
  @media (max-width: 1023px) {
    ${(props) => props.mobile}
  }
  position: relative;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction};
  gap: ${(props) => props.gap};
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
  width: ${(props) => props.width || 'fit-content'};
  background: ${(props) => props.bg || '#F4F4F4'};
  height: ${(props) => props.height};
  position: ${(props) => props.position};
  padding: ${(props) => props.padding};
  min-height: ${(props) => props.minHeight};
  max-height: ${(props) => props.maxHeight};
  min-width: ${(props) => props.minWidth};
  max-width: ${(props) => props.maxWidth};
  box-shadow: ${(props) =>
    props.shadow || '0px 8px 20px 0px rgba(0, 76, 151, 0.25)'};
  @media (max-width: 1023px) {
    ${(props) => props.mobile}
  }
`;

export const Button = styled.button`
  cursor: pointer;
  outline: none;
  border: ${(props) => (props.secondary ? '1px solid #0D3564' : 'none')};
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 8px;
  cursor: pointer;
  background: ${(props) => (props.secondary ? '#ffffff' : '#0d3564')};
  color: ${(props) => (props.secondary ? '#0d3564' : '#ffffff')};
  width: ${(props) => props.width || 'fit-content'};
  min-width: ${(props) => props.minwidth || 'fit-content'};
  max-width: ${(props) => props.maxwidth || '100%'};
  height: 40px;
  ${(props) =>
    props.disabled
      ? 'background:#DCDEDE; color: rgba(130, 153, 163, 1);'
      : null}
  ${(props) => props.extras}
  @media (max-width: 1023px) {
    ${(props) => props.mobile}
  }
`;

export const StyledInput = styled.input`
  width: ${({ width }) => width || '100%'};
  width: ${({ minwidth }) => minwidth || ''};
  height: 48px;
  padding: 16px 8px;
  border-radius: 8px;
  border: 1px solid #b9cad0;
  background: #ffffff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  outline: none;
  &:focus {
    border: 1px solid #0d3564;
  }
  ${({ disabled }) =>
    disabled && 'border: 1px solid #DCDEDE; background: #F4F4F4;'}

  ${({ error }) => error && 'border: 1px solid #D22121; background: #FFF;'}

  @media(max-width: 1023px) {
    ${({ mobile }) => mobile}
  }
`;

export const StyledSelect = styled.select`
  width: 100%;
  height: 48px;
  border: 1px solid #b9cad0;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  box-sizing: border-box;
  border-radius: 8px;
  outline: none;
  padding: 0 12px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(${DownArrow});
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding-right: 48px;

  ::-ms-internal-expand {
    display: none; /* Ocultar el icono de despliegue en Microsoft Edge */
  }
  ::-ms-expand {
    display: none; /* Ocultar el icono de despliegue en Internet Explorer */
  }

  &:focus {
    border: 1px solid #0d3564;
  }

  option {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    background-color: #fff;
    :hover {
      background-color: #8299a3;
    }
  }

  option:disabled {
    color: #8299a3;
  }

  ${({ error }) => error && 'border: 1px solid #D22121; background: #FFF;'}
`;
const StyledInputPassword = styled.div`
  width: 100%;
  height: 48px;
  padding: 0 8px 0;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #b9cad0;
  background: #ffffff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  outline: none;
  background: ${(props) => (props.disabled ? '#DCDEDE' : null)};
  ${({ focus }) => (focus ? 'border: 1px solid #0d3564;' : null)};
`;

const UnstyledInput = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: none;
  letter-spacing: ${(props) => props.letterSpacing};
`;

export const Layout = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

export const ModalBox = styled.div`
  width: 100%;
  max-width: 630px;
  padding: 40px 56px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 0px 8px 24px 0px rgba(0, 32, 74, 0.4);
  display: flex;
  @media (max-width: 1023px) {
    padding: 40px;
    gap: 20px;
  }
`;

export const MarketIcon = styled.img`
  height: 100px;
  width: auto;
  @media (max-width: 1023px) {
    height: 60px;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 40px;
  box-shadow: 0px 8px 24px 0px rgba(0, 32, 74, 0.4);
  gap: 20px;
  width: 100%;
  max-width: 680px;
  align-items: center;
`;

const Title = styled.h2`
  font-family: Mulish;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 38.4px */
  color: #0d3564;
  margin: 0;
  text-align: center;
`;

const Subtitle = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  color: #14181a;
  text-align: center;
`;

export const Tooltip = styled.div`
  position: absolute;
  width: 300px;
  padding: 15px;
  background-color: #eff3f8;
  border: 2px solid #eff3f8;
  border-radius: 10px;
  margin: 20px;
  bottom: 30px;
  left: -30px;
`;

export const TooltipExtra = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #eff3f8;
  bottom: -18px;
  left: 143px;
  transform: translateX(-50%);
`;

export const StyledPassword = ({
  placeholder,
  handleChange,
  disabled,
  versiontwo,
  error,
}) => {
  const [show, setShow] = useState(false);
  const [focus, setFocus] = useState(false);
  return (
    <>
      <StyledInputPassword
        disabled={disabled}
        style={error ? { border: '1px solid red' } : null}
        focus={focus}
      >
        {versiontwo ? (
          <>
            <img src={lock} alt='temproalText' />
          </>
        ) : null}
        <UnstyledInput
          type={show ? 'text' : 'password'}
          placeholder={placeholder}
          onChange={handleChange}
          disabled={disabled}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
        {show ? (
          <img
            src={eyeInput}
            onClick={() => setShow(!show)}
            alt='temproalText'
          />
        ) : (
          <img
            src={eyePass}
            onClick={() => setShow(!show)}
            alt='temproalText'
          />
        )}
      </StyledInputPassword>
    </>
  );
};

export const StyledInputIcon = ({ icon, handleChange }) => {
  return (
    <>
      <StyledInputPassword>
        <img
          src={icon}
          style={{ maxWidth: '20px', width: '20px', height: 'auto' }}
          alt='temproalText'
        />
        <UnstyledInput onChange={handleChange}></UnstyledInput>
      </StyledInputPassword>
    </>
  );
};

export const AlertModal = ({
  open,
  setOpen,
  title,
  subtitle,
  buttonText,
  buttonOnClick,
  secondaryButtonText,
  secondaryButtonOnClick,
}) => {
  return (
    <Modal open={open} setOpen={setOpen}>
      <Layout>
        <ModalContent>
          {title && <Title>{title}</Title>}
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
          <Button onClick={buttonOnClick}>
            {buttonText ? buttonText : 'Cerrar'}
          </Button>
          {secondaryButtonText && (
            <Button secondary onClick={secondaryButtonOnClick}>
              {secondaryButtonText}
            </Button>
          )}
        </ModalContent>
      </Layout>
    </Modal>
  );
};
