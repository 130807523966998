/* eslint-disable */
import React from 'react';

import { Modal } from '@mui/material';
import {
  Button,
  Flex,
  Layout,
  ModalBox,
  Text,
} from '../../../styles/common.styles';
const ModalPublish = ({ open, setOpen }) => {
  return (
    <>
      <Modal open={open}>
        <Layout>
          <ModalBox>
            <Text size='32px' weight='800' color='#0D3564'>
              Publicar producto
            </Text>
            <Text family='Inter' size='16px' weight='400' color='#14181A'>
              ¿Estás seguro que deseas publicar el producto?{' '}
            </Text>
            <Flex align='center' gap='24px' justify='center'>
              <Button width='194px'>Publicar</Button>
              <Button secondary width='194px' onClick={() => setOpen(false)}>
                Cancelar
              </Button>
            </Flex>
          </ModalBox>
        </Layout>
      </Modal>
    </>
  );
};

export default ModalPublish;
