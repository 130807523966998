/*  eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import TitleAlta from '../TilteAlta/TitleAlta';
import DatosGenerales from '../DatosGenerales/DatosGenerales';
import DireccionForm from '../DireccionForm/DireccionForm';
import Distribuidores from '../Distribuidores/Distribuidores';
import { GET_ALL_GROUPS, GET_INFO_GROUP } from '../../../../graphql/Query';
import { DELE_USER } from '../../../../graphql/Mutation';
import Loader from '../../common/Loader';
import { GeneralContainer } from '../ExtraStyles.styles';

export default function AltaDistribuidor({ setClose }) {
  const [showDistribuidores, setShowDistribuidores] = useState('');
  const [newGroup, setNewGroup] = useState(true);
  const [groupDone, setGroupDone] = useState(false);
  const [listGroups, setListGroups] = useState([]);
  const [listBrands, setListBrands] = useState([]);
  const [listD2, setListD2] = useState([]);
  const [listD3, setListD3] = useState([]);
  const [currentGroup, setCurrentGroup] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tempData, setTempData] = useState({});
  const [missingFields, setMissingFields] = useState({
    nameGroup: true,
    nameUser: true,
    brands: true,
    street: true,
    numberOutside: true,
    cp: true,
    mail: true,
    phone: true,
    selectGroup: true,
    typeUser: true,
    idDistribuidor: true,
    selectd2: true,
    selectd3: true,
    selectedColony: true,
  });
  const [createClick, setCreateClick] = useState(false);
  const [clearData, setClearData] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [mobile, setMobile] = useState(windowWidth);

  // Función para manejar cambios en el tamaño de la ventana
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Efecto secundario para suscribirse a los cambios en el tamaño de la ventana
  useEffect(() => {
    // Agregar el evento de cambio de tamaño de la ventana
    window.addEventListener('resize', handleResize);

    // Limpiar el evento cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 1440) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [windowWidth]);

  const [getGroups] = useLazyQuery(GET_ALL_GROUPS, {
    fetchPolicy: 'no-cache',
    onCompleted({ getAllGroups }) {
      if (getAllGroups.statusCode === 200) {
        const info = JSON.parse(getAllGroups.response);
        setListGroups(info);
      }
    },
  });

  const [getDataGroups] = useLazyQuery(GET_INFO_GROUP, {
    fetchPolicy: 'no-cache',
    onCompleted({ getInfoGroups }) {
      if (getInfoGroups.statusCode === 200) {
        const info = JSON.parse(getInfoGroups.response);
        const list = info.map((item) => {
          if (item.name_brand) {
            return {
              id: item.id_catalog_brands,
              name: item.name_brand,
            };
          } else {
            return {
              id: item.catalog_brand.id_catalog_brands,
              name: item.catalog_brand.name_brand,
            };
          }
        });
        setListBrands(list);
      }
    },
  });

  const [getUsersGroups] = useLazyQuery(GET_INFO_GROUP, {
    //Admin-login y nos lleva a add-user
    fetchPolicy: 'no-cache',
    onCompleted({ getInfoGroups }) {
      if (getInfoGroups.statusCode === 200) {
        const info = JSON.parse(getInfoGroups.response);
        setShowDistribuidores(info[0]?.group_name);
        setAllUsers(info);
        const d2 = info.filter((item) => item.type_user === 3);
        const d3 = info.filter((item) => item.type_user === 4);
        setListD2(d2);
        setListD3(d3);
      }
    },
  });

  const [deleteUserMutation] = useMutation(DELE_USER, {
    fetchPolicy: 'no-cache',
    onCompleted({ deleteUser }) {
      reloadUsers();
    },
  });

  const reloadUsers = (idFather) => {
    if (!idFather) {
      setListBrands([]);
      getDataGroups({
        variables: {
          id: currentGroup,
          type: 'brands',
        },
      });
    }
    getUsersGroups({
      variables: {
        id: idFather ? idFather.toString() : currentGroup,
        type: 'users',
      },
    });
    setLoading(false);
  };

  const deleteUser = (id) => {
    setLoading(true);
    deleteUserMutation({
      variables: {
        idUser: id.toString(),
      },
    });
  };

  const handleEditUser = (item, isD1) => {
    window.scrollTo(0, 0);
    setTempData({ ...item, isD1: isD1 });
  };

  const getInfoGroup = (groupId, fatherId) => {
    setListBrands([]);
    setCurrentGroup(fatherId);
    getDataGroups({
      variables: {
        id: groupId,
        type: 'brands',
      },
    });
    getUsersGroups({
      variables: {
        id: fatherId,
        type: 'users',
      },
    });
  };

  useEffect(() => {
    getDataGroups({
      variables: {
        type: 'brands',
      },
    });
  }, []);

  useEffect(() => {
    if (groupDone) {
      getGroups();
      setShowDistribuidores('');
    } else {
      setShowDistribuidores('');
      getDataGroups({
        variables: {
          type: 'brands',
        },
      });
    }
  }, [groupDone]);

  useEffect(() => {
    setTempData({});
    // setClearData(true)
  }, [groupDone]);

  return (
    <div style={{ width: '100%', padding: '1.5rem' }} onClick={setClose}>
      <Loader open={loading} />
      <TitleAlta
        newGroup={newGroup}
        groupDone={groupDone}
        setNewGroup={setNewGroup}
        setGroupDone={setGroupDone}
      />
      <GeneralContainer>
        <div style={{ width: '100%' }}>
          <DatosGenerales
            clearData={clearData}
            setClearData={setClearData}
            newGroup={newGroup}
            groupDone={groupDone}
            listGroups={listGroups}
            getInfoGroup={getInfoGroup}
            listBrands={listBrands}
            listD2={listD2}
            listD3={listD3}
            missingFields={missingFields}
            createClick={createClick}
            setMissingFields={setMissingFields}
            tempData={tempData}
            mobile={mobile}
          />
        </div>
        <div style={{ width: '100%' }}>
          <DireccionForm
            setClearData={setClearData}
            reloadUsers={reloadUsers}
            setShowDistribuidores={setShowDistribuidores}
            missingFields={missingFields}
            createClick={createClick}
            setCreateClick={setCreateClick}
            setMissingFields={setMissingFields}
            setLoading={setLoading}
            tempData={tempData}
            clearData={clearData}
            mobile={mobile}
          />
        </div>
      </GeneralContainer>
      {showDistribuidores !== '' && (
        <Distribuidores
          allUsers={allUsers}
          deleteUser={deleteUser}
          showDistribuidores={showDistribuidores}
          handleEditUser={handleEditUser}
        />
      )}
    </div>
  );
}
