import styled, { css } from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const InputContainer = styled.div`
  width: 35%;
  margin-bottom: 1.5rem;
  position: relative;
  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 1.5rem;
  }
`;

export const InputLabel = styled.p`
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
`;

export const InputField = styled.input`
  width: 100%;
  height: 48px;
  padding: 16px 8px;
  padding-left: 30px;
  border-radius: 8px;
  border: 1px solid #b9cad0;
  background: #fff;
  color: #8299a3;
`;
export const IconUserEye = styled.img`
  width: 20px;
  height: 15px;
  position: absolute;
  bottom: 18px;
  right: 10px;
`;

export const IconUser = styled.img`
  width: 12px;
  height: 15px;
  position: absolute;
  bottom: 18px;
  left: 10px;
`;

export const ContainerManter = styled.div`
  width: 35%;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
    gap: 0.5rem;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
`;

export const Mantener = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  color: #55646a;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const DividerContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const Olvide = styled.p`
  color: #0d3564;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const ButtonLogIn = styled.button`
  display: flex;
  width: 194px;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #0d3564;
  color: #fff;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 32px;
  margin-bottom: 16px;
  cursor: pointer;
  ${(props) =>
    props.disabled &&
    css`
      background-color: lightgray; // Set the background color for disabled state
      cursor: not-allowed; // Change cursor style for disabled state
    `}
  @media (max-width: 768px) {
    width: 90%;
  }
  outline: none;
  border: none;
  box-shadow: none;
`;

export const Divider = styled.div`
  width: 167.393px;
  height: 1px;
  background: #8299a3;
`;

export const Enlace = styled.p`
  color: #0e79b4;
  text-align: center;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
`;

export const Errormsg = styled.p`
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: #d22121;
  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 10px;
  }
`;
