import React, { useEffect, useState } from 'react';
//styles
//assets
import { url } from '../../../api';
import {
  Overlay,
  MainContainer,
  CloseContainer,
  ButtonCargar,
  ButtonSelect,
  CargaArchivosContainer,
  DescriptionText,
  InputModal,
  MainTitle,
  SelectContainer,
  Subtitle,
  TextCargar,
  TextCargarWarning,
  UploadImg,
  UploadImgeHeader,
  LabelSelect,
  DimensionsContainer,
  ButtonContainer,
  Xtext,
  TextAreaModal,
  Flex,
  UnstyledInput,
  InputModalSuffix,
  VerticalDivider,
} from './ModalEditarAdminMktstyles';
import { Modal } from '@mui/material';
import { useMutation } from '@apollo/client';
import { useDropzone } from 'react-dropzone';
import { UPLOAD_FILE } from '../../../../graphql/Mutation';
import upload from '../../../assets/archivo.svg';

const edit = `${url}Editar.svg`;
const close = `${url}CloseButton.png`;
export default function ModalEditAdminMkt({
  showModalEdit,
  setShowModalEdit,
  handleEdit,
}) {
  const [uploadFile] = useMutation(UPLOAD_FILE);
  const [file, setFile] = useState(null);
  const [valid, setValid] = useState(true);

  const onDrop = async (acceptedFiles) => {
    setFile(acceptedFiles[0]);
  };

  const saveFile = async () => {
    if (
      formEditData.height !== '' &&
      formEditData.length !== '' &&
      formEditData.width !== '' &&
      formEditData.weight !== ''
    ) {
      try {
        const { data } = await uploadFile({
          variables: {
            file,
            name: `${showModalEdit?.repair || 'repair'}-${
              showModalEdit?.id_autopart || 'id_autopart'
            }-${showModalEdit?.id_part || 'id_part'}`,
          },
        });

        handleEdit({
          ...formEditData,
          image: data.singleUpload,
        });
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    } else {
      console.log('probanding');
      setValid(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': [],
    },
    multiple: false, // Disable multiple file selection   // Allow only one file
  });

  const [formEditData, setFormEditData] = useState({
    name: showModalEdit?.repair || '',
    part: showModalEdit?.id_autopart || '',
    partId: showModalEdit?.id_part || '',
    description: showModalEdit?.description || '',
    averageCost: showModalEdit?.average_price || '',
    marketPlacePrice: showModalEdit?.final_price || '',
    height: showModalEdit?.height || '',
    width: showModalEdit?.width || '',
    length: showModalEdit['length'] || '',
    weight: showModalEdit?.weight || '',
    image: showModalEdit?.image || '',
    postInMarketPlace: false,
  });

  useEffect(() => {
    console.log(formEditData);
    return () => {
      // limpieza del efecto
    };
  }, [formEditData]);

  return (
    <Modal open>
      <Overlay>
        <MainContainer>
          <div
            onClick={() => setShowModalEdit(false)}
            style={{
              position: 'absolute',
              top: '1rem',
              right: '1rem',
              fontSize: '24px',
              cursor: 'pointer',
              fontWeight: '800',
            }}
          >
            🞨
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '32px' }}>
            <UploadImgeHeader src={edit} />
            <MainTitle>Editar </MainTitle>
          </div>

          <DescriptionText>
            Necesitamos que nos apoyes a completar la siguiente información para
            poder asociar tu producto en el sistema.
          </DescriptionText>
          <Flex>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                width: '100%',
              }}
            >
              <Subtitle>Información general</Subtitle>
              <SelectContainer>
                <LabelSelect>Nombre del producto</LabelSelect>
                <InputModal
                  onChange={(e) =>
                    setFormEditData({ ...formEditData, name: e.target.value })
                  }
                  value={formEditData?.name}
                  placeholder='Colocar nombre del producto'
                />
              </SelectContainer>
              <SelectContainer>
                <LabelSelect>Parte</LabelSelect>
                <InputModal
                  disabled
                  value={formEditData?.part}
                  placeholder='Parte'
                />
              </SelectContainer>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  width: '100%',
                }}
              >
                <SelectContainer>
                  <LabelSelect>ID Parte</LabelSelect>
                  <InputModal
                    disabled
                    value={formEditData?.partId}
                    placeholder='ID Parte'
                  />
                </SelectContainer>

                <SelectContainer>
                  <LabelSelect>ID Distribuidor</LabelSelect>
                  <InputModal placeholder='ID Distribuidor' />
                </SelectContainer>
              </div>
              <SelectContainer>
                <LabelSelect>Descripción</LabelSelect>
                <TextAreaModal
                  onChange={(e) =>
                    setFormEditData({
                      ...formEditData,
                      description: e.target.value,
                    })
                  }
                  value={formEditData?.description}
                  placeholder='Ingresa una breve descripción de la pieza'
                />
              </SelectContainer>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                width: '100%',
              }}
            >
              <Subtitle>Costos</Subtitle>
              <SelectContainer>
                <LabelSelect>Costo promedio</LabelSelect>
                <InputModal
                  onChange={(e) =>
                    setFormEditData({
                      ...formEditData,
                      averageCost: e.target.value,
                    })
                  }
                  value={formEditData?.averageCost}
                  placeholder='Costo promedio'
                />
              </SelectContainer>
              <SelectContainer>
                <LabelSelect>Precio Market Place </LabelSelect>
                <InputModal
                  onChange={(e) =>
                    setFormEditData({
                      ...formEditData,
                      marketPlacePrice: e.target.value,
                    })
                  }
                  value={formEditData?.marketPlacePrice}
                  placeholder='Precio Market Place '
                />
              </SelectContainer>

              <Subtitle>Volumetría</Subtitle>

              <DimensionsContainer>
                <SelectContainer>
                  <LabelSelect>Alto</LabelSelect>
                  <InputModalSuffix
                    validIn={formEditData.height == '' && valid == false}
                  >
                    <UnstyledInput
                      onChange={(e) => {
                        const nuevoValor = e.target.value.replace(
                          /[^0-9.]/g,
                          ''
                        );
                        setFormEditData({
                          ...formEditData,
                          height: nuevoValor,
                        });
                      }}
                      value={formEditData?.height}
                      placeholder='Colocar alto del producto'
                    />
                    <div style={{ width: '100%' }}>mm</div>
                  </InputModalSuffix>
                </SelectContainer>
                <Xtext>X</Xtext>
                <SelectContainer>
                  <LabelSelect>Ancho</LabelSelect>
                  <InputModalSuffix
                    validIn={formEditData.width == '' && valid == false}
                  >
                    <UnstyledInput
                      onChange={(e) => {
                        const nuevoValor = e.target.value.replace(
                          /[^0-9.]/g,
                          ''
                        );
                        setFormEditData({
                          ...formEditData,
                          width: nuevoValor,
                        });
                      }}
                      value={formEditData?.width}
                      placeholder='Colocar ancho del producto'
                    />
                    <div>mm</div>
                  </InputModalSuffix>
                </SelectContainer>
                <Xtext>X</Xtext>
                <SelectContainer>
                  <LabelSelect>Largo</LabelSelect>
                  <InputModalSuffix
                    validIn={formEditData.length == '' && valid == false}
                  >
                    <UnstyledInput
                      onChange={(e) => {
                        const nuevoValor = e.target.value.replace(
                          /[^0-9.]/g,
                          ''
                        );
                        setFormEditData({
                          ...formEditData,
                          length: nuevoValor,
                        });
                      }}
                      value={formEditData?.length}
                      placeholder='Colocar largo del producto'
                    />
                    <div>mm</div>
                  </InputModalSuffix>
                </SelectContainer>
              </DimensionsContainer>
              <SelectContainer>
                <LabelSelect>Peso</LabelSelect>
                <InputModalSuffix
                  style={{ maxWidth: '100%' }}
                  validIn={formEditData.weight == '' && valid == false}
                >
                  <UnstyledInput
                    style={{ width: '30%' }}
                    onChange={(e) => {
                      const nuevoValor = e.target.value.replace(/[^0-9.]/g, '');

                      setFormEditData({
                        ...formEditData,
                        weight: nuevoValor,
                      });
                    }}
                    value={formEditData?.weight}
                    placeholder='Colocar peso del producto'
                  />
                  <div>kg</div>
                </InputModalSuffix>
              </SelectContainer>
            </div>
          </Flex>

          <Subtitle>Subir imagen del producto</Subtitle>
          <DescriptionText style={{ textAlign: 'left' }}>
            Sube o cambia el archivo
          </DescriptionText>
          <CargaArchivosContainer>
            <div style={{ display: 'flex', gap: '24px', alignItems: 'center' }}>
              <UploadImg src={upload} />
              <TextCargar>Rines Guadalajara .jgp</TextCargar>
            </div>
            <VerticalDivider />

            <TextCargarWarning>
              Formato .jpg/.png - peso de 10MB
            </TextCargarWarning>

            <div>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <ButtonSelect as='label' htmlFor='fileInput'>
                  Cambiar
                </ButtonSelect>
              </div>
            </div>
          </CargaArchivosContainer>

          <ButtonContainer>
            <ButtonSelect onClick={() => setShowModalEdit(false)}>
              Cancelar
            </ButtonSelect>
            <ButtonCargar onClick={() => saveFile()}>Cargar</ButtonCargar>
          </ButtonContainer>
        </MainContainer>
      </Overlay>
    </Modal>
  );
}
