import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import RecoverPass from '../screens/RecoverPassword';
import RecoverPassDistributor from '../screens/RecoverPasswordDistributor';
import Dashboard from '../screens/Dashboard';
import DashboardDistributor from '../screens/DashboardDistributor';
import TestPegasus from '../screens/TestPegasus';
import Notifications from '../screens/Notifications';
import OptionsNotifications from '../screens/notificationsOptions';
import Cliente from '../screens/Client';
import InsideProduct from '../screens/insideProduct';
import SearchProducts from '../screens/SearchProducts';
// import Shopping from '../screens/Shopping';
// import TableMov from '../components/Dashboard/TableMov';
// import TableMovDis from '../components/DashboardDistributor/TableMov';
// import ClientStore from '../components/Dashboard/ClientStore';
// import ClientStoreDistributor from '../components/DashboardDistributor/ClientStore';
// import AllProducts from '../components/Ecommerce/AllProducts';
// import DashboardAdmin from '../components/DashboardAdmin/DashboardAdmin';
// import ClientStoreAdmin from '../components/DashboardAdmin/ClientStoreAdmin';
import CatMkt from '../screens/catMkt';
import MercadoPagoScreen from '../screens/MercadoPago';
import PaymentScreen from '../screens/MercadoPago/PaymentScreen';
import Terms from '../screens/PoliciesTerms/Terms';
import { Privacy } from '../screens/Privacy/Privacy';
import TyC from '../front2/components/LegalTexts/TyC';
import AvisoPrivacidad from '../front2/components/LegalTexts/AvisoPrivacidad';
import MainPage from '../front2/views/MainPage';
import Store from '../front2/views/Store';
import NewAccount from '../front2/views/NewAccount';
import PasswordRecovery from '../front2/views/PasswordRecovery';
import { LogInV2 } from '../front2/views/InicioSesion/LogInV2';
import QuienesSomosPage from '../front2/components/LegalTexts/QuienesSomosPage';
import AddUser from '../front2/views/AddUser/AddUser';
import AdminLogin from '../front2/views/AdminLogin';
import PrivateRoute from '../front2/views/ProtectedRoutes';
import PrivateRouteStore from '../front2/views/ProtectedRoutes/Paymet';
import { UnauthorizedUser } from '../front2/views/UnauthorizedUser';
import Cart from '../front2/views/Cart';
import DetailItem from '../front2/views/DetailItem';
import DetailItemComponent from '../front2/components/DetailItem/DetailItemComponent';
import ResultCart from '../front2/views/ResultCart';
import Chatbot from '../front2/components/Chatbot/Chatbot';
import ScrollToTop from '../hooks/topHook';

const typeDefs = gql`
  extend input SellInfo {
    ID: ID
    id_parte: String
    id_grupo: Int
    id_distribuidor: Int
    email: String
    cuantity: Int
    email: String
    Proveedor: String
    Precio_de_Venta: String
  }

  extend input ShipmentElements {
    name: String
    service: String
    cuantity: Int
    id_parte: String
  }

  extend input Identification {
    type: String
    number: String
  }

  extend input Payer {
    email: String
  }

  extend input Parcel {
    ID: ID
    cuantity: Int
  }

  extend type Quotation {
    amount_local: Int
    currency_local: String
    provider: String
    service_level_name: String
    service_level_code: String
    days: Int
    insurable: Boolean
    out_of_area_service: Boolean
    out_of_area_pricing: Boolean
    total_pricing: Int
    is_occure: Boolean
  }
`;

// Configurar el enlace HTTP
const httpUploadLink = createUploadLink({
  uri: 'https://api.dev.ballena.therocketcode.com/',
});

const getToken = () => {
  return sessionStorage.getItem('token');
};

const authLink = setContext((_, { headers }) => {
  const token = getToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : 'NoDisponible', // Agregar el encabezado solo si hay un token
    },
  };
});

function RouterApp() {
  const client = new ApolloClient({
    link: authLink.concat(httpUploadLink),
    /*
    link: createUploadLink({
      uri: 'https://api.dev.ballena.therocketcode.com/',
      // uri: "http://localhost:5001/api",
    }),
    */
    cache: new InMemoryCache(),
    //typeDefs,
  });
  return (
    <ApolloProvider client={client}>
      <Router>
        <ScrollToTop />
        <Chatbot />
        <Switch>
          <Route exact path='/'>
            {/* <Login /> */}
            <MainPage />
          </Route>
          {/* <Route exact path="/distributor">
            <LoginDistributor />
          </Route> */}
          <Route exact path='/payment-result/:id'>
            <PaymentScreen />
          </Route>
          <Route exact path='/product'>
            <DetailItem />
          </Route>
          <Route exact path='/recover'>
            <RecoverPass />
          </Route>
          <Route exact path='/recoverdis'>
            <RecoverPassDistributor />
          </Route>
          <Route exact path='/dashboard'>
            <Dashboard />
          </Route>
          <Route exact path='/dashboardMovimientos'>
            {/* <TableMov /> */}
          </Route>
          <Route exact path='/ClientStore'>
            {/* <ClientStore /> */}
          </Route>
          <Route exact path='/ClientStoreAdmin'>
            {/* <ClientStoreAdmin /> */}
          </Route>
          <Route exact path='/dashboardMovimientosDis'>
            {/* <TableMovDis /> */}
          </Route>
          <Route exact path='/dashboardAdmin'>
            {/* <DashboardAdmin /> */}
          </Route>
          <Route exact path='/dashboardDis'>
            <DashboardDistributor />
          </Route>
          <Route exact path='/testApiPegasus'>
            <TestPegasus />
          </Route>
          <Route exact path='/dashboardDis/ClientStore'>
            {/* <ClientStoreDistributor /> */}
          </Route>
          <Route exact path='/notifications'>
            <Notifications />
          </Route>
          <Route exact path='/setup'>
            <OptionsNotifications />
          </Route>
          {/* <Route exact path='/store'>
            <Ecommerce />
          </Route> */}
          <Route exact path='/AllProducts'>
            {/* <AllProducts /> */}
          </Route>
          <Route exact path='/cliente'>
            <Cliente />
          </Route>
          <Route exact path='/searchProducts'>
            <SearchProducts />
          </Route>
          <Route exact path='/insideProduct'>
            <InsideProduct />
          </Route>
          <Route exact path='/shopping'>
            {/* <Shopping /> */}
          </Route>
          <Route exact path='/terms'>
            <Terms />
          </Route>
          <Route exact path='/advice'>
            <AvisoPrivacidad />
          </Route>
          <Route exact path='/login'>
            <LogInV2 />
          </Route>

          {/* <Route exact path="/test">
            <Test />
          </Route> */}

          {/* <Route exact path='/start'>
            <StartMkt />{' '}
          </Route> */}
          <Route exact path='/categorias/:category/:categoryId'>
            <CatMkt />{' '}
          </Route>
          <PrivateRouteStore exact path='/payment'>
            <MercadoPagoScreen />
          </PrivateRouteStore>
          {/* //! */}
          <Route exact path='/terminos-y-condiciones'>
            <TyC />
          </Route>
          <Route exact path='/aviso-de-privacidad'>
            <AvisoPrivacidad />
          </Route>
          <Route exact path='/store'>
            <Store />
          </Route>
          <Route exact path='/store/searchresult'>
            <Store />
          </Route>
          <Route exact path='/store/cart'>
            <Cart />
          </Route>
          <Route exact path='/store/cart/resultPayment'>
            <ResultCart />
          </Route>
          <Route exact path='/create-account'>
            <NewAccount />
          </Route>
          <Route exact path='/recover-password'>
            <PasswordRecovery />
          </Route>
          <Route exact path='/quienes-somos'>
            <QuienesSomosPage />
          </Route>
          <PrivateRoute exact path='/admin/dashboard'>
            <AddUser />
          </PrivateRoute>
          <Route exact path='/unauthorized'>
            <UnauthorizedUser />
          </Route>
          <Route exact path='/admin'>
            <AdminLogin />
          </Route>
          <Route exact path='/store/detail/:id'>
            <DetailItemComponent />
          </Route>
        </Switch>
      </Router>
    </ApolloProvider>
  );
}
export default RouterApp;
