import styled from 'styled-components';
import { Button, Flex, Text } from '../../styles/common.styles';
import { Divider } from '../common/ModalMenu/ModalMenu.styles';
import { useEffect, useState } from 'react';
import ModalDelete from './ModalDelete';
import {
  formatNumberWithTwoDecimals,
  stringToNumber,
} from '../../../helpers/shopCart';
import useShoppingCart from '../../../hooks/useShoppingCart';

export const ItemCardContainer = styled.div`
  background: #ffffff;
  // box-shadow: 0px 20px 54px 0px rgba(13, 53, 100, 0.15);
  max-width: 302px;
  width: 100%;
  height: 464px;
  height: fit-content;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  position: relative;
  @media (min-width: 1023px) {
    height: 280px;
    max-width: 100%;
    flex-direction: row;
  }
  @media (max-width: 1024px) {
    min-width: 100%;
    width: 100%;
  }
`;

const ImgContainer = styled.div`
  width: 180px;
  height: 180px;
  min-height: 200px;
  max-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1023px) {
    height: 163px;
    width: 163px;
  }
`;

const ImgStyled = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
  cursor: pointer;
`;

const StyledButton = styled.button`
  font-family: Nunito Sans;
  font-weight: 700;
  font-size: 1.5rem;
  box-sizing: border-box;
  border: 0;
  border-radius: 999px;
  color: #55646a;
  background: transparent;
  width: 30px;
  height: 30px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: #e6f6fa;
    cursor: pointer;
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
  }
`;

export const ItemCard = ({
  item,
  handleBuy,
  finalPrice,
  setFinalPice,
  setCountItems,
  countItems,
}) => {
  const { updateItemsQuantity } = useShoppingCart();
  const [quantity, setQuantity] = useState(1);
  const [currentPrice, setCurrentPice] = useState(
    stringToNumber(item.price) * item.qty
  );

  useEffect(() => {
    setQuantity(item.qty);
    setCurrentPice(stringToNumber(item.price) * item.qty);
  }, [item.qty, item]);

  const addQty = () => {
    const newQuantity = quantity + 1;
    if (newQuantity <= item.existence) {
      // setQuantity(newQuantity);
      // setCurrentPice(newQuantity * stringToNumber(item.price))
      // setFinalPice(finalPrice + stringToNumber(item.price))
      // setCountItems(countItems + 1);
      updateItemsQuantity(item, newQuantity);
    }
  };

  const substractQty = () => {
    const newQuantity = quantity - 1;
    if (newQuantity >= 1) {
      // setQuantity(newQuantity);
      // setCurrentPice(newQuantity * stringToNumber(item.price))
      // setFinalPice(finalPrice - stringToNumber(item.price))
      // setCountItems(countItems - 1);
      updateItemsQuantity(item, newQuantity);
    }
  };

  // useEffect(() => {
  //   //! Here: effect a ejecutar en cambio de qty
  // }, [quantity]);

  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <ModalDelete
        id={item.id}
        open={openModal}
        setOpen={setOpenModal}
        single
      />

      <ItemCardContainer>
        <ImgContainer>
          <ImgStyled alt='' src={item.img} />
        </ImgContainer>
        <Flex direction='column' width='100%' height='100%' gap='16px'>
          <Flex
            direction='row'
            width='100%'
            height='100%'
            gap='32px'
            mobile='flex-direction: column;'
          >
            <Flex
              direction='column'
              width='65%'
              height='100%'
              gap='16px'
              mobile='width: 100%;'
            >
              <Flex
                direction='row'
                align='center'
                justify='space-between'
                width='100%'
                gap='1rem'
              >
                <Text color='#0D3564' weight='700'>
                  Existencia:{' '}
                  <span style={{ fontWeight: '400', color: '#32B7D7' }}>
                    {item.existence}
                  </span>
                </Text>
                <Text color='#0D3564' weight='700'>
                  No. Parte:{' '}
                  <span style={{ fontWeight: '400' }}>{item.partNumber}</span>
                </Text>
              </Flex>
              <Text
                width='100%'
                family='Inter'
                color='#55646A'
                extras='max-height: 76px; overflow: hidden; text-overflow: ellipsis;'
              >
                {item.description}
              </Text>
            </Flex>
            <Flex
              direction='column'
              width='35%'
              height='100%'
              mobile='width: 100%; max-width: 280px;'
            >
              <Text color='#0D3564' weight='700'>
                Precio
              </Text>
              <Flex
                direction='row'
                width='100%'
                height='100%'
                align='center'
                justify='space-between'
                gap='1rem'
              >
                <Text color='#55646A' weight='400' family='Inter' size='14px'>
                  Subtotal:
                </Text>
                <Text color='#0E79B4' weight='700' family='Inter' sixze='18px'>
                  ${formatNumberWithTwoDecimals(currentPrice)} MXN
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Divider />
          <Flex
            direction='row'
            width='100%'
            gap='32px'
            align='center'
            mobile='flex-direction:column;'
          >
            <Flex direction='row' width='fit-content' gap='32px' align='center'>
              <Text color='#0D3564' weight='700'>
                Cantidad
              </Text>
              <Flex
                direction='row'
                gap='15px'
                width='fit-content'
                align='center'
              >
                <StyledButton onClick={() => substractQty()}>-</StyledButton>{' '}
                <Text family='Nunito Sans' weight='600' color='#55646A'>
                  {quantity}
                </Text>
                <StyledButton onClick={() => addQty()}>+</StyledButton>
              </Flex>
            </Flex>
            <Flex
              direction='row'
              width='100%'
              gap='32px'
              align='center'
              mobile='justify-content: center;'
            >
              <Text
                color='#0D3564'
                weight='700'
                width='100%'
                maxWidth='155px'
                textAlign='center'
                style={{ cursor: 'pointer' }}
                onClick={() => setOpenModal(true)}
              >
                Eliminar
              </Text>
              {/* <Button secondary width='100%' onClick={() => handleBuy()}>
                Comprar
              </Button> */}
            </Flex>
          </Flex>
        </Flex>
      </ItemCardContainer>
    </>
  );
};
