/* eslint-disable */
import React from 'react';

import { Modal } from '@mui/material';
import {
  Button,
  Flex,
  Layout,
  ModalBox,
  Text,
} from '../../../styles/common.styles';

import { url } from '../../../api';

const warning = `${url}warnigSign.svg`;
const TurnOffModal = ({ open, setOpen }) => {
  return (
    <>
      <Modal open={open}>
        <Layout>
          <ModalBox>
            <Flex gap='16px'>
              <img src={warning} />
              <Text size='32px' weight='800' color='#0D3564'>
                Apagar producto{' '}
              </Text>
            </Flex>
            <Text family='Inter' size='16px' weight='400' color='#14181A'>
              ¿Estás seguro que deseas apagar el producto?
            </Text>
            <Flex align='center' gap='24px' justify='center'>
              <Button width='194px'>Eliminar</Button>
              <Button secondary width='194px' onClick={() => setOpen(false)}>
                Cancelar
              </Button>
            </Flex>
          </ModalBox>
        </Layout>
      </Modal>
    </>
  );
};

export default TurnOffModal;
