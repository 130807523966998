/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  Text,
  StyledInput,
  StyledSelect,
} from '../../../styles/common.styles';
import { url } from '../../../api';
import PhoneInput from 'react-phone-input-2';
import Card from '../Atoms/Card';
const iconContact = `${url}contactDataIcon.svg`;
import mercado from '../assets/mercadoPago.svg';
import ItemCard from '../Atoms/ItemCard';
import useMercadoPago from '../../../../hooks/useMercadoPago';
import useShipmentCart from '../../../../hooks/useShippment';
import useShoppingCart from '../../../../hooks/useShoppingCart';
import {
  formatNumberWithTwoDecimals,
  stringToNumber,
} from '../../../../helpers/shopCart';
import paymentsColor from '../../../assets/paymentsColor.svg';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ScrollToTop from '../../../../hooks/topHook';

const INITIAL_STATE = {
  cvc: '',
  cardExpirationMonth: '',
  cardExpirationYear: '',
  focus: 'cardNumber',
  cardholderName: '',
  cardNumber: '',
  issuer: '',
};

const Payment = ({ setStepSelected, items, setLoading }) => {
  const [claves, setClaves] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [countItems, setCountItems] = useState(0);
  const [groups, setGroups] = useState([]);
  const [totalShipment, setTotalShipment] = useState(0);
  const { shippmentsAvailables } = useShipmentCart();

  useEffect(() => {
    const sub = items.reduce(
      (accumulator, currentValue) =>
        accumulator + stringToNumber(currentValue.price) * currentValue.qty,
      0
    );
    const count = items.reduce(
      (accumulator, currentValue) => accumulator + currentValue.qty,
      0
    );
    setSubTotal(sub);
    setCountItems(count);
  }, [items]);

  useEffect(() => {
    let add = 0;
    if (shippmentsAvailables?.length > 0 || shippmentsAvailables) {
      for (const selectedShipment in shippmentsAvailables) {
        add =
          add +
          stringToNumber(
            shippmentsAvailables[selectedShipment].selectedShipment.costo
          );
      }
      setTotalShipment(add);
    }
  }, [shippmentsAvailables]);

  useEffect(() => {
    let nuevoObjeto = {};
    items.forEach((x) => {
      if (!nuevoObjeto.hasOwnProperty(x.id_uac)) {
        nuevoObjeto[x.id_uac] = {
          data: [],
        };
      }
      nuevoObjeto[x.id_uac].data.push(x);
    });
    let tempclaves = Object.keys(nuevoObjeto);
    setGroups(nuevoObjeto);
    setClaves(tempclaves);
  }, [items]);

  const [resultPayment, loading, errors] = useMercadoPago();
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);
  const handleInputChange = (e) => {
    const targetName = e.target.dataset.name || e.target.name;
    const targetValue = e.target.value;

    switch (targetName) {
      case 'cardNumber':
        e.target.value = targetValue.replace(/[^\d\s]/g, '');
        break;
      case 'cardExpirationMonth':
      case 'cardExpirationYear':
      case 'cvc':
        e.target.value = targetValue.replace(/[^\d]/g, '');
        break;
      case 'cardholderName':
        e.target.value = targetValue.replace(/[^a-zA-Z\s]/g, '');
        break;
      case 'identificationNumber':
        e.target.value = targetValue.replace(/[^\w\d\s-]/g, '');
        break;
      default:
        break;
    }

    setState((prev) => ({
      ...prev,
      [targetName]: e.target.value,
    }));
  };

  const handleInputFocus = (e) => {
    setState({ ...state, focus: e.target.dataset.name || e.target.name });
  };

  useEffect(() => {
    if (resultPayment && errors.length <= 0 && !resultPayment.error) {
      if (resultPayment.status === 'approved')
        dispatch(deleteAllShopElements());
      history.push(`/payment-result/${resultPayment.sellId}`);
    } else {
    }
  }, [resultPayment]);

  const [validEmail, setValidEmail] = useState(false);
  const [validForm, setValidForm] = useState({
    cardNumber: '',
    caducity: '',
    cvcNumber: '',
    ownerName: '',
    email: '',
    bankName: '',
    cuotes: '',
  });

  useEffect(() => {
    return () => {
      // limpieza del efecto
    };
  }, [validForm]);

  const handleValidation = (type, e) => {
    if (type === 'email') {
      const value = e.target.value;
      const regex = /^[^\s@]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const newValue = value.replace(/[^\w@.-]+/g, '');
      e.target.value = newValue;
      if (regex.test(newValue) === true) {
        setValidEmail(true);
      } else {
        setValidEmail(false);
      }
      setValidForm({ ...validForm, email: newValue });
    }
    if (type === 'cardNumber') {
      const value = e.target.value;
      // const regex = /^[^\s@]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      let newValue = value
        .replace(/\D/g, '')
        .replace(/(\d{4})/g, '$1 ')
        .trim();
      if (newValue.length > 19) {
        newValue = newValue.slice(0, 19);
      }
      e.target.value = newValue;
      // if (regex.test(newValue) === true) {
      //   setValidEmail(true);
      // } else {
      //   setValidEmail(false);
      // }
      setValidForm({ ...validForm, cardNumber: newValue });
    }
    if (type === 'cvc') {
      const value = e.target.value;
      // const regex = /^[^\s@]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      let newValue = value.replace(/\D/g, '').trim();
      if (newValue.length > 3) {
        newValue = newValue.slice(0, 3);
      }
      e.target.value = newValue;
      // if (regex.test(newValue) === true) {
      //   setValidEmail(true);
      // } else {
      //   setValidEmail(false);
      // }
      setValidForm({ ...validForm, cvcNumber: newValue });
    }
    if (type === 'caducity') {
      const value = e.target.value;
      let newValue = value
        .replace(/\D/g, '') // Elimina caracteres no numéricos
        .slice(0, 4) // Limita la longitud total a 4 caracteres
        .split('') // Divide la cadena en un array de caracteres
        .map((char, index) => {
          if (index === 2) {
            return ' / ' + char; // Agrega " / " después de los primeros 2 dígitos
          } else {
            return char;
          }
        })
        .join(''); // Convierte el array de caracteres nuevamente en una cadena
      e.target.value = newValue;
      setValidForm({ ...validForm, caducity: newValue });
    }

    if (type === 'name') {
      const value = e.target.value;
      const regex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/; // Expresión regular para aceptar letras y espacios
      let newValue = value.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ\s]/g, ''); // Elimina caracteres no permitidos
      e.target.value = newValue;

      if (regex.test(newValue)) {
        setValidForm({ ...validForm, ownerName: newValue });
      }
    }
  };

  useEffect(() => {
    return () => {
      // limpieza del efecto
    };
  }, [validForm]);

  return (
    <>
      <ScrollToTop />
      <Text
        color='#004C97'
        width='100%'
        textAlign='center'
        size='40px'
        weight='800'
      >
        Método de pago
      </Text>

      <Flex
        width='100%'
        direction='row'
        gap='40px'
        mobile='flex-direction: column;'
        extras='border-top: 0.74px solid rgba(0, 0, 0, 0.35);'
        padding='64px 0 0'
      >
        <Flex
          width='100%'
          direction='column'
          gap='40px'
          minWidth='530px'
          mobile='min-width: 0;'
        >
          <form id='form-checkout'>
            <Flex
              //Parcial Form Container
              width='100%'
              gap='24px'
              direction='column'
              align='flex-start'
            >
              {/* <Flex width='100%' direction='row' justify='center'>
              <Card info={validForm} />
            </Flex> */}
              <Flex
                //Title container
                width='100%'
                gap='32px'
                direction='row'
                align='center'
                margin='0 0 16px'
              >
                <img src={iconContact} />
                <Text size='28px' weight='800' color='#0D3564'>
                  Datos de tarjeta
                </Text>
              </Flex>
              <Flex
                // Row/Column Container
                direction='row'
                width='100%'
                gap='24px'
                mobile='flex-direction: column;'
              >
                <Flex
                  //Input container
                  direction='column'
                  gap='4px'
                  width='100%'
                >
                  <Text family='Inter' size='12px' weight='400'>
                    Número de tarjeta
                  </Text>
                  <StyledInput
                    type='tel'
                    placeholder='0000-0000-0000-0000'
                    name='cardNumber'
                    id='form-checkout__cardNumber'
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    // onChange={(e) => handleValidation('cardNumber', e)}
                  />
                </Flex>
                <Flex
                  // Row/Column Container
                  direction='row'
                  width='100%'
                  gap='24px'
                  margin='0 0 24px'
                >
                  <Flex
                    //Input container
                    direction='column'
                    gap='4px'
                    width='100%'
                  >
                    <Text family='Inter' size='12px' weight='400'>
                      Fecha de caducidad
                    </Text>
                    <Flex
                      //Input container
                      direction='row'
                      gap='4px'
                      width='100%'
                      align='center'
                      justify='center'
                      style={{
                        borderRadius: '8px',
                        border: '1px solid #b9cad0',
                        width: '100%',
                      }}
                    >
                      <StyledInput
                        type='tel'
                        name='cardExpirationMonth'
                        id='form-checkout__cardExpirationMonth'
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        maxLength={2}
                        width='40px'
                        style={{
                          outline: 'none',
                          border: 'none',
                          paddingRight: '0',
                        }}
                        // type='text'
                        // placeholder='mm / yy'
                        // onChange={(e) => handleValidation('caducity', e)}
                      />
                      /
                      <StyledInput
                        type='tel'
                        name='cardExpirationYear'
                        id='form-checkout__cardExpirationYear'
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        maxLength={2}
                        width='40px'
                        style={{
                          outline: 'none',
                          border: 'none',
                          paddingRight: '0',
                        }}
                        // type='text'
                        // placeholder='mm / yy'
                        // onChange={(e) => handleValidation('caducity', e)}
                      />
                    </Flex>
                  </Flex>
                  <Flex
                    //Input container
                    direction='column'
                    gap='4px'
                    width='100%'
                  >
                    <Text family='Inter' size='12px' weight='400'>
                      CVC
                    </Text>
                    <StyledInput
                      type='tel'
                      name='cvc'
                      id='form-checkout__securityCode'
                      onChange={handleInputChange}
                      onFocus={handleInputFocus}
                      maxLength={3}
                      // type='text'
                      // placeholder='---'
                      // onChange={(e) => handleValidation('cvc', e)}
                    />
                  </Flex>{' '}
                  {/* //!!!!!!!!! aquiiii */}
                </Flex>
              </Flex>
            </Flex>

            <Flex
              //Parcial Form Container
              width='100%'
              gap='24px'
              direction='column'
              align='flex-start'
            >
              <Flex
                // Row/Column Container
                direction='row'
                width='100%'
                gap='24px'
                mobile='flex-direction: column;'
              >
                <Flex
                  // Row/Column Container
                  direction='row'
                  width='100%'
                  gap='24px'
                  mobile='flex-direction: column;'
                >
                  <Flex
                    //Input container
                    direction='column'
                    gap='4px'
                    width='100%'
                  >
                    <Text family='Inter' size='12px' weight='400'>
                      Titular de la tarjeta
                    </Text>
                    <StyledInput
                      type='text'
                      name='cardholderName'
                      id='form-checkout__cardholderName'
                      onChange={handleInputChange}
                      onFocus={handleInputFocus}
                      // type='text'
                      // placeholder='Nombre'
                      // onChange={(e) => handleValidation('name', e)}
                    />
                  </Flex>
                  <Flex
                    //Input container
                    direction='column'
                    gap='4px'
                    width='100%'
                  >
                    <Text family='Inter' size='12px' weight='400'>
                      Correo electrónico
                    </Text>
                    <StyledInput
                      type='email'
                      name='cardholderEmail'
                      id='form-checkout__cardholderEmail'
                      onFocus={handleInputFocus}
                      // type='text'
                      // placeholder='Ingresa tu correo electrónico'
                      // onChange={(e) => handleValidation('email', e)}
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                // Row/Column Container
                direction='row'
                width='100%'
                gap='24px'
                mobile='flex-direction: column;'
              >
                <Flex
                  // Row/Column Container
                  direction='row'
                  width='100%'
                  gap='24px'
                  mobile='flex-direction: column;'
                >
                  <Flex
                    //Input container
                    direction='column'
                    gap='4px'
                    width='100%'
                  >
                    <Text family='Inter' size='12px' weight='400'>
                      Banco emisor
                    </Text>

                    <StyledSelect
                      name='issuer'
                      id='form-checkout__issuer'
                      on
                      // onChange={(e) =>
                      //   setValidForm({ ...validForm, bankName: e.target.value })
                      // }
                    >
                      {/* <option disabled selected value=''>
                        Selecciona una opción
                      </option>
                      <option value='1'>prueba</option> */}
                    </StyledSelect>
                  </Flex>
                  <Flex
                    //Input container
                    direction='column'
                    gap='4px'
                    width='100%'
                  >
                    <Text family='Inter' size='12px' weight='400'>
                      Cuotas
                    </Text>
                    <StyledSelect
                      name='installments'
                      id='form-checkout__installments'
                      // onChange={(e) =>
                      //   setValidForm({ ...validForm, cuotes: e.target.value })
                      // }
                    >
                      {/* <option disabled selected value=''>
                        Selecciona una opción
                      </option>
                      <option value='1'>prueba</option> */}
                    </StyledSelect>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              width='100%'
              justify='center'
              direction='column'
              align='center'
              margin='32px 0 0'
            >
              <Text
                family='Inter'
                size='12px'
                color='#55646A'
                textAlign='center'
              >
                Métodos de pago disponibles
              </Text>
              <img alt='' src={paymentsColor} />
              <Text
                textAlign='center'
                family='Inter'
                size='12px'
                style={{ margin: '32px 0 0' }}
                color='#022741'
              >
                Tus datos personales se utilizarán para procesar tu pedido,
                mejorar tu experiencia en esta web y otros propósitos descritos
                en nuestra{' '}
                <Link
                  target='_blank'
                  to='/advice'
                  style={{
                    textDecoration: 'none',
                    fontWeight: '700',
                    color: '#022741',
                  }}
                >
                  política de privacidad.
                </Link>
                <br />
                <br />
                Al continuar, aceptas nuestros{' '}
                <Link
                  target='_blank'
                  to='/terminos-y-condiciones'
                  style={{
                    textDecoration: 'none',
                    fontWeight: '700',
                    color: '#022741',
                  }}
                >
                  Términos y condiciones
                </Link>
              </Text>
            </Flex>
            <Flex
              //Input container
              direction='row'
              gap='4px'
              width='100%'
              justify='center'
              mobile='justify-content: center;'
            >
              <Button
                disabled={false}
                // disabled={
                //   validEmail &&
                //     validForm.cardNumber !== '' &&
                //     validForm.cardNumber.length === 19 &&
                //     validForm.caducity !== '' &&
                //     validForm.caducity.length === 7 &&
                //     validForm.cvcNumber !== '' &&
                //     validForm.cvcNumber.length === 3 &&
                //     validForm.ownerName !== '' &&
                //     validForm.ownerName.length >= 2 &&
                //     validForm.email !== '' &&
                //     validForm.bankName !== '' &&
                //     validForm.cuotes !== ''
                //     ? false
                //     : true
                // }
                // onClick={() => setStepSelected(4)}
                type='submit'
                id='form-checkout__submit'
                width='100%'
                extras='max-width: 254px;'
                style={{ margin: '24px 0' }}
              >
                Pagar
              </Button>
              {/* <div className='form-control'>
                <select ></select>
              </div> */}
              <div className='form-control'>
                <input
                  type='hidden'
                  name='identificationNumber'
                  id='form-checkout__identificationNumber'
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                />
              </div>
              <div className='form-control'>
                {/* <select ></select> */}
                <select
                  style={{ display: 'none' }}
                  name='identificationType'
                  id='form-checkout__identificationType'
                ></select>
              </div>
              {/* <div className='form-control'>
                <button
                  type='submit'
                  id='form-checkout__submit'
                // disabled={shoppingValues.length < 1}
                >
                  Pagar
                </button>
              </div> */}
            </Flex>
          </form>
        </Flex>
        <Flex
          width='100%'
          direction='column'
          maxWidth='50%'
          minWidth='350px'
          mobile='max-width: 100%;'
        >
          <Flex
            width='100%'
            direction='row'
            justify='center'
            margin='0 0 32px;'
          >
            <img
              alt=''
              src={mercado}
              style={{ width: '120px', maxWidth: '120px', height: 'auto' }}
            />
          </Flex>
          <Flex
            padding='32px 16px'
            direction='column'
            gap='16px'
            extras='box-shadow: 0px 20px 54px 0px rgba(13, 53, 100, 0.15);'
            width='100%'
            mobile='padding: 32px;'
          >
            <Text
              color='#004C97'
              width='100%'
              textAlign='left'
              size='24px'
              weight='700'
            >
              Resumen de compra
            </Text>
            {claves?.map((currentItems, index) => (
              <ItemCard
                subTotal={subTotal}
                countItems={countItems}
                item={groups[currentItems].data}
                index={index}
              />
            ))}
            <Flex width='100%' direction='column' align='flex-end' gap='48px'>
              <Flex width='100%' direction='column' align='flex-end' gap='8px'>
                <Text font='Inter' color='#0D3564' width='fit-content;'>
                  Envío:{' '}
                  <span
                    style={{
                      fontSize: '16px',
                      color: '#8299A3',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    ${formatNumberWithTwoDecimals(totalShipment)} MXN
                  </span>
                </Text>
                <Text font='Inter' color='#0D3564' width='fit-content;'>
                  Subtotal
                  {/* ({countItems}{' '}
                  {countItems > 1 ? 'productos' : 'producto'}) */}
                  :{' '}
                  <span
                    style={{
                      fontSize: '16px',
                      color: '#8299A3',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    ${formatNumberWithTwoDecimals(subTotal)} MXN
                  </span>
                </Text>
              </Flex>
              <Text famili='Roboto' size='24px' weight='600' color='#022741'>
                <span style={{ fontWeight: '800' }}>Total:</span>
                &nbsp;&nbsp;&nbsp;&nbsp; $
                {formatNumberWithTwoDecimals(subTotal + totalShipment)} MXN
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {/* <form id='form-checkout'>
        <div className='form-control'>
          <input
            type='tel'
            name='cardNumber'
            id='form-checkout__cardNumber'
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className='form-control cvcExpiration'>
          <div id='expirationContainer'>
            <input
              type='tel'
              name='cardExpirationMonth'
              id='form-checkout__cardExpirationMonth'
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              maxLength={2}
            />
            <h5>/</h5>
            <input
              type='tel'
              name='cardExpirationYear'
              id='form-checkout__cardExpirationYear'
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              maxLength={2}
            />
          </div>
          <input
            type='tel'
            name='cvc'
            id='form-checkout__securityCode'
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            maxLength={3}
          />
        </div>
        <div className='form-control'>
          <input
            type='text'
            name='cardholderName'
            id='form-checkout__cardholderName'
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
          <input
            type='email'
            name='cardholderEmail'
            id='form-checkout__cardholderEmail'
            onFocus={handleInputFocus}
          />
        </div>
        <div className='form-control'>
          <select name='issuer' id='form-checkout__issuer' on></select>
        </div>
        <div className='form-control'>
          <input
            type="hidden"
            name='identificationNumber'
            id='form-checkout__identificationNumber'
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div className='form-control'>
          <select name='installments' id='form-checkout__installments'></select>
          <select style={{ display: "none" }} name='identificationType' id="form-checkout__identificationType"></select>
        </div>
        <div className='form-control'>
          <button
            type='submit'
            id='form-checkout__submit'
          // disabled={shoppingValues.length < 1}
          >
            Pagar
          </button>
        </div>
      </form> */}
    </>
  );
};

export default Payment;
