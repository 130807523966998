import React, { useState } from 'react';
import styled from 'styled-components';

const SwitchContainer = styled.label`
  position: relative;
  display: inline-block;
  width: 48px;
  height: 28px;
`;

const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const SwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ isChecked }) => (isChecked ? '#027CBB' : '#ccc')};
  border-radius: 28px;
  transition: 0.4s;

  &:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: ${({ isChecked }) => (isChecked ? 'calc(100% - 24px)' : '4px')};
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
  }
`;

const Switch = ({ checked: propChecked, onClick }) => {
  const [internalChecked, setInternalChecked] = useState(propChecked || false);

  const handleToggle = () => {
    const newCheckedValue = !internalChecked;
    setInternalChecked(newCheckedValue);
    if (onClick) {
      onClick(newCheckedValue);
    }
  };

  return (
    <SwitchContainer>
      <SwitchInput
        type='checkbox'
        checked={internalChecked}
        onChange={() => {}}
      />
      <SwitchSlider isChecked={internalChecked} onClick={handleToggle} />
    </SwitchContainer>
  );
};

export default Switch;
