/* eslint-disable jsx-a11y/alt-text */
import { useState } from 'react';
import {
  FooterCont,
  FooterRow,
  Flex,
  Text,
} from '../../styles/footer.styles.jsx';
import { Link } from 'react-router-dom';
import SellFormModal from '../MainPage/Modals/SellFormModal.jsx';
import FinishModal from '../MainPage/Modals/FinishModal.jsx';
import Payments from '../../assets/payments.svg';

import ModalComprarMayoreo from '../QuieresComprar/ModalComprarMayoreo';

// import { Link } from 'react-router-dom';
import ModalGracias from '../QuieresComprar/ModalGracias.jsx';
import { url } from '../../api.js';
const footerLogo = `${url}ballenafooterlogo.svg`;
const arroba = `${url}@.svg`;
const facebookImg = `${url}facebook.svg`;
const instagramImg = `${url}instagram.svg`;

const Footer = () => {
  const [openModalMayoreo, setOpenModalMayoreo] = useState(false);
  const [openModalGracias, setOpenModalGracias] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);

  return (
    <>
      {openModalMayoreo && (
        <ModalComprarMayoreo
          setOpenMayoreo={setOpenModalMayoreo}
          setOpenGracias={setOpenModalGracias}
          open={openModalMayoreo}
        />
      )}
      {openModalGracias && <ModalGracias setShow={setOpenModalGracias} />}
      <SellFormModal
        setOpen={setOpenModal1}
        open={openModal1}
        finishModal={setOpenModal2}
      />
      <FinishModal open={openModal2} setOpen={setOpenModal2} />
      <FooterRow mobile='gap: 16px;'>
        <FooterCont mobile='flex-direction: column; gap:16px; padding: 40px 32px; align-items:center;'>
          <Flex direction='column' gap='16px' mobile='align-items: center;'>
            <img src={footerLogo} style={{ width: '138px' }} />
            <Flex direction='column' gap='0px'>
              <Text
                familiy='Inter'
                size='16px'
                weight='400'
                color='#787B7B'
                mobile='text-align:center;'
              >
                Método de pago
              </Text>

              <img alt='' src={Payments} style={{ width: '100%' }} />
            </Flex>
          </Flex>
          <Flex direction='column' gap='16px' mobile='width: 100%;'>
            <Flex direction='column' gap='8px'>
              <Link style={{ color: 'white' }} to='/advice'>
                <Text>Políticas de privacidad</Text>
              </Link>
              <Link style={{ color: 'white' }} to='/terminos-y-condiciones'>
                <Text>Términos y condiciones</Text>
              </Link>
              <Link style={{ color: 'white' }} to='/quienes-somos'>
                <Text>Quiénes somos</Text>
              </Link>
              <Text
                style={{ cursor: 'pointer' }}
                onClick={() => setOpenModal1(true)}
              >
                ¿Quieres vender?
              </Text>
              <Text
                style={{ cursor: 'pointer' }}
                onClick={() => setOpenModalMayoreo(true)}
              >
                ¿Quieres comprar al mayoreo?
              </Text>
            </Flex>
          </Flex>
          <Flex direction='column' gap='16px' mobile='width: 100%;'>
            <Text size='20px' weight='700'>
              Contacto:
            </Text>
            <Flex direction='row' gap='8px' align='center'>
              <img src={arroba} style={{ width: '20px', height: '20px' }} />
              <Text>
                Correo electronico: <br />
                contactaballena@gmail.com
              </Text>
            </Flex>
          </Flex>
          <Flex
            direction='column'
            align='flex-start'
            justify='flex-start'
            gap='40px'
            mobile='align-items: flex-start; width:100%; gap: 32px;'
          >
            <Text
              maxWidth='200px'
              textAlign='left'
              mobile='text-align: left; max-width:100%;'
            >
              Tu camino a la excelencia automotriz: Ballena, originalidad y
              confianza en cada pieza
            </Text>

            <Flex
              direction='row'
              gap='31px'
              mobile='justify-content:center; width:100%;'
            >
              <a
                target='_blank'
                href='https://www.facebook.com/profile.php?id=100095132824148'
                rel='noopener noreferrer'
              >
                <img src={facebookImg} />
              </a>
              <a
                target='_blank'
                href='https://www.instagram.com/autopartes_ballena/'
                rel='noopener noreferrer'
              >
                <img src={instagramImg} />
              </a>
              {/*   <a href="https://www.linkedin.com/in/your_linkedin_profile_link">
                <Linkedin />
              </a> */}
            </Flex>
          </Flex>
        </FooterCont>
        <Text style={{ margin: '0 1rem 1rem', textAlign: 'center' }}>
          Copyright © 2020 | Todos los derechos reservados | México
        </Text>
      </FooterRow>
    </>
  );
};

export default Footer;
