/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  GET_ALL_USERS_MKTP,
  GET_REPORT_USERS_MARKETPLACE,
} from '../../../graphql/Query';

//styles
import {
  Title,
  TitleContainer,
  TitleImg,
  RowsContainer,
} from './UsuariosMkt.styles';
import ExpandableRow from './Atoms/ExpandableRow';
import { url } from '../../api';
import { Button, Flex } from '../../styles/common.styles';
//assets
const descuentos = `${url}descuentos.svg`;
//import initialData from './Mock.json'; // Import the

export default function UsuariosMkt() {
  //const [data, setData] = useState(initialData);
  const [user, setUser] = useState([
    {
      name: '',
      email: '',
      phone: '',
      adress: '',
      typeUser: '',
      discount: '',
    },
  ]);

  const [getAllUsers] = useLazyQuery(GET_ALL_USERS_MKTP, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const arrayUsers = data.getAllUsersMarketplace.map((user) => {
        switch (user.type_user_segment) {
          case 1:
            user.type_user_segment = 'U1';
            break;
          case 2:
            user.type_user_segment = 'U2';
            break;
          case 3:
            user.type_user_segment = 'U3';
            break;
          case 4:
            user.type_user_segment = 'U0';
            break;
          default:
            break;
        }
        return {
          name: user.name,
          email: user.email,
          phone: user.phone,
          adress: user.pais,
          typeUser: user.type_user_segment,
          discount: user.discount,
        };
      });
      setUser(arrayUsers);
    },
    onError(err) {},
  });

  const [getReport] = useLazyQuery(GET_REPORT_USERS_MARKETPLACE, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      console.log('data=>', data.getReportUserMarketplace.statusCode);
      if (data.getReportUserMarketplace.statusCode == 200) {
        // Convertir el base64 a un blob y crear un enlace de descarga
        const binaryData = atob(data.getReportUserMarketplace.data);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }
        const blob = new Blob([arrayBuffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'ReporteUsuariosMarketplace.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } else {
        console.log(
          'Error descarga de reporte=>',
          data.getReportUserMarketplace.error
        );
      }
    },
    onError(err) {},
  });

  useEffect(() => {
    getAllUsers();
  }, []);

  const filterUsersByType = (users, userType) => {
    return users.filter((user) => user.typeUser === userType);
  };

  const U0 = filterUsersByType(user, 'U0');
  const U1 = filterUsersByType(user, 'U1');
  const U2 = filterUsersByType(user, 'U2');
  const U3 = filterUsersByType(user, 'U3');

  const downloadReport = () => {
    getReport();
  };
  return (
    <div>
      <Flex
        wrap
        direction='row'
        justify='space-between'
        width='100%'
        extras='margin-bottom: 40px;'
        align='center'
        mobile='flex-direction: column; align-items: flex-start;'
        gap='32px'
      >
        <TitleContainer style={{ marginBottom: '0', width: 'fit-content' }}>
          <TitleImg src={descuentos} alt='' />
          <Title>Descuento de usuarios</Title>
        </TitleContainer>
        <Button onClick={() => downloadReport()} mobile='width:100%;'>
          Descarga registros
        </Button>
      </Flex>

      <RowsContainer>
        <ExpandableRow
          Title={'Usuarios Marketplace (U0)'}
          users={U0}
          isU0
          index={4}
          getAllUsers={getAllUsers}
        />
        <ExpandableRow
          Title={'Usuarios Marketplace (U1)'}
          users={U1}
          getAllUsers={getAllUsers}
          isU1
          index={1}
        />
        <ExpandableRow
          Title={'Usuarios Marketplace (U2)'}
          users={U2}
          getAllUsers={getAllUsers}
          isU2
          index={2}
        />
        <ExpandableRow
          Title={'Usuarios Marketplace (U3)'}
          users={U3}
          getAllUsers={getAllUsers}
          isU3
          index={3}
        />
      </RowsContainer>
    </div>
  );
}
