/* eslint-disable */
import React, { useState } from 'react';
import { url } from '../../api';
const edit = `${url}Editar.svg`;
const DeleteIcon = `${url}delete.svg`;
import styled from 'styled-components';
import Switch from './Switch';
import { Pagination } from '@mui/material';
import { Flex } from '../../styles/common.styles';
import { makeStyles } from '@material-ui/core';

const MockData = [
  {
    id: '123456',
    description: 'Difusión',
    movement: 'Lento',
    distribuitor: 'Vat. Distribuidor',
    cost: '$7,822.04',
    marketplace: true,
    mktPrice: '$7,782.04',
  },
  {
    id: '123457',
    description: 'Difusión',
    movement: 'Potencial obsoleto',
    distribuitor: 'Vat. Distribuidor',
    cost: '$7,822.04',
    marketplace: false,
    mktPrice: '$5,822.04',
  },
  {
    id: '123458',
    description: 'Difusión',
    movement: 'Lento',
    distribuitor: 'Vat. Distribuidor',
    cost: '$7,822.04',
    marketplace: true,
    mktPrice: '$822.04',
  },
  {
    id: '123459',
    description: 'Difusión',
    movement: 'Potencial obsoleto',
    distribuitor: 'Vat. Distribuidor',
    cost: '$7,822.04',
    marketplace: false,
    mktPrice: '$7,892.04',
  },
  {
    id: '1234510',
    description: 'Difusión',
    movement: 'Lento',
    distribuitor: 'Vat. Distribuidor',
    cost: '$22.04',
    marketplace: true,
    mktPrice: '$47,822.04',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaginationItem-page': {
      color: '#0E79B4', // Cambia el color de la fuente para la página seleccionada
    },
    '& .MuiPaginationItem-root.Mui-selected': {
      background: 'rgba(14, 121, 180, 0.2)', // Cambia el color de las flechas de elipsis
    },
    '& .MuiPaginationItem-root': {
      color: '#0E79B4',
    },
  },
}));

const DashboardTable = ({}) => {
  const classes = useStyles();
  const itemsPerPage = 2;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(MockData.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData = MockData.slice(startIndex, endIndex);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <>
        <FlexTable>
          <FlexTableHeaderRow>
            <Cell>No. de parte</Cell>
            <Cell>Descripción</Cell>
            <Cell>Tipo de movimiento</Cell>
            <Cell>Distribuidor</Cell>
            <Cell>Costo promedio</Cell>
            <Cell>Market Place</Cell>
            <Cell>Precio Market Place</Cell>
            <Cell> </Cell>
          </FlexTableHeaderRow>
          {displayedData.map((product, index) => (
            <FlexTableRow key={`product${index}`}>
              <Cell>{product.id}</Cell>
              <Cell>{product.description}</Cell>
              <Cell>{product.movement}</Cell>
              <Cell>{product.distribuitor}</Cell>
              <Cell>{product.cost}</Cell>
              <Cell>
                <Switch
                  checked={product.marketplace}
                  onClick={() => console.log('prueba')}
                />
              </Cell>
              <Cell>{product.mktPrice}</Cell>
              <Cell>
                <EditContainer>
                  <StyledSvg src={edit} />
                  {/* <StyledSvg src={DeleteIcon} /> */}
                </EditContainer>
              </Cell>
            </FlexTableRow>
          ))}
          <Flex width='100%' justify='flex-end'>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handleChangePage}
              classes={{ root: classes.root }}
              shape='rounded'
              color='primary'
            />
          </Flex>
        </FlexTable>
      </>
    </>
  );
};

export default DashboardTable;

const FlexTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  border-radius: 8px;
  color: #14181a;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  @media (max-width: 1023px) {
    overflow-x: auto;
  }
`;

const FlexTableRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  grid-template-columns: auto auto auto auto auto auto auto auto;

  &:nth-child(even) {
    background-color: #f7f7f7;
  }
`;

const FlexTableHeaderRow = styled(FlexTableRow)`
  background-color: #ffffff;
  font-weight: bold;
  border-bottom: 1px solid #dcdede;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: #55646a;
  @media (max-width: 1023px) {
    margin: 15px; /* Agrega margen lateral en modo móvil */
  }
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-height: 50px;
  text-align: center;
  @media (max-width: 1023px) {
    margin: 15px; /* Agrega margen lateral en modo móvil */
  }
`;

const EditContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  gap: 16px;
`;

const StyledSvg = styled.img`
  width: 24px;
  cursor: pointer;
`;
