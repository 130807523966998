/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
//styles
import {
  MainContainer,
  Title,
  ButtonActive,
  ButtonAdd,
  ButtonInactive,
  Imagen,
  InputAlta,
  InputContainer,
  LabelGeneral,
  TitleContainer,
  ContainerButtons,
} from './DatosGenerales.styles';
//components
import MarcasRegistradas from '../../MarcasRegistradas/MarcasRegistradas';
import DistribuidoresAdd from '../../common/DistribuidoresAdd/DistribuidoresAdd';
//redux
import { useDispatch, useSelector } from 'react-redux';
import { setDatosGenerales } from '../../../../redux/reducers/datosGrales.reducer';
import { InlineCont } from '../ExtraStyles.styles';
import { url } from '../../../api';
import { Flex, StyledSelect } from '../../../styles/common.styles';

const DownArrow = `${url}downArrow.svg`;
//assets
const users = `${url}users.svg`;

export default function DatosGenerales({
  newGroup,
  groupDone,
  listGroups,
  getInfoGroup,
  listBrands,
  listD2,
  listD3,
  missingFields,
  createClick,
  setMissingFields,
  clearData,
  setClearData,
  tempData,
  mobile,
}) {
  const dispatch = useDispatch();
  const datosGenerales = useSelector((state) => state.datosGenerales);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedDistribuidor, setSelectedDistribuidor] = useState('');
  const [selectedOptionsArray, setSelectedOptionsArray] = useState([]);
  const [
    selectedOptionsArrayDistribuidores,
    setSelectedOptionsArrayDistribuidores,
  ] = useState([]);
  const [showNewComponent, setShowNewComponent] = useState(false);
  const [showNewComponentDistribuidor, setShowNewComponentDistribuidor] =
    useState(false);
  const [activeButton, setActiveButton] = useState('activo');
  const [groupNameError, setGroupNameError] = useState('');
  const [groups, setGroups] = useState([]);
  const [brands, setBrands] = useState([]);
  const [usersd2, setListd2] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [usersd3, setListd3] = useState([]);
  const [nameBrand, setNameBrand] = useState([]);
  const [arrNameBrand, setArrNameBrand] = useState([]);

  const [formDataLocal, setFormDataLocal] = useState({
    selectValue: 0,
    groupId: 0,
    groupName: '',
    selectedUserType: '',
    userName: '',
    userStatus: 'activo',
    selectedBrands: '',
    selectedBrand: 0,
    idDistribuidor: '',
    selectedDistribuidor: '',
    selectedD2: 0,
    selectedD3: 0,
    userd1: 0,
  });

  useEffect(() => {
    !mobile && setOpenDrop(true);
  }, [mobile]);

  const handleFieldChange = (field, value, idFather, brand) => {
    if (field === 'selectedUserType') {
      if (value && value !== '') {
        if (value === 'D2') {
          setMissingFields({
            ...missingFields,
            typeUser: false,
            idDistribuidor: false,
            selectd2: false,
            selectd3: false,
            nameGroup: false,
          });
        }
        if (value === 'D3') {
          setMissingFields({
            ...missingFields,
            typeUser: false,
            idDistribuidor: false,
            selectd3: false,
            nameGroup: false,
          });
        }
        if (value === 'D4') {
          setMissingFields({
            ...missingFields,
            typeUser: false,
            idDistribuidor: true,
            selectd2: true,
            selectd3: true,
            nameGroup: false,
          });
        }
      } else {
        setMissingFields({
          ...missingFields,
          typeUser: true,
        });
      }
    }

    if (field === 'idDistribuidor') {
      if (value && value !== '') {
        setMissingFields({
          ...missingFields,
          idDistribuidor: false,
        });
      } else {
        setMissingFields({
          ...missingFields,
          idDistribuidor: true,
        });
      }
    }

    if (field === 'selectedD2') {
      if (value && value !== '') {
        setMissingFields({
          ...missingFields,
          selectd2: false,
        });
      } else {
        setMissingFields({
          ...missingFields,
          selectd2: true,
        });
      }
    }

    if (field === 'selectedD3') {
      if (value && value !== '') {
        setMissingFields({
          ...missingFields,
          selectd3: false,
        });
      } else {
        setMissingFields({
          ...missingFields,
          selectd3: true,
        });
      }
    }

    if (field === 'groupName') {
      // Perform validation for the groupName field
      if (!/^[A-Za-z\s]+$/.test(value)) {
        setGroupNameError(
          'Ingresa un nombre de distribuidor válido (solo texto)'
        );
        setMissingFields({
          ...missingFields,
          nameGroup: true,
        });
      } else {
        setGroupNameError('');
        setMissingFields({
          ...missingFields,
          nameGroup: false,
        });
      }
    }

    if (field === 'selectedBrand') {
      setSelectedOption(value);
      setNameBrand(brand);
    } else if (field === 'selectedDistribuidor') {
      setSelectedOption(value);
    }

    if (field === 'userName') {
      if (value && value !== '') {
        setMissingFields({
          ...missingFields,
          nameUser: false,
        });
      } else {
        setMissingFields({
          ...missingFields,
          nameUser: true,
        });
      }
    }
    if (field === 'groupId') {
      if (value && value !== '') {
        setMissingFields({
          ...missingFields,
          selectGroup: false,
        });
      } else {
        setMissingFields({
          ...missingFields,
          selectGroup: true,
        });
      }
      getInfoGroup(value, idFather);
      setFormDataLocal((prevFormData) => ({
        ...prevFormData,
        [field]: idFather,
      }));
      setFormDataLocal((prevFormData) => ({
        ...prevFormData,
        selectValue: value,
      }));
      dispatch(
        setDatosGenerales({
          ...datosGenerales,
          [field]: idFather,
          userd1: value,
        })
      );
    } else {
      if (idFather) {
        setFormDataLocal((prevFormData) => ({
          ...prevFormData,
          [field]: idFather,
        }));
        dispatch(setDatosGenerales({ ...datosGenerales, [field]: value }));
      } else {
        setFormDataLocal((prevFormData) => ({
          ...prevFormData,
          [field]: value,
        }));
        dispatch(setDatosGenerales({ ...datosGenerales, [field]: value }));
      }
    }
  };

  const handleButtonClick = () => {
    if (tempData?.isD1) return;
    if (selectedOption && !selectedOptionsArray.includes(selectedOption)) {
      setSelectedOptionsArray((prevOptions) => [
        ...prevOptions,
        selectedOption,
      ]);
      setArrNameBrand((prevOptions) => [...prevOptions, nameBrand]);
      setShowNewComponent(true);
    }
    setMissingFields({
      ...missingFields,
      brands: false,
    });
  };

  const handleEliminarOpcion = (index) => {
    if (tempData?.isD1) return;
    setArrNameBrand((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions.splice(index, 1);
      return updatedOptions;
    });
    setSelectedOptionsArray((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions.splice(index, 1);
      if (updatedOptions.length === 0) {
        setMissingFields({
          ...missingFields,
          brands: true,
        });
      }
      return updatedOptions;
    });
  };

  const handleButtonClickDistribuidor = () => {
    if (selectedDistribuidor) {
      setSelectedOptionsArrayDistribuidores((prevOptions) => [
        ...prevOptions,
        selectedDistribuidor,
      ]);
      setShowNewComponentDistribuidor(true);
    }
  };

  const handleEliminarOpcionDistribuidor = (index) => {
    setSelectedOptionsArrayDistribuidores((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions.splice(index, 1);
      return updatedOptions;
    });
  };

  const getTypeUser = (type) => {
    switch (type) {
      case 5:
        return 'D4';
      case 4:
        return 'D3';
      case 3:
        return 'D2';
      case 2:
        return 'D1';
      default:
        return '';
    }
  };

  useEffect(() => {
    setFormDataLocal((prevFormData) => ({
      ...prevFormData,
      selectedBrands: selectedOptionsArray.toString(),
    }));
    dispatch(
      setDatosGenerales({
        ...datosGenerales,
        selectedBrand: selectedOptionsArray.toString(),
      })
    );
  }, [selectedOptionsArray]);

  useEffect(() => {
    setIsEditing(false);
    setGroups(listGroups);
    setBrands(listBrands);
  }, [listGroups, groupDone, newGroup, listBrands]);

  useEffect(() => {
    if (newGroup) {
      setFormDataLocal({
        ...formDataLocal,
        selectedUserType: '',
      });
      dispatch(setDatosGenerales({ ...formDataLocal, selectedUserType: '' }));
    }
  }, [newGroup]);

  useEffect(() => {
    if (groupDone) {
      setMissingFields({
        ...missingFields,
        nameGroup: false,
        selectGroup: true,
        typeUser: true,
        idDistribuidor: true,
        selectd2: true,
        selectd3: true,
      });
    } else {
      setMissingFields({
        ...missingFields,
        nameGroup: true,
        selectGroup: false,
        typeUser: false,
        idDistribuidor: false,
        selectd2: false,
        selectd3: false,
      });
    }
  }, [groupDone]);

  useEffect(() => {
    setListd2(listD2);
    setListd3(listD3);
  }, [listD2, listD3]);

  useEffect(() => {
    if (clearData) {
      setFormDataLocal({
        selectValue: 0,
        groupId: 0,
        groupName: '',
        selectedUserType: '',
        userName: '',
        userStatus: 'activo',
        selectedBrands: '',
        selectedBrand: 0,
        idDistribuidor: '',
        selectedDistribuidor: '',
        selectedD2: 0,
        selectedD3: 0,
        userd1: 0,
      });
      setNameBrand([]);
      setArrNameBrand([]);
      setSelectedOptionsArray([]);
      setShowNewComponent(false);
      setShowNewComponentDistribuidor(false);
      setMissingFields({
        nameGroup: true,
        nameUser: true,
        brands: true,
        street: true,
        numberOutside: true,
        cp: true,
        mail: true,
        phone: true,
        selectGroup: true,
        typeUser: true,
        idDistribuidor: true,
        selectd2: true,
        selectd3: true,
      });
      setClearData(false);
    }
  }, [clearData]);

  useEffect(() => {
    if (tempData?.direccionesDistribuidors) {
      const tempBrands = [];
      const brandsNames = [];
      tempData?.users_brands?.map((item) => {
        console.log({ item });
        tempBrands.push(item.id_catalog_brands);
        brandsNames.push(item.catalog_brand.name_brand);
      });
      if (tempBrands.length > 0) {
        setShowNewComponent(true);
      }
      setSelectedOptionsArray([...tempBrands]);
      setArrNameBrand([...brandsNames]);

      setFormDataLocal({
        selectValue: tempData.catalog_user_id_d1,
        groupId: 0,
        groupName: '',
        selectedUserType: getTypeUser(tempData.type_user),
        userName: tempData.name,
        userStatus: 'activo',
        selectedBrands: JSON.stringify(tempBrands)
          .replace('[', '')
          .replace(']', ''),
        selectedBrand: JSON.stringify(tempBrands)
          .replace('[', '')
          .replace(']', ''),
        idDistribuidor: tempData?.id_distribuidor,
        selectedDistribuidor: '',
        selectedD2: tempData.catalog_user_id_d2,
        selectedD3: tempData.catalog_user_id_d3,
        userd1: 0,
      });
      dispatch(
        setDatosGenerales({
          selectValue: tempData.catalog_user_id_d1,
          groupId: 0,
          groupName: '',
          selectedUserType: getTypeUser(tempData.type_user),
          userName: tempData.name,
          userStatus: 'activo',
          selectedBrands: JSON.stringify(brands)
            .replace('[', '')
            .replace(']', ''),
          selectedBrand: tempBrands[0],
          idDistribuidor: tempData?.id_distribuidor,
          selectedDistribuidor: '',
          selectedD2: tempData.catalog_user_id_d2,
          selectedD3: tempData.catalog_user_id_d3,
          userd1: 0,
        })
      );

      setIsEditing(true);
      setMissingFields({
        nameGroup: false,
        nameUser: false,
        brands: false,
        street: false,
        numberOutside: false,
        cp: false,
        mail: false,
        phone: false,
        selectGroup: false,
        typeUser: false,
        idDistribuidor: false,
        selectd2: false,
        selectd3: false,
      });
    } else {
      setIsEditing(false);
      setFormDataLocal({
        selectValue: 0,
        groupId: 0,
        groupName: '',
        selectedUserType: '',
        userName: '',
        userStatus: 'activo',
        selectedBrands: '',
        selectedBrand: 0,
        idDistribuidor: '',
        selectedDistribuidor: '',
        selectedD2: 0,
        selectedD3: 0,
        userd1: 0,
      });
      setNameBrand([]);
      setArrNameBrand([]);
      setSelectedOptionsArray([]);
      setShowNewComponent(false);
      setShowNewComponentDistribuidor(false);
      setMissingFields({
        nameUser: true,
        brands: true,
        street: true,
        numberOutside: true,
        cp: true,
        mail: true,
        phone: true,
        selectedColony: true,
        nameGroup: true,
        selectGroup: false,
        typeUser: false,
        idDistribuidor: false,
        selectd2: false,
        selectd3: false,
      });
    }
  }, [tempData]);

  const [openDrop, setOpenDrop] = useState(false);

  return (
    <>
      <MainContainer>
        <Flex
          direction='row'
          justify='space-between'
          width='100%'
          onClick={() => mobile && setOpenDrop(!openDrop)}
          style={{ cursor: 'pointer' }}
        >
          <TitleContainer>
            <Imagen src={users} alt='' />
            <Title>Datos generales</Title>
          </TitleContainer>
          {mobile && (
            <img
              src={DownArrow}
              style={{ transform: openDrop && 'rotate(180deg)' }}
            />
          )}
        </Flex>
        {openDrop && (
          <>
            <InputContainer style={{ display: newGroup ? 'flex' : 'none' }}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                }}
              >
                <LabelGeneral>
                  Ingresa nombre de grupo distribuidor
                </LabelGeneral>
                <InputAlta
                  disabled={tempData?.isD1}
                  value={formDataLocal.groupName}
                  onChange={(e) =>
                    handleFieldChange('groupName', e.target.value)
                  }
                  placeholder='Nombre de grupo distribuidor'
                  type='text'
                />
                {groupNameError && (
                  <span style={{ color: 'red' }}>{groupNameError}</span>
                )}
                {createClick && missingFields.nameGroup && (
                  <span style={{ color: 'red' }}>Este campo es requerido</span>
                )}
              </div>
              {/* <div style={{}}>
                <ButtonCrear>Crear grupo</ButtonCrear>
            </div> */}
            </InputContainer>

            <InputContainer style={{ display: groupDone ? 'flex' : 'none' }}>
              <LabelGeneral>Selecciona tu grupo distribuidor</LabelGeneral>
              <StyledSelect
                disabled={isEditing}
                value={formDataLocal.selectValue}
                onChange={(e) =>
                  handleFieldChange(
                    'groupId',
                    e.target.value,
                    e.target.selectedOptions[0].id
                  )
                }
                style={{
                  color: formDataLocal.selectValue == 0 ? 'grey' : null,
                }}
              >
                <option selected disabled value={0}>
                  Selecciona un grupo
                </option>
                {groups?.length &&
                  groups?.map((item) => {
                    return (
                      <option id={item.id_uac} value={item.id_uac}>
                        {item.group_name ?? 'No name'}
                      </option>
                    );
                  })}
              </StyledSelect>
              {createClick && missingFields.selectGroup && (
                <span style={{ color: 'red' }}>Este campo es requerido</span>
              )}
            </InputContainer>

            <InputContainer
              column
              style={{ width: '100%', display: newGroup ? 'none' : 'flex' }}
            >
              <LabelGeneral>Selecciona tipo de usuario</LabelGeneral>
              <StyledSelect
                value={formDataLocal.selectedUserType}
                onChange={(e) =>
                  handleFieldChange('selectedUserType', e.target.value)
                }
                disabled={!groupDone || isEditing}
                style={{
                  backgroundColor: !groupDone ? '#DCDEDE' : '#FFF',
                  color: formDataLocal.selectedUserType == 0 ? 'grey' : null,
                }}
              >
                <option disabled selected value={0}>
                  Selecciona una opción
                </option>

                <option>D2</option>
                <option>D3</option>
                <option>D4</option>
              </StyledSelect>
              {createClick && missingFields.typeUser && (
                <span style={{ color: 'red' }}>Este campo es requerido</span>
              )}
              <div
                style={{
                  width: '100%',
                  display: newGroup ? 'flex' : 'none',
                  flexDirection: 'column',
                  gap: '8px',
                }}
              >
                <LabelGeneral>Ingresa nombre de usuario</LabelGeneral>
                <InputAlta
                  disabled={tempData?.isD1}
                  value={formDataLocal.userName}
                  onChange={(e) =>
                    handleFieldChange('userName', e.target.value)
                  }
                  placeholder='Ingresa nombre de usuario'
                  type='text'
                />
                {groupNameError && (
                  <span style={{ color: 'red' }}>{groupNameError}</span>
                )}
                {createClick && missingFields.nameUser && (
                  <span style={{ color: 'red' }}>Este campo es requerido</span>
                )}
              </div>
            </InputContainer>
            <InlineCont
              styled='
            display:flex;
            flex-direction: column;
          width: fit-content;
          min-width: 250px;
          gap: 8px;
          @media (max-width: 1439px){
            width:100%;
          }
          '
            >
              <LabelGeneral>Estatus</LabelGeneral>
              <ContainerButtons>
                <ButtonActive
                  className={activeButton === 'activo' ? 'active' : ''}
                  onClick={() => {
                    setActiveButton('activo');
                    handleFieldChange('userStatus', 'activo');
                  }}
                >
                  Activo
                </ButtonActive>
                <ButtonInactive
                  className={activeButton === 'noactivo' ? 'active' : ''}
                  onClick={() => {
                    setActiveButton('noactivo');
                    handleFieldChange('userStatus', 'noactivo');
                  }}
                >
                  No activo
                </ButtonInactive>
              </ContainerButtons>
            </InlineCont>

            <div
              style={{
                width: '100%',
                display: newGroup ? 'none' : 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
            >
              <LabelGeneral>Ingresa nombre de usuario</LabelGeneral>
              <InputAlta
                disabled={tempData?.isD1}
                value={formDataLocal.userName}
                onChange={(e) => handleFieldChange('userName', e.target.value)}
                placeholder='Nombre de usuario'
              />
              {createClick && missingFields.nameUser && (
                <span style={{ color: 'red' }}>Este campo es requerido</span>
              )}
            </div>

            <div
              style={{
                width: '100%',
                display:
                  groupDone && formDataLocal.selectedUserType === 'D4'
                    ? 'block'
                    : 'none',
              }}
            >
              <LabelGeneral>Ingresa ID distribuidor</LabelGeneral>
              <InputAlta
                disabled={isEditing}
                value={formDataLocal.idDistribuidor}
                onChange={(e) =>
                  handleFieldChange('idDistribuidor', e.target.value)
                }
                placeholder='ID distribuidor'
              />
              {createClick && missingFields.idDistribuidor && (
                <span style={{ color: 'red' }}>Este campo es requerido</span>
              )}
            </div>

            <InputContainer>
              <LabelGeneral>Marca(s)</LabelGeneral>
              <Flex width='100%' gap='8px' direction='row' align='center'>
                <StyledSelect
                  disabled={tempData?.isD1}
                  value={formDataLocal.selectedBrand}
                  onChange={(e) =>
                    handleFieldChange(
                      'selectedBrand',
                      e.target.value,
                      null,
                      e.target.selectedOptions[0].id
                    )
                  }
                  style={{
                    color: formDataLocal.selectedBrand == 0 ? 'grey' : null,
                  }}
                >
                  <option selected disabled value={0}>
                    Selecciona una opción
                  </option>
                  {brands?.length &&
                    brands?.map((item) => {
                      return (
                        <option id={item.name} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                </StyledSelect>
                <div style={{}}>
                  <ButtonAdd onClick={handleButtonClick}>+</ButtonAdd>
                </div>
              </Flex>
              {createClick && missingFields.brands && (
                <span style={{ color: 'red' }}>Este campo es requerido</span>
              )}
            </InputContainer>

            {/* <InputContainer style={{ width: '100%', display: formDataLocal.selectedUserType === "D3" ? "flex" : "none"}}>
          <div style={{width:"100%"}}>
            <LabelGeneral>Distribuidores</LabelGeneral>
            <StyledSelect
              value={selectedDistribuidor}
              onChange={(e) => setSelectedDistribuidor(e.target.value)}
            >
              <option disabled selected value={''}>
                Selecciona una opción
              </option>
              <option>D1</option>
              <option>D2</option>
              <option>D3</option>
              <option>D4</option>
            </StyledSelect>
          </div>
          <div style={{}}>
            <ButtonAdd onClick={handleButtonClickDistribuidor}>+</ButtonAdd>
          </div>
        </InputContainer> */}

            <div
              style={{
                width: '100%',
                display:
                  formDataLocal.selectedUserType === 'D3' ||
                  formDataLocal.selectedUserType === 'D4'
                    ? 'block'
                    : 'none',
              }}
            >
              <LabelGeneral>
                Selecciona tu usuario D2 de referencia{' '}
              </LabelGeneral>
              <StyledSelect
                value={formDataLocal.selectedD2}
                onChange={(e) =>
                  handleFieldChange('selectedD2', e.target.value)
                }
                style={{
                  color: formDataLocal.selectedD2 == 0 ? 'grey' : null,
                }}
              >
                <option disabled selected value={0}>
                  {' '}
                  Selecciona una opción
                </option>
                {usersd2?.length &&
                  usersd2?.map((item) => {
                    return (
                      <option value={item.id_uac}>
                        {item.name ?? 'Sin nombre'}
                      </option>
                    );
                  })}
              </StyledSelect>
              {createClick && missingFields.selectd2 && (
                <span style={{ color: 'red' }}>Este campo es requerido</span>
              )}
            </div>

            <div
              style={{
                width: '100%',
                display:
                  formDataLocal.selectedUserType === 'D4' ? 'block' : 'none',
              }}
            >
              <LabelGeneral>
                Selecciona tu usuario D3 de referencia{' '}
              </LabelGeneral>
              <StyledSelect
                value={formDataLocal.selectedD3}
                onChange={(e) =>
                  handleFieldChange('selectedD3', e.target.value)
                }
                style={{
                  color: formDataLocal.selectedD3 == 0 ? 'grey' : null,
                }}
              >
                <option disabled selected value={0}>
                  Selecciona una opción
                </option>
                {usersd3?.length &&
                  usersd3?.map((item) => {
                    return (
                      <option value={item.id_uac}>
                        {item.name ?? 'Sin nombre'}
                      </option>
                    );
                  })}
              </StyledSelect>
              {createClick && missingFields.selectd3 && (
                <span style={{ color: 'red' }}>Este campo es requerido</span>
              )}
            </div>
          </>
        )}
      </MainContainer>
      {showNewComponent && (
        <MarcasRegistradas
          names={arrNameBrand}
          opciones={selectedOptionsArray}
          eliminarOpcion={handleEliminarOpcion}
        />
      )}
      {showNewComponentDistribuidor && (
        <DistribuidoresAdd
          opciones={selectedOptionsArrayDistribuidores}
          eliminarOpcion={handleEliminarOpcionDistribuidor}
        />
      )}
    </>
  );
}
