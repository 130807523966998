import React, { useEffect, useState } from 'react';
import {
  Amount,
  ButtonContainer,
  ButtonEliminar,
  ContainerSubtotal,
  Desplegable,
  DesplegableTextBold,
  DesplegableTextNormal,
  EnvioContaier,
  EnvioText,
  HiddenmobileText,
  InnerContaier,
  Subtotal,
  SubtotalAmount,
  SubtotalAmountContainer,
  SubtotalText,
  SubtotalTextContainer,
  TextBoldText,
  TextNormalTitle,
  TitleContainer,
  Unselect,
} from './PaqueteriaElegir.styles';
// import { Caminon } from '../../../Carrito/ModalEscogerPaqueteria/ModalEscogerPaqueteria.style'
import { CardArticuloPaqueteria } from './CardArticuloPaqueteria';
import PaqueteriaDrop from './PaqueteriaDrop/PaqueteriaDrop';
import { url } from '../../../../api';
import ModalEscogerPaqueteria from '../../../Carrito/ModalEscogerPaqueteria/ModalEscogerPaqueteria';
import {
  formatNumberWithTwoDecimals,
  stringToNumber,
} from '../../../../../helpers/shopCart';
import ModalDelete from '../../ModalDelete';
import useShipmentCart from '../../../../../hooks/useShippment';
import {
  Flex,
  Text,
  Tooltip,
  TooltipExtra,
} from '../../../../styles/common.styles';
import info from '../../assets/info.svg';
import { Backdrop } from '@mui/material';
import desplegar from '../../../../assets/dropdownlightblue.svg';

const camion = `${url}whiteTruck.svg`;
const editar = `${url}Editar.svg`;
const CheckCircle = `${url}CheckCircle.svg`;
const flechaBlanca = `${url}flechaBlanca.svg`;

export default function ComponentePaquete({
  items,
  setStepSelected,
  paqueterias,
  setCostoEnvio,
  costoEnvio,
  setIdEnvio,
  idEnvio,
  index,
  handleAcceptTerms,
}) {
  const [hide, setHide] = useState(false);
  const [show, setShow] = useState(false);
  const [currentCostoEnvio, setCurrentCostoEnvio] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const { shippmentsAvailables } = useShipmentCart();
  // const [mostarEnvios, setMostrarEnvios] = useState(false);
  const [valueShipping, setValueShipping] = useState({});
  const [background, setBackground] = useState('#41b7701a');
  const [selectedByModal, setSelectedByModal] = useState(false);

  const filterItems = items.filter(
    (el) => el.id_uac === Number(paqueterias[0].id_distribuidor)
  );
  const subTotal = filterItems.reduce(
    (accumulator, currentValue) =>
      accumulator + stringToNumber(currentValue.price) * currentValue.qty,
    0
  );
  const countItems = filterItems.reduce(
    (accumulator, currentValue) => accumulator + currentValue.qty,
    0
  );
  const statusShipment = shippmentsAvailables[paqueterias[0].id_distribuidor];
  const updateStatusValidation = async (value) => {
    await handleAcceptTerms(index, value);
  };
  useEffect(() => {
    if (statusShipment?.isAvailable) {
      updateStatusValidation(true);
      setValueShipping({
        nombre: statusShipment.selectedShipment.nombre,
        servicio: statusShipment.selectedShipment.servicio,
        costo: statusShipment.selectedShipment.costo,
        dias_de_entrega: statusShipment.selectedShipment.dias_de_entrega,
      });
      setBackground(statusShipment.isAvailable ? '#41b7701a' : '#0E79B4');
    } else {
      updateStatusValidation(false);
    }
  }, [statusShipment]);

  useEffect(() => {
    if (selectedByModal) {
      setSelectedByModal(false);
      setBackground('#41b7701a');
      updateStatusValidation(true);
    } else {
      if (!statusShipment) {
        setBackground(valueShipping.nombre ? '#41b7701a' : '#0E79B4');
      }
    }
  }, [valueShipping, statusShipment]);

  const handleBuySingle = () => {
    setStepSelected(1);
  };

  const updateShipmentPrice = (price) => {
    setCurrentCostoEnvio(price);
    const temp =
      stringToNumber(costoEnvio) -
      stringToNumber(currentCostoEnvio) +
      stringToNumber(price);
    setCostoEnvio(`$${temp} MXN`);
  };

  const updatePrice = () => {
    const temp = stringToNumber(costoEnvio) - stringToNumber(currentCostoEnvio);
    setCostoEnvio(`$${temp} MXN`);
  };

  const [infoDisplay, setInfoDisplay] = useState(false);

  const manejarClicFuera = (e) => {
    const divChild = document.getElementById('tooltip');

    if (divChild && !divChild.contains(e.target)) {
      // Clic fuera del div child, ocultar el div
      setInfoDisplay(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', manejarClicFuera);

    // Limpiar el event listener al desmontar el componente
    return () => {
      document.removeEventListener('click', manejarClicFuera);
    };
  }, []);

  return (
    filterItems?.length > 0 && (
      <div>
        {show ? (
          <ModalEscogerPaqueteria
            setCostoEnvio={updateShipmentPrice}
            show={show}
            setShow={setShow}
            setValueShipping={setValueShipping}
            paqueterias={paqueterias}
            setIdEnvio={setIdEnvio}
            idEnvio={idEnvio}
            filterItems={filterItems}
            setSelectedByModal={setSelectedByModal}
          />
        ) : null}
        <ModalDelete
          id={filterItems[0]?.id_uac}
          open={openModal}
          setOpen={setOpenModal}
          box
          updatePrice={updatePrice}
        />

        {index < 1 && (
          <Text
            size='24px'
            weight='700'
            color='#004C97'
            style={{ margin: '0 0 16px', gap: '40px' }}
          >
            Paquetes de envío
            <img
              alt=''
              src={info}
              style={{
                marginLeft: '40px',
              }}
              onMouseEnter={() => setInfoDisplay(true)}
              onTouchStart={() => setInfoDisplay(true)}
            />
            {infoDisplay ? (
              <>
                <Backdrop open sx={{ zIndex: 2000 }} />
                <Tooltip
                  style={{
                    zIndex: '2001',
                    width: '100%',
                    maxWidth: '400px',
                    gap: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    left: '0px',
                  }}
                  id='tooltip'
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: '8px',
                      right: '8px',
                      fontSize: '16px',
                      color: '#32B7D7',
                      cursor: 'pointer',
                    }}
                    onClick={() => setInfoDisplay(false)}
                  >
                    ✕
                  </div>
                  <Flex width='100%' direction='row' gap='8px'>
                    <img alt='' src={info} />
                    <Text size='18px' weight='600' color='#32B7D7'>
                      Envíos por paquetes
                    </Text>
                  </Flex>
                  <Text size='12px' color='#0D3564' family='Inter'>
                    Los productos que has seleccionado se organizan en distintos
                    paquetes, basados en la ubicación de cada proveedor.
                    <br />
                    <br />A continuación, tendrás la opción de elegir el
                    servicio de paquetería que más te convenga, tomando en
                    cuenta tus preferencias y tiempos de entrega.
                  </Text>
                  <TooltipExtra style={{ left: '236px' }} />
                </Tooltip>
              </>
            ) : null}
          </Text>
        )}
        <TitleContainer
          style={{
            backgroundColor: background,
          }}
        >
          <Flex gap='16px'>
            <img src={valueShipping.nombre ? CheckCircle : camion} alt='' />
            <InnerContaier>
              {/* <img src={camion} alt='' /> */}
              {!!valueShipping.nombre ? (
                <>
                  <TextBoldText style={{ color: '#0D3564' }}>
                    {valueShipping.nombre}
                  </TextBoldText>
                  <TextBoldText style={{ color: '#0D3564', fontWeight: '400' }}>
                    {valueShipping.servicio}
                  </TextBoldText>
                  <TextBoldText style={{ color: '#55646A' }}>
                    {valueShipping.costo}
                  </TextBoldText>
                  <TextBoldText style={{ color: '#0D3564', fontWeight: '400' }}>
                    ({valueShipping.dias_de_entrega} días hábiles)
                  </TextBoldText>
                </>
              ) : (
                <TextBoldText style={{ fontWeight: '400' }}>
                  Escoge el servicio de envío para tu producto
                </TextBoldText>
              )}
            </InnerContaier>
          </Flex>
          <InnerContaier>
            {valueShipping.nombre ? (
              <img src={editar} onClick={() => setShow(!show)} alt='' />
            ) : (
              <>
                <TextNormalTitle onClick={() => setShow(!show)}>
                  <HiddenmobileText>SELECCIONAR &nbsp;</HiddenmobileText>

                  <img
                    style={{ cursor: 'pointer' }}
                    src={flechaBlanca}
                    onClick={() => setShow(!show)}
                    alt=''
                  />
                </TextNormalTitle>
              </>
            )}
          </InnerContaier>
        </TitleContainer>
        <Flex
          width='100%'
          direction='column'
          padding='12px 32px'
          bg='#EFF3F8'
          gap='16px'
        >
          <Flex
            width='100%'
            direction='row'
            justify='space-between'
            align='center'
            onClick={() => setHide(!hide)}
          >
            <InnerContaier>
              <DesplegableTextBold>Paquete {index + 1}</DesplegableTextBold>
            </InnerContaier>
            <InnerContaier>
              {hide ? (
                <>
                  <DesplegableTextNormal>Ocultar detalle</DesplegableTextNormal>
                  <img
                    src={desplegar}
                    onClick={() => setHide(!hide)}
                    style={{
                      cursor: 'pointer',
                      transform: !hide ? 'rotate(0deg)' : 'rotate(180deg)',
                    }}
                    alt=''
                  />
                </>
              ) : (
                <>
                  <DesplegableTextNormal>Ver detalle</DesplegableTextNormal>
                  <img
                    src={desplegar}
                    alt=''
                    style={{
                      cursor: 'pointer',
                      transform: hide ? 'rotate(180deg)' : 'rotate(0deg) ',
                    }}
                  />
                </>
              )}
            </InnerContaier>
          </Flex>
          {!hide && (
            <div
              style={{
                width: '100%',
                height: '1px',
                background: '#C7CACE',
                margin: '0 auto',
              }}
            ></div>
          )}
        </Flex>

        {hide && (
          <div>
            <Flex bg='#EFF3F8' padding='0 16px' width='100%' direction='column'>
              {filterItems.map((item) => (
                <CardArticuloPaqueteria
                  item={item}
                  handleBuy={handleBuySingle}
                  costoEnvio={currentCostoEnvio}
                />
              ))}
            </Flex>
            <Subtotal>
              <ContainerSubtotal>
                <SubtotalTextContainer>
                  <SubtotalText>
                    Subtotal ({countItems} productos):{' '}
                  </SubtotalText>
                  <Amount>${formatNumberWithTwoDecimals(subTotal)} MXN </Amount>
                </SubtotalTextContainer>
                <EnvioContaier>
                  <EnvioText>Envío:</EnvioText>
                  <Unselect>
                    {currentCostoEnvio !== 0
                      ? currentCostoEnvio
                      : 'No has seleccionado'}
                  </Unselect>
                </EnvioContaier>
              </ContainerSubtotal>
              <ButtonContainer>
                {/* <ButtonEliminar onClick={() => setOpenModal(true)}>
                  Eliminar todos
                </ButtonEliminar> */}
                <SubtotalAmountContainer>
                  <SubtotalText>Subtotal:</SubtotalText>
                  <SubtotalAmount>
                    ${formatNumberWithTwoDecimals(subTotal)} MXN
                  </SubtotalAmount>
                </SubtotalAmountContainer>
              </ButtonContainer>
            </Subtotal>
          </div>
        )}
        {!hide && (
          <div style={{ width: '100%' }}>
            <PaqueteriaDrop
              costoEnvio={currentCostoEnvio}
              item={filterItems}
              handleBuy={handleBuySingle}
              countItems={countItems}
              subTotal={subTotal}
              ç
              nopad2
            />
          </div>
        )}
      </div>
    )
  );
}
