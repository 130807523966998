import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import {
  MainContainer,
  Title,
  Description,
  Enlace,
  TextContainer,
  Imagen,
  ImgContainer,
  VerMasContainer,
  RightArrow,
} from './Card.style';
import { url } from '../../api';
//assets
const right_arrow = `${url}right_arrow.svg`;
export default function Card({
  title,
  description,
  enlace,
  image,
  altimg,
  backgroundColor,
  tamano,
  objectFit,
}) {
  return (
    <MainContainer backgroundColor={backgroundColor} as={Link} to={enlace}>
      <TextContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <VerMasContainer>
          <Enlace>Ver más</Enlace>
          {/* Assuming 'enlace' is the URL you want to link to */}
          <RightArrow src={right_arrow} alt='' />
        </VerMasContainer>
      </TextContainer>
      <ImgContainer>
        <Imagen
          src={image}
          alt={altimg}
          tamano={tamano}
          objectFit={objectFit}
        />
      </ImgContainer>
    </MainContainer>
  );
}
