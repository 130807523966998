import React, { useEffect, useState } from 'react';
import { Button, Flex, Text } from '../../../styles/common.styles';
import Correct from '../assets/Correct.svg';
import Incorrect from '../assets/Incorrect.svg';
import Pending from '../assets/Pendiente.svg';
import ExpandableCardSuccess from '../Atoms/ExpandableCardSuccess';
import usePaymentStatus from '../../../../hooks/usePaymentStatus';
import ScrollToTop from '../../../../hooks/topHook';
import {
  Box,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  stepConnectorClasses,
  styled,
} from '@mui/material';
import useShoppingCart from '../../../../hooks/useShoppingCart';
import { useHistory } from 'react-router-dom';

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 12px)',
    right: 'calc(50% + 12px)',
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 5,
    borderRadius: 1,
  },
}));

const Success = ({ items }) => {
  //!
  // const succeeded = true;
  //!
  const { deleteAll } = useShoppingCart();
  const { statusPayment } = usePaymentStatus();
  const [paymentInfo, setPaymentInfo] = useState({});
  const [succeeded, setSucceeded] = useState(false);
  const history = useHistory();

  console.log('statusPayment', statusPayment);
  useEffect(() => {
    if (statusPayment?.length > 0 || statusPayment?.info) {
      setPaymentInfo(statusPayment.info);
      if (statusPayment.statusPayment.includes('Succes confirm shipment')) {
        setSucceeded('success');
        deleteAll();
      } else if (statusPayment.statusPayment.includes('fallo algo, el pag')) {
        setSucceeded('failed');
      } else if (statusPayment.statusPayment.includes('pago esta pendiente')) {
        setSucceeded('pending');
      }
    }
  }, [statusPayment]);
  return (
    <>
      <ScrollToTop />
      <Flex
        direction='column'
        width='100%'
        align='center'
        maxWidth='1440px'
        alignSelf='center'
        padding='80px'
        gap='40px'
        mobile='padding: 40px 16px;'
      >
        {/* <Flex width='100%' direction='row'>
          <Box sx={{ width: '100%' }}>
            <Stepper
              // activeStep:
              //   <0 = none
              //   0 = tu carrito
              //   1 = datos de envio
              //   2 = paquetería
              //   3 = pago
              //   >=4 = done
              activeStep={4}
              alternativeLabel
              connector={<Connector />}
            >
              <Step>
                <StepLabel>
                  <Flex
                    direction='column'
                    align='center'
                    gap='8px'
                    width='100%'
                  >
                    <img
                      src=''
                      alt=''
                      style={{ width: '32px', maxWidth: '32px' }}
                    />
                    Tu carrito
                  </Flex>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  <Flex
                    direction='column'
                    align='center'
                    gap='8px'
                    width='100%'
                  >
                    <img
                      src=''
                      alt=''
                      style={{ width: '32px', maxWidth: '32px' }}
                    />
                    Datos de envió
                  </Flex>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  <Flex
                    direction='column'
                    align='center'
                    gap='8px'
                    width='100%'
                  >
                    <img
                      src=''
                      alt=''
                      style={{ width: '32px', maxWidth: '32px' }}
                    />
                    Paquetería
                  </Flex>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  <Flex
                    direction='column'
                    align='center'
                    gap='8px'
                    width='100%'
                  >
                    <img
                      src=''
                      alt=''
                      style={{ width: '32px', maxWidth: '32px' }}
                    />
                    Pago
                  </Flex>
                </StepLabel>
              </Step>
            </Stepper>
          </Box>
        </Flex> */}
        <>
          <Text
            color='#004C97'
            width='100%'
            textAlign='center'
            size='40px'
            weight='800'
            mobile='font-size: 28px;'
          >
            {succeeded === 'success' && 'Confirmación de compra'}
            {succeeded === 'failed' && 'Error al realizar el pago'}
            {succeeded === 'pending' && 'Pago pendiente'}
          </Text>

          <Flex
            width='100%'
            direction='column'
            gap='40px'
            mobile='flex-direction: column; border-top: none;'
            extras='border-top: 0.74px solid rgba(0, 0, 0, 0.35);'
            padding='16px 0 0'
          >
            <Flex
              direction='row'
              gap='40px'
              width='100%'
              align='center'
              justify='flex-start'
              mobile='flex-direction: column;'
            >
              {succeeded === 'success' && (
                <img alt='' src={Correct} style={{ maxWidth: '64px' }} />
              )}
              {succeeded === 'failed' && <img alt='' src={Incorrect} />}
              {succeeded === 'pending' && <img alt='' src={Pending} />}
              <Flex
                width='100%'
                direction='column'
                gap='40px'
                height='fit-content'
                mobile='width: 100%; align-items: center; '
              >
                <Text
                  family='Mulish'
                  size='40px'
                  weight='800'
                  color='#0D3564'
                  mobile='font-size: 24px;'
                >
                  {succeeded === 'success' && 'Gracias por tu compra'}
                  {succeeded === 'failed' &&
                    'Lo sentimos, no se ha podido completar la compra'}
                  {succeeded === 'pending' && 'Estamos procesando tu pago'}
                </Text>
                {succeeded === 'success' && (
                  <Text
                    family='Mulish'
                    size='24px'
                    weight='400'
                    color='#14181A'
                    mobile='font-size: 16px;'
                  >
                    Su pedido{' '}
                    <span style={{ color: '#32B7D7', fontWeight: '700' }}>
                      #{paymentInfo?.id}
                    </span>{' '}
                    ha sido realizados con la tarjeta{' '}
                    <span style={{ fontWeight: '700' }}>
                      *{paymentInfo?.fourFinalCardNumbers}
                    </span>
                  </Text>
                )}
                {succeeded === 'failed' && (
                  <Text
                    family='Mulish'
                    size='24px'
                    weight='400'
                    color='#14181A'
                    mobile='font-size: 16px;'
                  >
                    Su pedido{' '}
                    <span style={{ color: '#32B7D7', fontWeight: '700' }}>
                      #{paymentInfo?.id}
                    </span>{' '}
                    no se ha podido realizar.
                    <br />
                    <br />
                    Por favor, verifique los detalles de su tarjeta y{' '}
                    <span style={{ fontWeight: '700' }}>
                      vuelva a intentarlo.
                    </span>
                  </Text>
                )}
                {succeeded === 'pending' && (
                  <Text
                    family='Mulish'
                    size='24px'
                    weight='400'
                    color='#14181A'
                    mobile='font-size: 16px;'
                  >
                    Su pedido{' '}
                    <span style={{ color: '#32B7D7', fontWeight: '700' }}>
                      #{paymentInfo?.id}
                    </span>{' '}
                    esta pendiente.
                    <br />
                    <br />
                    Mercadopago está revisando su pago. Será notificado por
                    correo electrónico electrónico una vez que haya sido
                    aprobado.
                  </Text>
                )}
              </Flex>
            </Flex>
            {succeeded === 'success' ? (
              <Text
                family='Mulish'
                size='24px'
                weight='400'
                color='#14181A'
                mobile='font-size: 16px;'
              >
                Hemos enviado un correo electrónico a{' '}
                <span style={{ fontWeight: '700' }}>{paymentInfo?.email}</span>{' '}
                con la confirmación de su envio y la factura.
                <br />
                <br />
                Fecha y hora de compra :{' '}
                <span style={{ fontWeight: '700' }}>{paymentInfo?.fecha}</span>
              </Text>
            ) : (
              <Text family='Mulish' size='24px' weight='400' color='#14181A'>
                Fecha y hora de compra :{' '}
                <span style={{ fontWeight: '700' }}>{paymentInfo?.fecha}</span>
              </Text>
            )}
            <Flex
              width='100%'
              padding='32px'
              gap='32px'
              bg='#EFF3F8'
              direction='column'
              align='center'
              mobile='padding: 32px 16px;'
            >
              <Text family='Mulish' color='#004C97' size='24px' weight='700'>
                Resumen del pedido
              </Text>
              {paymentInfo?.trackingNumberByDistribuidor?.map(
                (currentItems, index) => (
                  <ExpandableCardSuccess
                    items={currentItems.arrItems}
                    selectedShipment={currentItems.selectedShipment}
                    index={index}
                  />
                )
              )}
            </Flex>
            <Flex
              direction='row'
              gap='16px'
              width='100%'
              mobile='flex-direction: column;'
              align='center'
              justify='center'
            >
              <Button width='190px' onClick={() => history.push('/store')}>
                Seguir comprando
              </Button>
              {!succeeded && (
                <Button secondary width='190px'>
                  Reintentar Pago
                </Button>
              )}
            </Flex>
          </Flex>
        </>
      </Flex>
    </>
  );
};

export default Success;
