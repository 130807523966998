/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import {
  StyledContainer,
  StyledHead,
  StyledCont,
  BottomStyled,
  StyledInput,
  UserResponse,
  BotResponse,
  Styledicon,
} from './Chatbot.styles';
import Sally from './Assets/AVATAR.svg';
import Icon from './Assets/Icon.svg';

const Chatbot = () => {
  const scrollableDivRef = useRef(null);

  const [open, setOpen] = useState(false);

  //   useEffect para desplazar a nuevo mensaje en cuanto se actualice el mensaje
  useEffect(() => {
    const scrollToBottom = () => {
      if (scrollableDivRef.current) {
        scrollableDivRef.current.scrollTop =
          scrollableDivRef.current.scrollHeight;
      }
    };

    // Espera un pequeño retraso antes de desplazar al final
    const timeoutId = setTimeout(scrollToBottom, 50);

    // Limpia el timeout al desmontar el componente
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      <StyledContainer style={{ display: open ? 'flex' : 'none' }}>
        <StyledHead>
          <img alt='' src={Sally} />
          Sally
          <div
            style={{
              position: 'absolute',
              top: '24px',
              right: '24px',
              gap: '12px',
              fontSize: '16px',
              display: 'flex',
            }}
          >
            {/* <div
              style={{
                cursor: 'pointer',
              }}
              onClick={() => setOpen(false)}
            >
              —
            </div> */}
            <div
              style={{
                cursor: 'pointer',
              }}
              onClick={() => setOpen(false)}
            >
              ✕
            </div>
          </div>
        </StyledHead>
        <StyledCont ref={scrollableDivRef}>
          {/* mock */}
          <UserResponse response={'prueba'} />
          <BotResponse response={'prueba'} />
          <UserResponse response={'prueba'} />
          <BotResponse response={'prueba'} />
          <UserResponse response={'prueba'} />
          <BotResponse response={'prueba'} />
          <UserResponse response={'prueba'} />
          <BotResponse response={'prueba'} />
        </StyledCont>
        <BottomStyled>
          <StyledInput />
        </BottomStyled>
      </StyledContainer>
      <Styledicon onClick={() => setOpen(true)}>
        <img alt='' src={Icon} />
      </Styledicon>
    </>
  );
};

export default Chatbot;
