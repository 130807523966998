/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Flex, Text, Button } from '../../styles/common.styles';
import styled from 'styled-components';
import { Pagination, Snackbar } from '@mui/material';
import { url } from '../../api';
import { useLazyQuery } from '@apollo/client';
import { GET_DETAIL } from '../../../graphql/Query';
import ModalComprarMayoreo from '../QuieresComprar/ModalComprarMayoreo';
import ModalGracias from '../QuieresComprar/ModalGracias';
import { makeStyles } from '@material-ui/core';
import useShoppingCart from '../../../hooks/useShoppingCart';
const noAvailable = `${url}noAvailable.svg`;
const blueHeart = `${url}blueheart.svg`;
const cart = `${url}cart.svg`;
const unratedStar = `${url}UnratedStar.svg`;
const ratedStar = `${url}RatedStar.svg`;

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaginationItem-page': {
      color: '#0E79B4', // Cambia el color de la fuente para la página seleccionada
    },
    '& .MuiPaginationItem-root.Mui-selected': {
      background: 'rgba(14, 121, 180, 0.2)', // Cambia el color de las flechas de elipsis
    },
    '& .MuiPaginationItem-root': {
      color: '#0E79B4',
    },
  },
}));

const FinderResults = ({ resultData, pages, handlePage }) => {
  const classes = useStyles();

  const data = sessionStorage.getItem('Brand');
  const idpart = sessionStorage.getItem('idPart');
  const category = sessionStorage.getItem('Ctry');
  const [cards, setCards] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const handleReload = () => {
    window.location.reload();
  };

  useEffect(() => {
    sessionStorage.removeItem('Result');
    if (resultData?.length > 0) {
      setCards(resultData);
    } else {
      setCards(resultData);
    }
  }, [resultData]);

  const handleChange = (event, page) => {
    setCurrentPage(page);
    handlePage(page);
  };

  const [openModalMayoreo, setOpenModalMayoreo] = useState(false);
  const [openModalGracias, setOpenModalGracias] = useState(false);

  return (
    <>
      {openModalMayoreo && (
        <ModalComprarMayoreo
          setOpenMayoreo={setOpenModalMayoreo}
          setOpenGracias={setOpenModalGracias}
          open={openModalMayoreo}
        />
      )}
      {openModalGracias && <ModalGracias setShow={setOpenModalGracias} />}

      <Flex
        direction='column'
        width='100%'
        align='center'
        maxWidth='1440px'
        alignSelf='center'
        gap='40px'
        padding='16px 16px 32px'
      >
        {category || data || idpart ? (
          <Text
            textAlign='center'
            size='40px'
            weight='800'
            color='#004C97'
            mobile='font-size: 32px;'
          >
            Todos nuestros resultados para
            {data && data !== undefined ? ` ${data}` : ''}
            {category && category !== undefined ? ` ${category}` : ''}
            {idpart && idpart !== undefined ? ` ${idpart}` : ''}
          </Text>
        ) : (
          <Text
            textAlign='center'
            size='40px'
            weight='800'
            color='#004C97'
            mobile='font-size: 32px;'
          >
            Todos nuestros resultados:
          </Text>
        )}
        <Text
          onClick={handleReload}
          style={{
            cursor: 'pointer',
          }}
          family='Roboto'
          size='24px'
          weight='600'
          color='#022741'
          decoration='underline'
          width='100%'
          mobile='font-size: 20px;'
        >
          Regresar a "Todas nuestras categorías"
        </Text>
        <Divider />
        <GridContainer>
          {cards.map((info) => (
            <GridMapeableItem
              info={info}
              unavailable={false}
              openModalMayoreo={openModalMayoreo}
              setOpenModalMayoreo={setOpenModalMayoreo}
            />
          ))}
        </GridContainer>
        <Pagination
          count={pages}
          shape='rounded'
          color='primary'
          page={currentPage}
          onChange={handleChange}
          classes={{ root: classes.root }}
        />
      </Flex>
    </>
  );
};

export default FinderResults;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: rgb(166, 166, 166);
`;

const GridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(302px, 302px));
  gap: 20px;
  justify-content: center;
  padding: 16px 0;
  @media (min-width: 1024px) and (max-width: 1440px) {
    grid-template-columns: repeat(3, minmax(0, 100%));
  }

  @media (max-width: 1023px) {
    grid-template-columns: repeat(1, minmax(0, 100%));
  }
`;

const GridItem = styled.div`
  background: #fff;
  box-shadow: 0px 20px 54px 0px rgba(13, 53, 100, 0.15);
  max-width: 302px;
  width: 100%;
  height: 464px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 16px;
  position: relative;
  @media (max-width: 1023px) {
    height: 235px;
    max-width: 100%;
    flex-direction: row;
  }
  @media (min-width: 1024px) {
    min-width: 302px;
  }
`;

const ItemImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 200px;
  max-width: 200px;
  cursor: pointer;
  @media (max-width: 1023px) {
    height: 163px;
    max-width: 120px;
    object-fit: contain;
  }
`;

const LikeButtonContainer = styled.button`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 24px;
  right: 24px;
  border-radius: 6px;
  border: 1px solid #dcdede;
  background: #ffffff;
  cursor: pointer;
  box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.08);
  z-index: 2;
  @media (max-width: 1023px) {
    display: none;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  height: 100%;
  gap: 8px;
  width: 100%;
`;

const GridMapeableItem = ({ info, openModalMayoreo, setOpenModalMayoreo }) => {
  const history = useHistory();
  const { updateItemsCart, itemsShoppingCart, updateItemsQuantity } =
    useShoppingCart();

  const [getDetails] = useLazyQuery(GET_DETAIL, {
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      const data = result.getAutopartDetail.data;
      history.push(`/store/detail/${info.id_part}`, { data });
    },
  });

  function handleOpenDetail() {
    getDetails({
      variables: {
        id: info.id_part,
      },
    });
  }

  function formatMoney(input) {
    let amount = typeof input === 'string' ? parseFloat(input) : input;
    if (isNaN(amount)) {
      return 'No es un número válido';
    }
    amount = Math.round(amount * 100) / 100;
    const parts = amount.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (parts.length === 1) {
      parts.push('00');
    } else if (parts[1].length === 1) {
      parts[1] += '0';
    }
    return '$' + parts.join('.');
  }

  const addItem = (item, redirect) => {
    const isInCart = itemsShoppingCart?.filter(
      (cart) => cart.id === item.id_autopart
    );
    const infoPart = [...itemsShoppingCart];

    if (isInCart && isInCart.length > 0) {
      if (redirect) {
        history.push(`/store/cart`);
      } else {
        updateItemsQuantity(
          isInCart[0],
          isInCart[0].qty + 1 <= isInCart[0].existence
            ? isInCart[0].qty + 1
            : isInCart[0].existence
        );
        // alert('Se agrego al carritoOO');
        handleClick();
        return;
      }
    } else {
      // alert('Se agrego al carrito');
      handleClick();
      infoPart.push({
        id: item.id_autopart,
        name: item.repair,
        img: item?.image || `${url}noAvailable.svg`,
        existence: item?.stock,
        partNumber: item?.id_part,
        price: `$${item?.final_price}`,
        id_uac: item?.user_access_controls[0]?.id_uac,
        qty: 1,
        description: item.repair,
      });
    }
    if (redirect) {
      history.push(`/store/cart`);
    }
    updateItemsCart(infoPart);
  };

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message='Se agregó producto al carrito'
      />
      <GridItem key={info.id_autopart}>
        {info.stock === 0 ? null : (
          <LikeButtonContainer onClick={() => addItem(info)}>
            <img src={cart} />
          </LikeButtonContainer>
        )}
        <div
          style={{
            width: '200px',
            height: '200px',
            minHeight: '200px',
            maxHeight: '200px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ItemImage
            src={info.image || noAvailable}
            onClick={handleOpenDetail}
          />
        </div>
        <InfoContainer>
          {info.stock === 0 ? null : (
            <Text size='20px' color='#0E79B4' weight='800'>
              {formatMoney(info.final_price)} MXN
            </Text>
          )}
          {info.stock === 0 && (
            <Text size='24px' color='#55646A' weight='800'>
              No disponible
            </Text>
          )}
          <Text
            style={{
              maxHeight: '68px',
              overflow: 'hidden',
            }}
            color='#55646A'
            size='14px'
            family='Inter'
            textAlign='left'
          >
            {info.repair}
          </Text>
          {info.stock === 0 ? null : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: '8px',
                position: 'absolute',
                bottom: '0',
              }}
            >
              <Button
                width='100%'
                mobile='width: 168px;font-size: 12px;width:100%;'
                maxwidth='100%'
                onClick={() => {
                  addItem(info, true);
                }}
              >
                Agregar al carrito
              </Button>
              <Button
                width='100%'
                mobile='font-size: 12px; width:100%;'
                maxwidth='100%'
                secondary
                onClick={() => setOpenModalMayoreo(!openModalMayoreo)}
              >
                Comprar al mayoreo
              </Button>
            </div>
          )}
        </InfoContainer>
      </GridItem>
    </>
  );
};

const RatedStars = ({ stars, reviews }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {stars >= 1 ? <img src={ratedStar} /> : <img src={unratedStar} />}
        {stars >= 2 ? <img src={ratedStar} /> : <img src={unratedStar} />}
        {stars >= 3 ? <img src={ratedStar} /> : <img src={unratedStar} />}
        {stars >= 4 ? <img src={ratedStar} /> : <img src={unratedStar} />}
        {stars >= 5 ? <img src={ratedStar} /> : <img src={unratedStar} />}
      </div>
      <Text family='Inter' size='12px' color='#B9CAD0'>
        ({reviews || '50'})
      </Text>
    </div>
  );
};
