import styled from 'styled-components';

// Importa styled-components u otra biblioteca de estilos que estés utilizando

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.5); /* Fondo negro difuminado con opacidad */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4; /* Asegura que el fondo esté por encima del contenido */
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 24px;
  top: 24px;
`;

export const MainContainer = styled.div`
  display: flex;
  width: 684px;
  padding: 56px;
  flex-direction: column;
  gap: 32px;
  border-radius: 16px;
  background: var(--neutral-white, #fff);
  box-shadow: 0px 8px 24px 0px rgba(0, 32, 74, 0.4);
  overflow-y: auto;
  max-height: 90vh;
  position: relative;
  ::-webkit-scrollbar {
    width: 8px; /* Anchura de la barra de desplazamiento en navegadores Webkit */
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(136, 136, 136, 0.5); /* Barra translúcida */
    border-radius: 4px; /* Bordes redondeados */
    height: 10%; /* Altura del 90% */
  }

  ::-webkit-scrollbar-track {
    background-color: transparent; /* Fondo transparente */
  }
`;

export const UploadImgeHeader = styled.div`
  width: 64px;
`;

export const MainTitle = styled.h2`
  color: var(--primarydeep, #0d3564);
  text-align: center;

  /* DESKTOP/H3 */
  font-family: Mulish;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 48px */
  margin-bottom: 0;
`;

export const DescriptionText = styled.p`
  color: var(--neutral-abism, #14181a);
  text-align: center;

  /* COMMON/Body */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  margin: 0;
`;

export const Subtitle = styled.p`
  color: var(--primarydeep, #0d3564);

  /* DESKTOP/H5 */
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
  margin: 0;
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const DimensionsContainer = styled.div`
  display: flex;
  align-items: last baseline;
  justify-content: space-between;
  gap: 8px;
`;

export const LabelSelect = styled.p`
  color: var(--primary-darkblue, #061222);

  /* COMMON/Caption */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
`;

export const Xtext = styled.p`
  margin: 0;
`;

export const InputModal = styled.input`
  display: flex;
  width: 100%;
  height: 48px;
  padding: 16px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: ${({ validIn }) => (validIn ? '1px solid red' : '1px solid #b9cad0')};
  background: var(--neutral-white, #fff);
  color: var(--neutral-storm, #8299a3);

  /* COMMON/Body */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
`;

export const CargaArchivosContainer = styled.div`
  color: var(--neutral-storm, #8299a3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 24px;
  /* COMMON/Body */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  border-radius: 8px;
  background: var(--background-light-gray, #f8f8f8);
  @media (max-width: 1039px) {
    flex-direction: column;
    gap: 16px;
    width: fit-content;
    margin: 0 auto;
  }
`;

export const UploadImg = styled.img`
  width: 32px;
`;

export const CargarArchviosContaier = styled.div`
  display: flex;
`;

export const TextCargar = styled.p`
  color: var(--primarydeep, #0d3564);

  /* COMMON/Body_Bold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
`;

export const TextCargarWarning = styled.p`
  color: var(--neutrals-taupe-gray, #939396);

  /* COMMON/Body */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
`;

export const ButtonSelect = styled.button`
  display: flex;
  width: 212px;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--primarydeep, #0d3564);
  background: var(--neutral-white, #fff);
  color: var(--primarydeep, #0d3564);
  cursor: pointer;
  /* COMMON/Button */
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const ButtonCargar = styled.button`
  display: flex;
  width: 212px;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--primarydeep, #0d3564);
  color: var(--neutral-white, #fff);
  border-color: #0d3564;
  border-style: solid;
  border-width: 1px;
  /* COMMON/Button */
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;
