/* eslint-disable */
import React, { useState } from 'react';
import { Flex, Text } from '../../styles/common.styles';
import {
  Box,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  stepConnectorClasses,
  styled,
  stepIconClasses,
  StepIcon,
} from '@mui/material';
import TuCarrito from './Subsections/TuCarrito';
import DatosEnvio from './Subsections/DatosEnvio';
import Payment from './Subsections/Payment';
import PaqueteriaElegir from './Subsections/PaqueteriaElegir/PaqueteriaElegir';
import Success from './Subsections/Success';
import { SAVE_SHOPING_CART_PROGRESS } from '../../../graphql/Mutation';
import { useMutation } from '@apollo/client';
import Loader from '../common/Loader';
import useUser from '../../../hooks/useUser';
import useShoppingCart from '../../../hooks/useShoppingCart';
import { useEffect } from 'react';
import useShipmentCart from '../../../hooks/useShippment';
import ModalShipmentError from '../Carrito/Modals/ModalErrorShipment/ModalErrorShipment';
import myCartIcon from './assets/mycart.svg';
import datosEnvioIcon from './assets/datosEnvio.svg';
import myCartDoneIcon from './assets/myCartDone.svg';
import datosEnvioDoneIcon from './assets/datosEnvioDone.svg';
import paqueteriaIcon from './assets/paqueteria.svg';
import paqueteríaDoneIcon from './assets/paqueteríaDone.svg';
import paymentIcon from './assets/payment.svg';
import paymentDoneIcon from './assets/paymentDone.svg';
import { useHistory } from 'react-router-dom';
import useDirectinCart from '../../../hooks/useDirectionCart';
import { stringToNumber } from '../../../helpers/shopCart';

//! TODO: agregar imagenes correspondientes. Las imagenes tienen que ser .svg 32px x 32px como en figma.

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 12px)',
    right: 'calc(50% + 12px)',
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 5,
    borderRadius: 1,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#32B7D7',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#32B7D7',
    },
  },
}));

const CustomStepIcon = styled(StepIcon)(({ theme }) => ({
  '&.MuiStepIcon-root': {
    color: '#ffffff',
    border: '2px solid #32B7D7',
    borderRadius: '50%',
  },
  '&.Mui-active': {
    color: '#32B7D7',
    border: 'none',
  },
  '& .MuiStepIcon-text': {
    display: 'none',
  },
  '&.Mui-completed': {
    color: '#32B7D7',
    border: 'none',
  },
}));

const CartSteps = () => {
  const { user } = useUser();
  const { updatedirectionCart, directionShoppingCart } = useDirectinCart();
  const [stepSelected, setStepSelected] = useState(0);
  const [topStep, setTopStep] = useState(0);
  const [paqueterias, setPaqueterias] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [shipmentNotAvailable, setShipmentNotAvailable] = useState(0);
  const [shipmentAvailable, setShipmentAvailable] = useState([]);
  const [openModalError, setOpenModalError] = useState(false);
  const [keysPaqueterias, setKeysPaqueterias] = useState([]);
  const [idEnvio, setIdEnvio] = useState();
  const [totalFailed, setTotalFailed] = useState(0);
  const history = useHistory();

  //
  const reLoadShipment = async () => {
    setLoading(true);
    setPaqueterias([]);
    setKeysPaqueterias([]);
    const arrItems = itemsShoppingCart.map((item) => {
      return {
        idAutopart: item.id,
        cuantity: item.qty,
      };
    });

    const data = {
      ...directionShoppingCart,
      fullName: `${user.name} ${user.second_name} ${user.sur_name} ${user.sur_name2}`,
      arrItems,
      idCustomer: user.cat_user_marketplace_id,
      idEnvio: idEnvio,
    };

    await saveProgressMutation({
      variables: {
        step: 'shipping_information',
        info: JSON.stringify(data),
      },
    });
  };
  const [loading, setLoading] = useState(false);
  const [saveProgressMutation] = useMutation(SAVE_SHOPING_CART_PROGRESS, {
    fetchPolicy: 'no-cache',
    onCompleted(saveShoppingCartProgress) {
      if (currentStep === 2) {
        const parsed = JSON.parse(
          saveShoppingCartProgress.saveShoppingCartProgress.response
        );
        if (typeof parsed === 'string') {
          setCurrentStep(1);
          setStepSelected(1);
          alert(parsed);
          setLoading(false);
          return '';
        }
        let claves = Object.keys(parsed);
        const arrPaqueterias = claves.map((item) => {
          return parsed[item].map((paqueteria) => {
            if (paqueteria.type === 'rates') {
              return {
                id_shipment: paqueteria.id,
                id_distribuidor: item,
                nombre: paqueteria.attributes.provider,
                servicio: paqueteria.attributes.service_level_code,
                costo: `$ ${paqueteria.attributes.total_pricing} ${paqueteria.attributes.currency_local}`,
                dias_de_entrega: paqueteria.attributes.days,
              };
            }
          });
        });
        let keys = Object.keys(arrPaqueterias);
        setPaqueterias(arrPaqueterias);
        setKeysPaqueterias(keys);
      }
      if (currentStep === 3) {
        const response = JSON.parse(
          saveShoppingCartProgress.saveShoppingCartProgress.response
        );
        let totalFailed = 0;
        let shipmentNotAvailable = [];
        let errors = false;
        setShipmentAvailable(response.shipmentsAvailables);
        let keys = Object.keys(idEnvio);

        keys.map((key) => {
          const currentShipment = response.shipmentsAvailables.filter(
            (item) => item.id_uac === key
          );
          idEnvio[key] = {
            ...idEnvio[key],
            tracking_number: currentShipment[0]?.infoShipment?.tracking_number,
            labelUrl: currentShipment[0]?.infoShipment?.labelUrl,
            isAvailable: currentShipment[0]?.isAvailable,
            nombre: currentShipment[0]?.nombre,
            servicio: currentShipment[0]?.servicio,
          };
        });

        addShipmentComplete(idEnvio);

        response.shipmentsAvailables.map((currentShipment) => {
          const mensaje =
            typeof currentShipment.message === 'string'
              ? currentShipment.message
              : JSON.parse(currentShipment.message);
          if (currentShipment.message !== 'paqueteria disponible') {
            // reLoadShipment();
            errors = true;
            totalFailed += stringToNumber(currentShipment.costo);
            shipmentNotAvailable.push({
              nombre: currentShipment.nombre,
              costo: currentShipment.costo,
              id_uac: currentShipment.id_uac,
            });
          }
        });
        if (errors) {
          setShipmentNotAvailable(shipmentNotAvailable);
          setOpenModalError(true);
          setCurrentStep(2);
          setStepSelected(2);
          // alert(`una o mas paqueterias seleccionadas no
          // estan disponibles, selecciona una distinta o
          // intentalo mas tarde
          // lista de paqueterias que tuvieron error:\n
          // ${JSON.stringify(shipmentNotAvailable)}`)
          setLoading(false);
          setTotalFailed(totalFailed);
          return '';
        } else {
          setOpenModalError(false);
          setTotalFailed(totalFailed);
        }
      }
      setStepSelected(currentStep);
      setLoading(false);
    },
    onError(err) {
      console.log(err);
      alert('Hay un error con las piezas que impide calcular su envio');
      setLoading(false);
    },
  });

  const saveProgress = async (next, step, info) => {
    setLoading(true);
    let data = {};
    const arrItems = itemsShoppingCart.map((item) => {
      return {
        idAutopart: item.id,
        cuantity: item.qty,
      };
    });

    if (next === 2) {
      updatedirectionCart(info);
      addShipmentComplete([]);
      data = {
        ...info,
        fullName: `${user.name} ${user.second_name} ${user.sur_name} ${user.sur_name2}`,
        arrItems,
        idCustomer: user.cat_user_marketplace_id,
        idEnvio: idEnvio,
      };
    }
    if (next === 3) {
      data = {
        envios: {
          ...idEnvio,
        },
        arrItems,
        idCustomer: user.cat_user_marketplace_id,
      };
      let keys = Object.keys(idEnvio);

      keys.map((key) => {
        const currentShipment = shipmentAvailable.filter(
          (item) => Number(item.id_uac) === key
        );
        idEnvio[key] = {
          ...idEnvio[key],
          tracking_number: currentShipment[0]?.infoShipment?.tracking_number,
          labelUrl: currentShipment[0]?.infoShipment?.labelUrl,
        };
      });
      // addShipmentComplete(idEnvio)
    }

    setCurrentStep(next);
    await saveProgressMutation({
      variables: {
        step,
        info: JSON.stringify(data),
      },
    });
    // setStepSelected(next);
  };

  /**Actualizacion del carrito ligado a la cuenta y progresos en la db */
  const { updateItemsCart, itemsShoppingCart } = useShoppingCart();
  const { addShipmentComplete } = useShipmentCart();

  useEffect(() => {
    console.log(itemsShoppingCart);
  }, [itemsShoppingCart]);
  /**------------------------------------------------------------------ */

  useEffect(() => {
    if (stepSelected > topStep) {
      setTopStep(stepSelected);
    }
  }, [stepSelected]);

  return (
    <>
      <Loader open={loading} />
      <ModalShipmentError
        setLoading={setLoading}
        items={itemsShoppingCart}
        setOpen={setOpenModalError}
        open={openModalError}
        info={shipmentNotAvailable}
        reLoadShipment={reLoadShipment}
      />
      <Flex
        direction='column'
        width='100%'
        align='center'
        maxWidth='1440px'
        alignSelf='center'
        padding='80px'
        gap='40px'
        mobile='padding: 40px 16px;'
      >
        <Text
          family='Roboto'
          size='16px'
          weight='600'
          color='#022741'
          decoration='underline'
          width='100%'
          onClick={() => history.push('/store')}
          style={{ cursor: 'pointer' }}
        >
          Regresar a "Todas nuestras categorías"
        </Text>
        <Flex width='100%' direction='row'>
          <Box sx={{ width: '100%' }}>
            <Stepper
              // activeStep:
              //   <0 = none
              //   0 = tu carrito
              //   1 = datos de envio
              //   2 = paquetería
              //   3 = pago
              //   >=4 = done
              activeStep={stepSelected}
              alternativeLabel
              connector={<Connector />}
            >
              <Step>
                <StepLabel
                  style={{ cursor: 'pointer' }}
                  StepIconComponent={CustomStepIcon}
                  onClick={() =>
                    stepSelected >= 0 ? setStepSelected(0) : null
                  }
                >
                  <Flex
                    direction='column'
                    align='center'
                    gap='8px'
                    width='100%'
                    style={{ fontSize: '12px' }}
                  >
                    <img
                      src={stepSelected >= 0 ? myCartDoneIcon : myCartIcon}
                      alt=''
                      style={{ width: '32px', maxWidth: '32px' }}
                    />
                    Tu carrito
                  </Flex>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  style={{ cursor: 'pointer' }}
                  StepIconComponent={CustomStepIcon}
                  onClick={() =>
                    stepSelected >= 1 ? setStepSelected(1) : null
                  }
                >
                  <Flex
                    direction='column'
                    align='center'
                    gap='8px'
                    width='100%'
                    style={{ fontSize: '12px' }}
                  >
                    <img
                      src={
                        stepSelected >= 1 ? datosEnvioDoneIcon : datosEnvioIcon
                      }
                      alt=''
                      style={{
                        width: '32px',
                        maxWidth: '32px',
                        opacity: stepSelected < 1 ? '0.5' : '1',
                      }}
                    />
                    Datos de envío
                  </Flex>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  style={{ cursor: 'pointer' }}
                  StepIconComponent={CustomStepIcon}
                  onClick={() =>
                    stepSelected >= 2 ? setStepSelected(2) : null
                  }
                >
                  <Flex
                    direction='column'
                    align='center'
                    gap='8px'
                    width='100%'
                    style={{ fontSize: '12px' }}
                  >
                    <img
                      src={
                        stepSelected >= 2 ? paqueteríaDoneIcon : paqueteriaIcon
                      }
                      alt=''
                      style={{
                        width: '32px',
                        maxWidth: '32px',
                        opacity: stepSelected < 2 ? '0.5' : '1',
                      }}
                    />
                    Paquetería
                  </Flex>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  style={{ cursor: 'pointer' }}
                  StepIconComponent={CustomStepIcon}
                  onClick={() =>
                    stepSelected >= 3 ? setStepSelected(3) : null
                  }
                >
                  <Flex
                    direction='column'
                    align='center'
                    gap='8px'
                    width='100%'
                    style={{ fontSize: '12px' }}
                  >
                    <img
                      src={stepSelected >= 3 ? paymentDoneIcon : paymentIcon}
                      alt=''
                      style={{
                        width: '32px',
                        maxWidth: '32px',
                        opacity: stepSelected < 3 ? '0.5' : '1',
                      }}
                    />
                    Pago
                  </Flex>
                </StepLabel>
              </Step>
            </Stepper>
          </Box>
        </Flex>
        {stepSelected === 0 && (
          <>
            <TuCarrito
              setStepSelected={setStepSelected}
              items={itemsShoppingCart}
            />
          </>
        )}
        {stepSelected === 1 && (
          <>
            <DatosEnvio saveProgress={saveProgress} user={user} />
          </>
        )}
        {stepSelected === 2 && (
          <>
            {
              <PaqueteriaElegir
                setStepSelected={saveProgress}
                items={itemsShoppingCart}
                paqueterias={paqueterias}
                setIdEnvio={setIdEnvio}
                idEnvio={idEnvio}
                totalFailed={totalFailed}
              />
            }
            {/*  <button onClick={() => setStepSelected(3)}>siguiente step</button> */}
          </>
        )}
        {stepSelected === 3 && (
          <>
            <Payment
              setStepSelected={setStepSelected}
              items={itemsShoppingCart}
              setLoading={setLoading}
            />
          </>
        )}
        {stepSelected === 4 && (
          <>
            <Success items={itemsShoppingCart} />
          </>
        )}
      </Flex>
    </>
  );
};

export default CartSteps;
