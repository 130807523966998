import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  top: 0;
  @media (max-width: 1439px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

export const AltaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const Title = styled.h2`
  font-family: Mulish;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 48px */
  color: #0d3564;
  margin: 0;
`;
export const Imagen = styled.img`
  width: 40px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  width: 100%;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;
export const ButtonAlta = styled.button`
  display: flex;
  width: 100%;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: #0d3564;
  color: #fff;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-color: #0d3564;
  border-radius: 8px;
  border-style: solid;
  cursor: pointer;
  @media (min-width: 1024px) {
    width: 194px;
  }
`;
