import React from 'react'
//styles
//assets
import { url } from '../../../api';
import { Overlay,MainContainer,ButtonCargar,ButtonContainer, MainTitle, Subtitle  } from './ModalLoSentimos.styles'
const edit = `${url}Editar.svg`;
const close = `${url}CloseButton.png`;
export default function ModalLosentimos({showModalDelete, setShowModalDelete}) {
  return (
    <Overlay>
    <MainContainer>
      <MainTitle>Lo sentimos </MainTitle>
    <Subtitle>Se ha realizado una búsqueda en el sistema, pero lamentablemente, por el momento no se encuentran datos relacionados.</Subtitle>
    <ButtonContainer>
     
      <ButtonCargar onClick={()=>setShowModalDelete(!showModalDelete)}>Entendido</ButtonCargar>
   
    </ButtonContainer>
      
    </MainContainer>
    </Overlay>
  )
}

