import React from 'react';
import ReactDOM from 'react-dom';
// import 'antd/dist/antd.css';
// import './styles/style.scss';
import './styles/style.css';
import Router from './routers/index';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/shopping.store';

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<Router />
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
