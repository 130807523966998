/* eslint-disable */
import React from 'react';
// import { Container } from './ModalUpload.styles';
// import ModalDashboard from '../Modal/ModalDashboard';
import { ModalBackdrop } from '../Modal/ModalDashboard.styles';
import {
  MainContainer,
  Title,
  TextModal,
  CargarContainer,
  CargarContainerInner,
  MainTextCargar,
  TextCargar,
  ButtonSeleccionar,
  ButtonCancelar,
} from '../../../components/Dashboard/ModalCargaInformacion.styles';
// import uploadFileImg from "../../../../assets/uploadFile.svg";
import { useState } from 'react';
import { SAVE_FILE_V2 } from '../../../../graphql/Query';
import { useLazyQuery } from '@apollo/client';
import Loader from '../../../components/common/Loader';
import useUser from '../../../../hooks/useUser';
import upload from '../../../assets/upload.svg';
import archivo from '../../../assets/archivo.svg';
import {
  Button,
  Flex,
  Layout,
  StyledSelect,
  Text,
} from '../../../styles/common.styles';
import { Modal } from '@mui/material';

const ModalUpload = ({ open, setOpen }) => {
  const { user } = useUser();
  const hiddenFileInput = React.useRef(null);
  const [data, setData] = useState([]);
  const [templateAdmited, settemplateAdmited] = useState(false);
  const [fileSelected, setfileSelected] = useState(false);
  const [nameffile, setnameffile] = useState('Seleccionar');
  const [loading, setLoading] = useState(false);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange2 = (e) => {
    let ArrCsv = [];
    e.preventDefault();
    const reader = new FileReader();
    const archivo = e.target.files[0];

    if (archivo && archivo.name.endsWith('.csv')) {
      reader.onload = async (e) => {
        const text = e.target.result;
        ArrCsv = text.split('\n');
        if (text.includes('costoPromedio')) {
          ArrCsv.shift();
        }
        // let arr = ArrCsv[0].split('|');

        setData(ArrCsv);
        setfileSelected(true);
      };
      reader.readAsText(e.target.files[0]);
      setnameffile(e.target.files[0].name);
      console.log('success');
    } else {
      console.log('fail');
    }
  };

  const sendData = () => {
    setOpen(false);
    setLoading(true);
    uploadFile({
      variables: {
        data: data,
        table: 'dashboards',
        id_Padre: 60,
        id_user: user.id_uac,
        nameffile: nameffile,
      },
    });
  };

  const [uploadFile] = useLazyQuery(SAVE_FILE_V2, {
    fetchPolicy: 'no-cache',
    onCompleted({ saveFileV2 }) {
      setLoading(false);
      setnameffile('Seleccionar');
    },
    onError(err) {
      setLoading(false);
      console.log('ERROR: getBestSellPart', err);
    },
  });

  return (
    <>
      <Loader open={loading} />
      <Modal open={open}>
        <Layout>
          <MainContainer>
            <div
              onClick={() => setOpen(false)}
              style={{
                position: 'absolute',
                top: '1rem',
                right: '1rem',
                fontSize: '24px',
                cursor: 'pointer',
                fontWeight: '800',
                width: '16px',
                height: '16px',
              }}
            >
              🞨
            </div>
            <Flex width='100%' direction='column' align='center'>
              <img src={upload} style={{ marginBottom: '16px' }} />
              <Title>Carga de información</Title>{' '}
            </Flex>
            <TextModal>
              Elige un distribuidor, si tienes dudas sobre el distribuidor,
              consulta las opciones disponibles.
            </TextModal>
            <Flex
              //Input container
              direction='column'
              gap='4px'
              width='100%'
            >
              <Text family='Inter' size='12px' weight='400'>
                Selecciona un distribuidor
              </Text>
              <StyledSelect>
                <option>mock</option>
              </StyledSelect>
            </Flex>

            <Flex
              width='100%'
              direction='column'
              align='center'
              gap='16px'
              padding='8px'
            >
              <TextModal>
                Para subir el archivo, da clic en{' '}
                <span style={{ fontWeight: '800' }}>'Seleccionar'</span> y elige
                el archivo correspondiente en formato{' '}
                <span style={{ fontWeight: '800' }}>.csv</span>.{' '}
              </TextModal>
              <TextModal>
                Si el archivo está en otro formato, conviértelo a{' '}
                <span style={{ fontWeight: '800' }}>.csv</span> antes de
                cargarlo.
              </TextModal>
              <TextModal>
                Finalmente, pulsa{' '}
                <span style={{ fontWeight: '800' }}>'Cargar'</span> una vez
                hayas seleccionado el archivo.
              </TextModal>
            </Flex>
            {/* <input
            type='file'
            ref={hiddenFileInput}
            onChange={handleChange2}
            style={{ display: 'none' }}
            accept='.csv'
          /> */}
            <CargarContainer>
              {fileSelected ? (
                <>
                  <Flex
                    direction='row'
                    gap='32px'
                    justify='space-between'
                    align='center'
                    width='100%'
                    mobile='gap:16px;'
                  >
                    <Flex direction='row' gap='32px' align='center'>
                      <img src={archivo} />
                      <Text family='Inter' color='#0D3564'>
                        {nameffile}
                      </Text>
                    </Flex>
                    <Text
                      weight='800'
                      size='24px'
                      color='#0D3564'
                      onClick={() => {
                        setfileSelected(false);
                        setnameffile('Seleccionar');
                      }}
                    >
                      ✕
                    </Text>
                  </Flex>
                </>
              ) : (
                <CargarContainerInner>
                  <Flex
                    direction='row'
                    gap='32px'
                    mobile='gap: 16px; flex-direction: column; align-items: center;'
                  >
                    <img src={upload} style={{ width: '32px' }} />
                    <div style={{ minWidth: '250px' }}>
                      <MainTextCargar>
                        Selecciona el archivo a cargar
                      </MainTextCargar>
                      <TextCargar>Formato .csv peso de 10MB</TextCargar>
                    </div>{' '}
                  </Flex>
                  <ButtonSeleccionar
                    className='button-files'
                    onClick={handleClick}
                  >
                    {/* <img src={uploadFileImg} alt="uploadFileImg" /> */}
                    {nameffile}
                  </ButtonSeleccionar>
                </CargarContainerInner>
              )}
            </CargarContainer>

            <Flex
              direction='row'
              width='100%'
              justify='center'
              gap='16px'
              margin='32px 0 0'
              mobile='flex-direction: column;'
            >
              <Button onClick={sendData} mobile='width:100%;' width='194px'>
                Cargar
              </Button>
              <Button
                width='194px'
                secondary
                mobile='width:100%;'
                onClick={() => setOpen(false)}
              >
                Cancelar
              </Button>
            </Flex>
          </MainContainer>
        </Layout>
      </Modal>
    </>
  );
};

export default ModalUpload;
