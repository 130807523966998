import React, { useEffect, useState } from 'react';
//styles
import {
  ModalOverlay,
  ModalContent,
  ButtonEliminar,
  CancelarButton,
  Subtitle,
  Title,
  TitleContainer,
  ImgForm,
  ButtonContainer,
  FormTitle,
  FormTitleContainer,
  InputContainer,
  InputForm,
  InputLabel,
  LabelSelect,
  TextAreaForm,
  RowContainer,
} from './ModalComprarMayoreo.styles';
import { ADD_LEADER_SHOPP } from '../../../graphql/Mutation';
import { useMutation } from '@apollo/client';
import { url } from '../../api';
import { Modal } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import { StyledInput } from '../../styles/common.styles';
//assets
const personalDataIcon = `${url}personalDataIcon.svg`;
const IconContact = `${url}contactDataIcon.svg`;

const isInputValid = (data, errors) => {
  const generalErrors = Object.values(errors);
  const contactErrors = Object.values(errors);

  // Check if there are any errors
  const hasErrors = generalErrors
    .concat(contactErrors)
    .some((error) => error !== '');

  return !hasErrors;
};
// redux

export default function ModalComprarMayoreo({
  show,
  setShow,
  clearData,
  setOpenGracias,
  setOpenMayoreo,
  open,
}) {
  const [createLead] = useMutation(ADD_LEADER_SHOPP, {
    onCompleted: ({ registerLeadShopp }) => {
      if (registerLeadShopp.response === 'guardad y envio exitoso') {
        setOpenGracias(true);
        setOpenMayoreo(false);
      }
    },
    onError: (error) => {
      console.log({ error });
      alert(`Error en peticion: ${error.message.split('GraphQL error: ')[1]}`);
    },
  });

  const [generalData, setGeneralData] = useState({
    primerNombre: '',
    segundoNombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
  });

  const [contactData, setContactData] = useState({
    correoElectronico: '',
    numeroCelular: '',
    nombreEmpresa: '',
    codigoPostal: '',
  });

  const [errors, setErrors] = useState({
    primerNombre: '',
    segundoNombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    correoElectronico: '',
    numeroCelular: '',
    codigoPostal: '',
  });

  const [comment, setComment] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [tryContinue, setTryContinue] = useState(false);

  // ... (your existing JSX)
  const sendData = () => {
    if (!isFormValid) {
      setTryContinue(true);
    } else {
      const data = Object.assign(generalData, contactData);
      const dataObject = { ...data, comment };
      createLead({
        variables: { data: JSON.stringify(dataObject) },
      });
    }
  };

  // Update the validation status whenever there's an input change
  useEffect(() => {
    const valid = isInputValid(generalData, errors);
    if (
      contactData.codigoPostal !== '' &&
      contactData.correoElectronico !== '' &&
      contactData.nombreEmpresa !== '' &&
      contactData.numeroCelular !== '' &&
      generalData.apellidoMaterno !== '' &&
      generalData.apellidoPaterno !== '' &&
      generalData.primerNombre !== '' &&
      valid
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [generalData, errors, contactData]);

  return (
    <Modal open={open}>
      <ModalOverlay>
        <ModalContent>
          <div
            style={{
              position: 'absolute',
              right: '16px',
              top: '16px',
              fontSize: '1.5rem',
              color: '#888',
              cursor: 'pointer',
            }}
            onClick={() => {
              setOpenMayoreo(false);
            }}
          >
            ✕
          </div>
          <TitleContainer>
            <Title style={{ fontSize: '28px' }}>
              ¿Quieres comprar al mayoreo?
            </Title>
          </TitleContainer>

          <Subtitle>
            Completa este formulario y un representante de Ballena se pondrá en
            contacto contigo. <br />
            Descubre cómo nuestros productos y soluciones pueden beneficiar a tu
            negocio y aprovecha las ventajas de comprar al mayoreo con nosotros.
          </Subtitle>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <FormTitleContainer>
              <ImgForm alt='' src={personalDataIcon} />
              <FormTitle>Datos generales</FormTitle>
            </FormTitleContainer>
            <RowContainer>
              <InputContainer>
                <InputLabel>Primer nombre</InputLabel>
                <StyledInput
                  placeholder='Escribe tu nombre'
                  value={generalData.primerNombre}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setGeneralData({ ...generalData, primerNombre: newValue });

                    if (/[^A-Za-záéíóúÁÉÍÓÚñÑ\s]/.test(newValue)) {
                      setErrors({
                        ...errors,
                        primerNombre: 'Solo se permiten letras.',
                      });
                    } else {
                      setErrors({ ...errors, primerNombre: '' });
                    }
                  }}
                  error={tryContinue && generalData.primerNombre == ''}
                />
                {errors.primerNombre && (
                  <span style={{ color: 'red' }}>{errors.primerNombre}</span>
                )}
              </InputContainer>
              <InputContainer>
                <InputLabel>Segundo nombre</InputLabel>
                <StyledInput
                  placeholder='Escribe tu segundo nombre'
                  value={generalData.segundoNombre}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setGeneralData({ ...generalData, segundoNombre: newValue });

                    if (/[^A-Za-záéíóúÁÉÍÓÚñÑ\s]/.test(newValue)) {
                      setErrors({
                        ...errors,
                        segundoNombre: 'Solo se permiten letras.',
                      });
                    } else {
                      setErrors({ ...errors, segundoNombre: '' });
                    }
                  }}
                />
                {errors.segundoNombre && (
                  <span style={{ color: 'red' }}>{errors.segundoNombre}</span>
                )}
              </InputContainer>
            </RowContainer>
            <RowContainer>
              <InputContainer>
                <InputLabel>Apellido paterno</InputLabel>
                <StyledInput
                  placeholder='Escribe tu apellido paterno'
                  value={generalData.apellidoPaterno}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setGeneralData({
                      ...generalData,
                      apellidoPaterno: newValue,
                    });

                    if (/[^A-Za-záéíóúÁÉÍÓÚñÑ\s]/.test(newValue)) {
                      setErrors({
                        ...errors,
                        apellidoPaterno: 'Solo se permiten letras.',
                      });
                    } else {
                      setErrors({ ...errors, apellidoPaterno: '' });
                    }
                  }}
                  error={tryContinue && generalData.apellidoPaterno == ''}
                />
                {errors.apellidoPaterno && (
                  <span style={{ color: 'red' }}>{errors.apellidoPaterno}</span>
                )}
              </InputContainer>
              <InputContainer>
                <InputLabel>Apellido materno</InputLabel>
                <StyledInput
                  placeholder='Escribe tu apellido materno'
                  value={generalData.apellidoMaterno}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setGeneralData({
                      ...generalData,
                      apellidoMaterno: newValue,
                    });

                    if (/[^A-Za-záéíóúÁÉÍÓÚñÑ\s]/.test(newValue)) {
                      setErrors({
                        ...errors,
                        apellidoMaterno: 'Solo se permiten letras.',
                      });
                    } else {
                      setErrors({ ...errors, apellidoMaterno: '' });
                    }
                  }}
                  error={tryContinue && generalData.apellidoMaterno == ''}
                />
                {errors.apellidoMaterno && (
                  <span style={{ color: 'red' }}>{errors.apellidoMaterno}</span>
                )}
              </InputContainer>
            </RowContainer>
          </div>

          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <FormTitleContainer>
              <ImgForm alt='' src={IconContact} />
              <FormTitle>Datos de contacto</FormTitle>
            </FormTitleContainer>
            <RowContainer>
              <InputContainer>
                <InputLabel>Correo electrónico</InputLabel>
                <StyledInput
                  placeholder='Escribe tu correo electrónico'
                  value={generalData.correoElectronico}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setContactData({
                      ...contactData,
                      correoElectronico: newValue,
                    });

                    if (
                      !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(
                        newValue
                      )
                    ) {
                      setErrors({
                        ...errors,
                        correoElectronico:
                          'Ingresa un correo electrónico válido.',
                      });
                    } else {
                      setErrors({ ...errors, correoElectronico: '' });
                    }
                  }}
                  error={tryContinue && contactData.correoElectronico == ''}
                />
                {errors.correoElectronico && (
                  <span style={{ color: 'red' }}>
                    {errors.correoElectronico}
                  </span>
                )}
              </InputContainer>
              <InputContainer>
                <InputLabel>Número celular</InputLabel>
                {/* <StyledInput
                  placeholder='Escribe tu número de celular '
                  value={generalData.numeroCelular}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setContactData({ ...contactData, numeroCelular: newValue });

                    if (!/^\d{10}$/.test(newValue)) {
                      setErrors({
                        ...errors,
                        numeroCelular:
                          'Ingresa un número de celular válido (10 dígitos).',
                      });
                    } else {
                      setErrors({ ...errors, numeroCelular: '' });
                    }
                  }}
                /> */}
                <PhoneInput
                  country='mx'
                  containerStyle={{ width: '100%' }}
                  inputStyle={{
                    width: '100%',
                    height: '48px',
                    borderRadius: '8px',
                    border:
                      contactData.numeroCelular == '' && tryContinue
                        ? '1px solid #D22121'
                        : '1px solid #b9cad0',
                    outline: 'none',
                    boxShadow: 'none',
                  }}
                  isValid={
                    contactData.numeroCelular == '' && tryContinue
                      ? false
                      : true
                  }
                  onChange={(e) => {
                    const newValue = e;
                    setContactData({ ...contactData, numeroCelular: newValue });

                    if (!/^\d{12}$/.test(newValue)) {
                      setErrors({
                        ...errors,
                        numeroCelular:
                          'Ingresa un número de celular válido (12 dígitos).',
                      });
                    } else {
                      setErrors({ ...errors, numeroCelular: '' });
                    }
                  }}
                  value={generalData.numeroCelular}
                />
                {errors.numeroCelular && (
                  <span style={{ color: 'red' }}>{errors.numeroCelular}</span>
                )}
              </InputContainer>
            </RowContainer>
            <RowContainer>
              <InputContainer>
                <InputLabel>Nombre de empresa</InputLabel>
                <StyledInput
                  placeholder='Escribe el nombre de tu empresa'
                  value={generalData.nombreEmpresa}
                  onChange={(e) =>
                    setContactData({
                      ...contactData,
                      nombreEmpresa: e.target.value,
                    })
                  }
                  error={tryContinue && contactData.nombreEmpresa == ''}
                />
              </InputContainer>
              <InputContainer>
                <InputLabel>Código postal</InputLabel>
                <StyledInput
                  placeholder='Escribe tu código postal'
                  value={contactData.codigoPostal}
                  onChange={(e) => {
                    const newValue = e.target.value
                      .replace(/\D/g, '')
                      .slice(0, 5);
                    setContactData({
                      ...contactData,
                      codigoPostal: newValue,
                    });

                    if (!/^\d{5}$/.test(newValue)) {
                      setErrors({
                        ...errors,
                        codigoPostal:
                          'Ingresa un código postal válido (5 dígitos numéricos).',
                      });
                    } else {
                      setErrors({ ...errors, codigoPostal: '' });
                    }
                  }}
                  error={tryContinue && contactData.codigoPostal == ''}
                />
                {errors.codigoPostal && (
                  <span style={{ color: 'red' }}>{errors.codigoPostal}</span>
                )}
              </InputContainer>
            </RowContainer>
          </div>

          {/* <InputContainer>
            <LabelSelect>¿Las refacciones de tu empresa son originales o genéricas?</LabelSelect>
            <SelectForm></SelectForm>
        </InputContainer>
        <InputContainer>
            <LabelSelect>¿Alrededor de cuantas marcas de refacciones maneja tu empresa?</LabelSelect>
            <SelectForm></SelectForm>
        </InputContainer> */}
          <InputContainer style={{ width: '100%' }}>
            <LabelSelect>Comentario (Opcional)</LabelSelect>
            <TextAreaForm
              placeholder='Escribe algún comentario'
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </InputContainer>
          <ButtonContainer>
            <ButtonEliminar
              style={{ cursor: isFormValid ? 'pointer' : 'default' }}
              onClick={sendData}
            >
              Enviar
            </ButtonEliminar>
            <CancelarButton
              onClick={() => {
                setOpenMayoreo(false);
              }}
            >
              Cancelar
            </CancelarButton>
          </ButtonContainer>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}
