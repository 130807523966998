import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      ok
      token
      refreshToken
      type
      email
    }
  }
`;

export const LOGIN_DIS = gql`
  mutation login($email: String!, $password: String!) {
    loginDis(email: $email, password: $password) {
      ok
      token
      refreshToken
      user
      errors {
        path
        message
      }
    }
  }
`;

export const GET_DATA_DISTRIBUTOR = gql`
  mutation getDistributor($e: String!) {
    getDistributor(e: $e) {
      id
      gato
      id_parte
      Costo_pieza
      costo_fijo_orden_de_compra
      Costo_de_almacenaje
      Precio_de_Venta
      Fecha_orden_almacen
      Fecha_de_entrega_almacen
      Fecha_orden_cliente
      Fecha_de_venta
      celda_L
      celda_M
      celda_N
      celda_O
      celda_P
      celda_Q
      celda_R
      id_distribuidor
    }
  }
`;

export const MOVE_TO_MARKETPLACE = gql`
  mutation moveToMarketplace($e: String, $id_parte: String) {
    moveToMarketplace(e: $e, id_parte: $id_parte) {
      ok
    }
  }
`;

export const UPLOAD_IMAGE = gql`
  mutation uploadImage($imgUrl: String, $email: String, $id_parte: Int) {
    uploadImage(imgUrl: $imgUrl, email: $email, id_parte: $id_parte) {
      ok
    }
  }
`;

export const REQUEST_PART = gql`
  mutation requestPart(
    $lastName: String!
    $firstName: String!
    $email: String!
    $phone: String!
    $id_parte: String!
  ) {
    requestPart(
      lastName: $lastName
      firstName: $firstName
      email: $email
      phone: $phone
      id_parte: $id_parte
    )
  }
`;

export const SEND_EMAIL_REQUEST_AUTOPART = gql`
  mutation requestAutopart($autopart: Autopart!, $customerData: CustomerData!) {
    requestAutopart(autopart: $autopart, customerData: $customerData)
  }
`;
export const UPDATE_USER_REQUESTPART = gql`
  mutation upDateUserRequestPart(
    $request_part_users_id: Int!
    $status_request: String!
  ) {
    upDateUserRequestPart(
      request_part_users_id: $request_part_users_id
      status_request: $status_request
    )
  }
`;

export const SELL_SHOPPING_CART = gql`
  mutation setSellShoppingCart(
    $items: [SellInfo]
    $userToken: String
    $token: String
    $issuer_id: String
    $payment_method_id: String
    $installments: Int
    $description: String
    $email: String
    $amount: String
    $identification: Identification
    $fourFinalCardNumbers: String
    $fullName: String
    $fecha: String
    $province: String
    $city: String
    $zip: String
    $street: String
    $number: String
    $address2: String
    $phone: String
    $reference: String
    $company: String
    $shipmentChoices: [ShipmentElements]
  ) {
    setSellShoppingCart(
      selling: {
        items: $items
        userToken: $userToken
        token: $token
        issuer_id: $issuer_id
        payment_method_id: $payment_method_id
        installments: $installments
        description: $description
        payer: { email: $email, identification: $identification }
        amount: $amount
        fourFinalCardNumbers: $fourFinalCardNumbers
        fullName: $fullName
        fecha: $fecha
        province: $province
        city: $city
        zip: $zip
        street: $street
        number: $number
        address2: $address2
        phone: $phone
        reference: $reference
        company: $company
        shipmentChoices: $shipmentChoices
      }
    ) {
      message
      success
      status
      sellId
      error
    }
  }
`;

export const POST_PRODUCT_TO_MARKETPLACE = gql`
  mutation (
    $id_CatProduct_group: Int!
    $id_parte: String!
    $id_grupo: Int!
    $id_distribuidor: Int!
    $descripcion: String
    $notas: String
    $peso: String
    $largo: Float
    $ancho: Float
    $alto: Float
    $Image: Upload
    $Image2: String
    $precio_plataforma: Float!
    $existencias: Int
    $id_Categorias: Int!
    $nueva_categoria: String
  ) {
    postingProductMarketplace(
      productData: {
        id_CatProduct_group: $id_CatProduct_group
        id_parte: $id_parte
        id_grupo: $id_grupo
        id_distribuidor: $id_distribuidor
        precio_plataforma: $precio_plataforma
        descripcion: $descripcion
        notas: $notas
        peso: $peso
        largo: $largo
        ancho: $ancho
        alto: $alto
        Image: $Image
        Image2: $Image2
        existencias: $existencias
        id_Categorias: $id_Categorias
        nueva_categoria: $nueva_categoria
      }
    )
  }
`;

export const REMOVE_PRODUCT_FROM_MARKETPLACE = gql`
  mutation ($ID: Int!) {
    removeProductFromMarketplace(ID: $ID)
  }
`;


export const SAVE_SHOPING_CART_PROGRESS = gql`
  mutation saveShoppingCartProgress($step: String, $info: String) {
    saveShoppingCartProgress(step: $step, info: $info){
      statusCode
      message
      error
      response
    }
  }
`;

export const UPLOAD_PRODUCT_IMAGE = gql`
  mutation (
    $ID: Int!
    $id_parte: String!
    $id_grupo: Int!
    $id_distribuidor: Int!
    $Image: Upload!
  ) {
    uploadProductImage(
      productData: {
        ID: $ID
        id_parte: $id_parte
        id_grupo: $id_grupo
        id_distribuidor: $id_distribuidor
        Image: $Image
      }
    )
  }
`;

export const SEND_EMAIL_CLIENTS_PRODUCT_AVAILABLE = gql`
  mutation ($autopart: Autopart!, $image: String!) {
    sendEmailPostingProductMarket(autopart: $autopart, image: $image)
  }
`;

export const UPDATE_SINGLE_PRICE_PRODUCT = gql`
  mutation updatePlatformPrice(
    $id: ID!
    $newPrice: Float
    $id_distribuidor: Int
    $id_disPadre: ID
  ) {
    updatePlatformPrice(
      data: {
        id: $id
        newPrice: $newPrice
        id_distribuidor: $id_distribuidor
        id_disPadre: $id_disPadre
      }
    ) {
      newPrice
    }
  }
`;

export const UPDATE_ALL_AVOID_PRODUCTS = gql`
  mutation updateAllAvoidProducts {
    updateAllVoidProductsPrice {
      ok
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation singleUpload($file: UploadSingleFile!, $name: String) {
    singleUpload(file: $file, name: $name)
  }
`;

export const CHANGE_TASA_ITEMS = gql`
mutation changeTasaItemsSelected($inMarketplace: Boolean , $date: String,  $idUac: String,  $idManufacture: String,  $isByFilters: Boolean,  $items: String, $tasa: String) {
  changeTasaItemsSelected(inMarketplace: $inMarketplace, date: $date, idUac: $idUac, idManufacture: $idManufacture, isByFilters: $isByFilters, items: $items, tasa: $tasa) {
      statusCode
  		message
  		error
  }
}
`;
export const REGISTER_MARKETPLACE_USER = gql`
  mutation registerMarketplaceUser(
    $email: String!
    $password: String!
    $name: String!
    $phone: String
    $last_name: String
    $surname: String
    $surname2: String
    $gender: String
    $birthdate: String
    $id_user_mktp: Int
    $country: String!
    $street: String!
    $division: String
    $extNUm: String
    $inteNum: String
    $zip: String
    $reference: String
    $priorityAdress: Boolean
  ) {
    registerMarketplaceUser(
      email: $email
      password: $password
      name: $name
      phone: $phone
      last_name: $last_name
      surname: $surname
      surname2: $surname2
      gender: $gender
      birthdate: $birthdate
      id_user_mktp: $id_user_mktp
      country: $country
      street: $street
      division: $division
      extNUm: $extNUm
      inteNum: $inteNum
      zip: $zip
      reference: $reference
      priorityAdress: $priorityAdress
    )
  }
`;

export const ADD_USER = gql`
  mutation registerUser($data: String) {
    registerUser(data: $data){
      response
    }
  }
`;

export const ADD_LEADER_SHOPP = gql`
  mutation registerLeadShopp($data: String) {
    registerLeadShopp(data: $data){
      response
    }
  }
`;

export const DELE_USER = gql`
  mutation deleteUser($idUser: String) {
    deleteUser(idUser: $idUser){
      response
    }
  }
`;

export const DELETE_ITEMS = gql`
  mutation deleteItems($arrItems: String) {
    deleteItems(arrItems: $arrItems){
      statusCode
      message
      error
    }
  }
`;

export const EDIT_ITEMS = gql`
  mutation editItems($info: String) {
    editItems(info: $info){
      statusCode
      message
      error
    }
  }
`;


export const SEND_EMAIL_CODE_PASSWORD = gql`
  mutation ($email: String!) {
    sendResetPasswordCode(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ($password: String!, $email: String) {
    resetPassword(password: $password, email: $email)
  }
`;

export const UPDATE_DISCOUNT = gql`
  mutation updateDiscount($discount: Int, $id: Int ) {
    updateDiscount(discount: $discount, id: $id){
      response
    }
  }
`;

export const UPDATE_USER_SEGMENT = gql`
  mutation updateUserSegment($emails: [String!], $segment: Int) {
    updateUserSegment(emails: $emails,segment: $segment){
      response
    }
  }
`;

export const REGISTER_MARKETPLACE_ADRESS = gql`
  mutation registerAdressMarketplaceUser(
    $id_user_mktp: Int
    $country: String!
    $street: String!
    $division: String
    $extNUm: String
    $inteNum: String
    $zip: String
    $reference: String
    $priorityAdress: Boolean
  ) {
    registerAdressMarketplaceUser(
      id_user_mktp: $id_user_mktp
      country: $country
      street: $street
      division: $division
      extNUm: $extNUm
      inteNum: $inteNum
      zip: $zip
      reference: $reference
      priorityAdress: $priorityAdress
    )
  }
`;

export const ADD_LEADER_SELL = gql`
  mutation registerSeller($name: String, $surname: String, $lastname:String, $second_lastname:String,
    $email:String, $phone:String, $company:String, $cp:String, $original:Boolean, $number_brands:String, 
    $type_lead:Int, $comment:String) {
    registerSeller(name: $name, surname: $surname, lastname: $lastname, second_lastname:$second_lastname
    email:$email, phone:$phone, company:$company, cp:$cp, original:$original, number_brands:$number_brands
    type_lead:$type_lead, comment:$comment)
  }
`;
