import React from 'react';
//syles
import { MainContainer, Title, CardContainer } from './MostWantedPieces.styles';
import Card from './Card';
import { url } from '../../api';
//assets

const calavera = `${url}calaveraNew.svg`;
const aceite = `${url}AceiteNew.svg`;
const faro = `${url}FarosNew.png`;
const rin = `${url}RinNew.svg`;

export const MostWantedPieces = () => {
  // const isMobile = window.innerWidth <= 1024;
  return (
    <MainContainer>
      <Title>Categorías más buscadas</Title>
      <CardContainer>
        <Card
          title='Calaveras'
          description='Descripción del producto'
          enlace='/store'
          // image={isMobile ? calaveramb : calavera}
          image={calavera}
          backgroundColor='#0D3564'
        />

        <Card
          title='Rines'
          description='Descripción del producto'
          enlace='/store'
          //image={isMobile ? rinmb : rin}
          image={rin}
          backgroundColor='#0E79B4'
        />

        <Card
          title='Faros'
          description='Descripción del producto'
          enlace='/store'
          //image={isMobile ? faromb : faro}
          image={faro}
          backgroundColor='#ED882B'
        />

        <Card
          title='Aceites'
          description='Descripción del producto'
          enlace='/store'
          //image={isMobile ? aceitemb : aceite}
          image={aceite}
          backgroundColor='#0D3564'
          tamano='60px'
        />
      </CardContainer>
    </MainContainer>
  );
};
